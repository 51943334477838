import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FORM_LEVEL_ERRORS } from '@shared/constants/constants';
import { HelperService } from '@shared/services/helper.service';
import { chain, every, isArray, startCase } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-helper',
  templateUrl: './helper-form.component.html',
  styleUrls: ['./helper-form.component.scss'],
})
export class HelperFormComponent implements OnInit, OnDestroy {
  hasView: boolean;
  isFormValid: boolean;
  errorFields: any[] = [];
  subscriptions = new Subscription();

  @Input() form: UntypedFormGroup | UntypedFormGroup[];
  formErrors: any;

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    const viewObserver = this.helperService.onFormHelpChange.subscribe(
      (hasView) => (this.hasView = hasView)
    );
    this.subscriptions.add(viewObserver);

    if (isArray(this.form)) {
      const forms: any = this.form;
      forms.forEach((form: UntypedFormGroup, index: number) => {
        this.onFormChange(form, index);
        const formChangesObserver = form.valueChanges.subscribe(() => {
          this.onFormChange(form, index);
        });
        this.subscriptions.add(formChangesObserver);
      });
    } else {
      const form: any = this.form;
      this.onFormChange(form);
      const formChangesObserver = form.valueChanges.subscribe(() => this.onFormChange(form));
      this.subscriptions.add(formChangesObserver);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onFormChange(form: UntypedFormGroup, index = 0) {
    if (form && !this.isFormValid) {
      this.formErrors = chain(form.errors)
        .map((error, controlName: string) => (error ? FORM_LEVEL_ERRORS[controlName] : false))
        .filter((error) => error)
        .value();
      this.errorFields[index] = chain(form.controls)
        .map((control: UntypedFormControl, controlName: string) =>
          control.invalid ? startCase(controlName) : false
        )
        .filter((name: any) => name)
        .value();
    }
  }

  onClose(): void {
    this.helperService.hideFormHelp();
  }

  get isAllFormValid(): boolean {
    return every(this.form, (form: UntypedFormGroup) => form?.valid);
  }
}
