import { Action } from '@ngrx/store';
import { MacIpBindingList } from '../models/mac-ip-binding.model';

export enum ActionTypes {
  EDIT_MAC_IP_BINDING_LIST = 'EDIT_MAC_IP_BINDING_LIST',
  CLEAR_MAC_IP_BINDING_LIST_TO_BE_EDITED = 'CLEAR_MAC_IP_BINDING_LIST_TO_BE_EDITED',
}

export class EditMacIpBindingList implements Action {
  readonly type = ActionTypes.EDIT_MAC_IP_BINDING_LIST;
  constructor(public payload: MacIpBindingList[]) {}
}

export class ClearMacIpBindingListToBeEdited implements Action {
  readonly type = ActionTypes.CLEAR_MAC_IP_BINDING_LIST_TO_BE_EDITED;
}

export type Actions = EditMacIpBindingList | ClearMacIpBindingListToBeEdited;
