<div class="card-header">
  <div class="row">
    <div class="col-12 col-xl-6">
      <app-search
        *ngIf="hasGlobalSearch"
        (searchChange)="onGlobalSearchChange($event)"
        (search)="onGlobalSearchSubmit()"
      ></app-search>
    </div>
    <div class="col-12 col-xl-6 text-right">
      <ng-container *ngFor="let button of customButtonConfig">
        <button
          *ngIf="customButtonConfig"
          class="btn btn-primary mr-1"
          [disabled]="button.isDisabled() || isLoading(button.isLoading)"
          (click)="button.onClick()"
        >
          <span *ngIf="isLoading(button.isLoading); else elseBlock">
            <span class="btn-text" translate>{{ button.loadingText }}</span> &nbsp;
            <i class="fa fa-spinner fa-spin"></i>
          </span>
          <ng-template #elseBlock>
            <span>
              <span class="btn-text" translate>{{ button.text }}</span>
            </span>
          </ng-template>
          <i *ngIf="button.iconClass" [ngClass]="button.iconClass"></i>
        </button>
      </ng-container>
      <button
        type="button"
        class="btn mr-1"
        data-toggle="tooltip"
        data-placement="left"
        title="Column filter"
        *ngIf="hasFloatingFilter"
        [style.background-color]="isFloatingFilterEnabled ? '#20a8d8' : '#c8ced3'"
        (click)="onToggleFloatingFilter()"
      >
        <i class="fa fa-filter" [style.color]="isFloatingFilterEnabled ? 'white' : 'black'"></i>
      </button>
      <button
        type="button"
        class="btn btn-primary mr-1"
        data-toggle="tooltip"
        data-placement="left"
        [title]="'SHARED.' + (isAutoSize ? 'FIT_TO_WINDOW' : 'FIT_TO_COLUMN') | translate"
        (click)="onChangeColumnSize()"
      >
        <i class="fa" [ngClass]="isAutoSize ? 'fa-arrows-alt' : 'fa-arrows-h'"></i>
      </button>
      <button
        type="button"
        class="btn btn-primary mr-1"
        data-toggle="tooltip"
        data-placement="left"
        title="{{ 'SHARED.ADD_NEW' | translate }}"
        *ngIf="addNewUrl"
        [routerLink]="addNewUrl"
      >
        <i class="fa fa-plus"></i>
      </button>
      <button
        type="button"
        class="btn mr-1"
        data-toggle="tooltip"
        data-placement="left"
        title="{{ 'SHARED.EDIT' | translate }}"
        *ngIf="editUrl"
        [ngClass]="noOfSelectedRows !== 1 || disableEdit ? 'btn-secondary' : 'btn-primary'"
        [disabled]="noOfSelectedRows !== 1 || disableEdit"
        (click)="onEdit()"
      >
        <i class="fa fa-pencil"></i>
      </button>
      <button
        *ngIf="hasDelete"
        type="button"
        class="btn btn-primary mr-1"
        data-toggle="tooltip"
        data-placement="left"
        title="{{ 'SHARED.DELETE' | translate }}"
        [ngClass]="isDeleteDisabled ? 'btn-secondary' : 'btn-primary'"
        [disabled]="isDeleteDisabled"
        (click)="onDelete()"
      >
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</div>
<div class="card-header">
  <div *ngIf="rowData && !hasFloatingFilter && (rowData | async)?.length === 0; else grid">
    <span *ngIf="noRowsText; else noRecords" translate [innerText]="noRowsText"> </span>
    <ng-template #noRecords><span translate>SHARED.NO_RECORDS</span> </ng-template>
  </div>
  <ng-template #grid>
    <ag-grid-angular
      style="width: 100%"
      class="ag-theme-balham"
      domLayout="autoHeight"
      [rowHeight]="rowHeight"
      [rowData]="rowData | async"
      [columnDefs]="columnDefs"
      [defaultColDef]="columnDef"
      [frameworkComponents]="frameworkComponents"
      [context]="context"
      [suppressRowClickSelection]="suppressRowClickSelection"
      [rowSelection]="'multiple'"
      [rowMultiSelectWithClick]="true"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [pagination]="hasPagination"
      [paginationPageSize]="paginationPageSize"
      (gridReady)="onGridReady($event)"
      (rowClicked)="onRowClicked($event)"
      (columnResized)="onColumnResized($event)"
      (selectionChanged)="onSelectionChanged()"
      (sortChanged)="onSortChanged($event)"
    >
    </ag-grid-angular>
  </ng-template>

  <app-grid-pagination
    *ngIf="pagination?.total_pages"
    [pagination]="pagination"
    (pageChange)="onPageChange($event)"
  ></app-grid-pagination>
</div>
