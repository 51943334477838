import { Action, createReducer, on } from '@ngrx/store';
import { clearIpsecCertificateAction, setIpsecCertificateAction } from './ipsec-certificate.action';
import { IpsecCertificateState } from './ipsec-certificate.interface';

const initialState: IpsecCertificateState = {
  certificates: [],
};

const reducer = createReducer(
  initialState,
  on(setIpsecCertificateAction, (state, props) => ({ ...state, certificates: props.certificates })),
  on(clearIpsecCertificateAction, () => initialState)
);

export function ipsecCertificateReducer(state: IpsecCertificateState | undefined, action: Action) {
  return reducer(state, action);
}
