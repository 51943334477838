<div class="input-group">
  <input
    type="search"
    class="form-control floating-search"
    data-show-value="true"
    data-popup-enabled="true"
    [(ngModel)]="currentValue"
    (ngModelChange)="this.search.next($event)"
  />
</div>
