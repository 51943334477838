import { Action } from '@ngrx/store';
import {
  VlanInterface,
  VlanInterfaceConfiguration,
} from '@secure/devices/store/models/vlan-interface-configuration.model';
import { Columns } from '../models/shared.model';
import { InterfaceAndId } from '../models/mac-ip-binding.model';

export enum ActionTypes {
  SET_VLAN_INTERFACE_CONFIGURAION = 'SET_VLAN_INTERFACE_CONFIGURAION',
  CLEAR_CURRENT_VLAN_INTERFACE_CONFIGURATION = 'CLEAR_CURRENT_VLAN_INTERFACE_CONFIGURATION',
  VLAN_INTERFACE_COLUMN_SELECT = 'VLAN_INTERFACE_COLUMN_SELECT',
  VLAN_INTERFACE_COLUMNS_SELECT_ALL = 'VLAN_INTERFACE_COLUMNS_SELECT_ALL',
  VLAN_INTERFACE_COLUMN_DESELECT = 'VLAN_INTERFACE_COLUMN_DESELECT',
  VLAN_INTERFACE_SHUFFLED_COLUMNS = 'VLAN_INTERFACE_SHUFFLED_COLUMNS',
  VLAN_INTERFACE_ADD = 'VLAN_INTERFACE_ADD',
  VLAN_INTERFACE_EDIT = 'VLAN_INTERFACE_EDIT',
  VLAN_INTERFACE_DELETE = 'VLAN_INTERFACE_DELETE',
  DELETE_VLAN_MAC_IP_BINDING_LIST = 'DELETE_VLAN_MAC_IP_BINDING_LIST',
}

export class SetVlanInterfaceConfiguration implements Action {
  readonly type = ActionTypes.SET_VLAN_INTERFACE_CONFIGURAION;
  constructor(public payload: VlanInterfaceConfiguration) {}
}

export class ClearCurrentVlanInterfaceConfiguration implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_VLAN_INTERFACE_CONFIGURATION;
}

export class VLANInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.VLAN_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class VLANInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.VLAN_INTERFACE_COLUMNS_SELECT_ALL;
}

export class VLANInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.VLAN_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class VLANInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.VLAN_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class AddVlanInterface implements Action {
  readonly type = ActionTypes.VLAN_INTERFACE_ADD;
  constructor(public payload: VlanInterface) {}
}

export class EditVlanInterface implements Action {
  readonly type = ActionTypes.VLAN_INTERFACE_EDIT;
  constructor(public payload: VlanInterface) {}
}

export class DeleteVlanInterface implements Action {
  readonly type = ActionTypes.VLAN_INTERFACE_DELETE;
  constructor(public payload: string) {}
}

export class DeleteVlanMacIpBindingList implements Action {
  readonly type = ActionTypes.DELETE_VLAN_MAC_IP_BINDING_LIST;
  constructor(public payload: InterfaceAndId) {}
}

export type Actions =
  | SetVlanInterfaceConfiguration
  | ClearCurrentVlanInterfaceConfiguration
  | VLANInterfaceColumnSelect
  | VLANInterfaceColumnsSelectAll
  | VLANInterfaceColumnDeSelect
  | VLANInterfaceShuffledColumns
  | AddVlanInterface
  | EditVlanInterface
  | DeleteVlanInterface
  | DeleteVlanMacIpBindingList;
