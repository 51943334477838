import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppSidebarModule,
} from '@coreui/angular';
import { StoreModule } from '@ngrx/store';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RoleGuard } from '@shared/guards/role.guard';
import { HttpTokenInterceptor } from '@shared/interceptors/http-token.interceptor';
import { metaReducers } from '@store/meta-reducer';
import { P404Component } from '@views/public/error/404.component';
import { AvatarModule } from 'ngx-avatars';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MarkdownModule } from 'ngx-markdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import {
  MESSAGE_FORMAT_CONFIG,
  TranslateMessageFormatCompiler,
} from 'ngx-translate-messageformat-compiler';
import * as store from '../app/store/store';
import { GoogleAnalyticsEventService } from './analytics/event.service';
import { GoogleAnalyticsTrackPageService } from './analytics/page.service';
import { AppComponent } from './app.component';
// Import routing module
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app.routing';
import { DefaultLayoutComponent } from './containers';
import { CanDeactivateGuard } from './shared/guards/can-deactivate-guards.service';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { GlobalLanguageService } from './shared/services/global-language.service';
import { SharedModule } from './shared/shared.module';

const APP_CONTAINERS = [DefaultLayoutComponent];

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    SharedModule,
    HttpClientModule,
    TabsModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
    }),
    StoreModule.forRoot(store.reducers, {
      metaReducers: metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    BsDropdownModule.forRoot(),
    AvatarModule,
    TooltipModule.forRoot(),
    MarkdownModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
  ],
  declarations: [AppComponent, ...APP_CONTAINERS, P404Component],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['en'] } },
    GlobalLanguageService,
    CanDeactivateGuard,
    GoogleAnalyticsTrackPageService,
    GoogleAnalyticsEventService,
    RoleGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
