import { ActionTypes, Actions } from '../actions/ipsec-interface.action';
import { IpsecInterfaceReducer } from '../models/ipsec-interface.model';

export const initialState: IpsecInterfaceReducer = {
  interfaces: [],
  shuffledColumns: [
    { key: 'Name', field: 'user_defined_name', hide: false },
    { key: 'Admin Status', field: 'enable_interface', hide: false },
    { key: 'Dynamic IP', field: 'dynamic_ip_addressing_mode', hide: false },
    { key: 'Runtime Status', field: 'runtime_status', hide: false },
    { key: 'IP Addresses', field: 'system_defined_ip_addresses', hide: false },
    { key: 'Bridge', field: 'bridge_id', hide: false },
    { key: 'Type', field: 'network_side', hide: false },
    { key: 'TX-Packets', field: 'statistics.sent.packets', hide: false },
    { key: 'TX-Bytes', field: 'statistics.sent.bytes', hide: false },
    { key: 'TX-Errors', field: 'statistics.sent.errors', hide: false },
    { key: 'RX-Packets', field: 'statistics.received.packets', hide: false },
    { key: 'RX-Bytes', field: 'statistics.received.bytes', hide: false },
    { key: 'RX-Errors', field: 'statistics.received.errors', hide: false },
  ],
};

export function ipsecInterfaceReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_IPSEC_INTERFACE:
      return {
        ...state,
        interfaces: action.payload,
      };
    case ActionTypes.CLEAR_CURRENT_IPSEC_INTERFACE:
      return initialState;
    case ActionTypes.IPSEC_INTERFACE_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.IPSEC_INTERFACE_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.IPSEC_INTERFACE_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.IPSEC_INTERFACE_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    default:
      return state;
  }
}
