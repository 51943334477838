import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '@shared/models/shared.model';

@Component({
  selector: 'app-grid-pagination',
  templateUrl: './pagination.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() pagination: Pagination;
  @Output() firstPageClicked = new EventEmitter();
  @Output() previousPageClicked = new EventEmitter();
  @Output() nextPageClicked = new EventEmitter();
  @Output() lastPageClicked = new EventEmitter();
  @Output() pageChange = new EventEmitter();

  onFirstPageClick(): void {
    this.onPageChange(1);
    this.firstPageClicked.emit();
  }
  onPreviousPageClick(): void {
    this.onPageChange(this.pagination.prev_page);
    this.previousPageClicked.emit();
  }
  onNextPageClick(): void {
    this.onPageChange(this.pagination.next_page);
    this.nextPageClicked.emit();
  }
  onLastPageClick(): void {
    this.onPageChange(this.pagination.total_pages);
    this.lastPageClicked.emit();
  }
  onPageChange(page: number): void {
    this.pageChange.emit(page);
  }
}
