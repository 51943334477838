import { Action, createReducer, on } from '@ngrx/store';
import * as deviceModelActions from './device-model.action';
import { DeviceModelState } from './device-model.model';

const initialState: DeviceModelState = {
  device_model: [],
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  globalSearch: '',
  shuffledColumns: [
    { key: 'Name', field: 'name', hide: false, sort: '', minWidth: 75, width: 155 },
    { key: 'Type', field: 'device_type', hide: false, sort: '', minWidth: 75, width: 85 },
    {
      key: 'WAN Interfaces',
      field: 'wan_interfaces',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 85,
    },
    {
      key: 'LAN Interfaces',
      field: 'lan_interfaces',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 85,
    },
  ],
  hasFloatingFilter: false,
  columns: {
    name: '',
    device_type: '',
    wan_interfaces: '',
    lan_interfaces: '',
  },
};

const reducer = createReducer(
  initialState,
  on(deviceModelActions.setDeviceModelList, (state, props) => ({
    ...state,
    device_model: props.deviceModelResponse.device_models,
    pagination: props.deviceModelResponse.pagination,
  })),
  on(deviceModelActions.setDeviceModelGlobalSearch, (state, props) => ({
    ...state,
    globalSearch: props.globalSearch,
  })),
  on(deviceModelActions.setDeviceModelCurrentPage, (state, props) => ({
    ...state,
    pagination: {
      ...state.pagination,
      current_page: props.currentPage,
    },
  })),
  on(deviceModelActions.setDeviceModelShuffledColumns, (state, props) => ({
    ...state,
    shuffledColumns: props.shuffledColumns,
  })),
  on(deviceModelActions.setDeviceModelColumnSelect, (state, props) => {
    const selectedColumn = state.shuffledColumns.find(
      (column) => column.field === props.columnField
    );
    if (selectedColumn) {
      selectedColumn.hide = false;
    }
    return {
      ...state,
    };
  }),
  on(deviceModelActions.setDeviceModelColumnSelectall, (state) => ({
    ...state,
    shuffledColumns: state.shuffledColumns.map((column) => {
      return {
        ...column,
        hide: false,
      };
    }),
  })),
  on(deviceModelActions.setDeviceModelColumnDeselect, (state, props) => {
    const unSelectedColumn = state.shuffledColumns.find(
      (column) => column.field === props.columnField
    );
    if (unSelectedColumn) {
      unSelectedColumn.hide = true;
    }
    return {
      ...state,
    };
  }),
  on(deviceModelActions.setDeviceModelColumnsSort, (state, props) => {
    state.shuffledColumns.forEach((column) => {
      let fieldMatch = false;
      props.sortedColumns.forEach((data) => {
        if (column.field === data.colId) {
          fieldMatch = true;
          column.sort = data.sort;
        }
      });

      if (!fieldMatch) {
        column.sort = '';
      }
    });
    return { ...state };
  }),
  on(deviceModelActions.updateDeviceModelFloatingFilter, (state, props) => ({
    ...state,
    hasFloatingFilter: props.hasFloatingFilter,
  })),
  on(deviceModelActions.DeviceModelColumnSearch, (state, props) => {
    const key = Object.keys(props.payload)[0];
    state.columns[key] = Object.values(props.payload)[0];
    return { ...state };
  })
);

export function deviceModelReducer(state: DeviceModelState | undefined, action: Action) {
  return reducer(state, action);
}
