import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PrismaInterfaceReducer } from '../models/prisma-interface.model';

export const getPrismaInterfaceFeatureState =
  createFeatureSelector<PrismaInterfaceReducer>('prismaInterface');

export const getCurrentDevicePrismaInterfaces = createSelector(
  getPrismaInterfaceFeatureState,
  (state) => {
    const prismaInterfaces = state.interfaces?.filter(
      (currentPrismaInterface) => currentPrismaInterface.is_configured
    );
    return prismaInterfaces;
  }
);

export const getCurrentDevicePrismaInterfaceById = createSelector(
  getCurrentDevicePrismaInterfaces,
  (prismaInterfaces, props) => {
    const currentInterface = prismaInterfaces.find((prismaInterfaces) => {
      return prismaInterfaces.id === props.interfaceId;
    });
    return currentInterface;
  }
);
