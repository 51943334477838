import { createAction, props } from '@ngrx/store';
import {
  ActiveHostsConfig,
  ActiveHostsDetails,
  Device,
  NetworkInterfaces,
} from '../models/active-hosts.model';
import { FloatingFilterChangeCallBackParam } from '@shared/components/ag-grid-floating-filter/ag-grid-floating-filter.model';

export const setNetworkInterfaces = createAction(
  '[Active Hosts] network interfaces',
  props<{ networkInterfaces: NetworkInterfaces[] }>()
);

export const setActiveHostsConfig = createAction(
  '[Active Hosts] active hosts config',
  props<{ activeHostsConfig: ActiveHostsConfig }>()
);

export const setActiveHostsDetails = createAction(
  '[Active Hosts] active hosts details',
  props<{ activeHostsDetails: ActiveHostsDetails }>()
);

export const setActiveHostsColumnSearch = createAction(
  '[Active Hosts] active hosts column search',
  props<{ activeHostsColumnSearch: FloatingFilterChangeCallBackParam }>()
);

export const setActiveHostsGlobalSearch = createAction(
  '[Active Hosts] global search',
  props<{ globalSearch: string }>()
);

export const setActiveHostsCurrentPage = createAction(
  '[Active Hosts] current page',
  props<{ currentPage: number }>()
);

export const setMacIpBinding = createAction('[Active Hosts] mac ip', props<{ macIp: Device[] }>());
