import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-delete-vpn-server',
  templateUrl: './modal-delete-vpn-server.component.html',
})
export class ModalDeleteVPNServerComponent {
  title: string;
  message: string;
  vpnClients: any[]; // TODO

  constructor(public bsModalRef: BsModalRef) {}
}
