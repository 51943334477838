import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppFooterModule } from '@coreui/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { GrafanaIframeComponent } from '@shared/components/grafana-iframe/grafana-iframe.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { AgGridModule } from 'ag-grid-angular';
import { GoogleAnalyticsEventService } from 'app/analytics/event.service';
import { GoogleAnalyticsTrackPageService } from 'app/analytics/page.service';
import { NgOtpInputModule } from 'ng-otp-input';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MarkdownModule } from 'ngx-markdown';
import { AgGridCellRendererButtonsComponent } from './components/ag-grid-cell-renderer-buttons/ag-grid-cell-renderer-buttons.component';
import { AgGridFloatingFilterComponent } from './components/ag-grid-floating-filter/ag-grid-floating-filter.component';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { FloatingFilterComponent } from './components/floating-filter/floating-filter.component';
import { FooterComponent } from './components/footer/footer.component';
import { ModalBackComponent } from './components/go-back/go-back.component';
import { HelperComponent } from './components/helper-field-icon/helper.component';
import { HelperFieldComponent } from './components/helper-field/helper-field.component';
import { HelperFormIconComponent } from './components/helper-form-icon/helper-form-icon.component';
import { HelperFormComponent } from './components/helper-form/helper-form.component';
import { HelperPageComponent } from './components/helper-page/helper-page.component';
import { ListGridComponent } from './components/list-grid/list-grid.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { ModalDeleteVPNServerComponent } from './components/modal-delete-vpn-server/modal-delete-vpn-server.component';
import { ModalDeleteComponent } from './components/modal-delete/modal-delete.component';
import { ModalInfoTableComponent } from './components/modal-info-table/modal-info-table.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { ModalReasonComponent } from './components/modal-reason/modal-reason.component';
import { ListFilterPipe } from './components/multi-select/list-filter.pipe';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { NotificationAlertComponent } from './components/notification-alert/notification-alert.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RouterLinkComponent } from './components/router-link/router-link.component';
import { SearchComponent } from './components/search/search.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { MacAddressFormatterDirective } from './directives/mac-address-formatter.directive';
import { PermissionDirective } from './directives/permission.directive';
import { FilesizePipe } from './pipes/file-size.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    AgGridModule,
    DragDropModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    TabsModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule,
    DigitOnlyModule,
    RouterModule,
    NgOtpInputModule,
    AppFooterModule,
    MarkdownModule.forChild(),
    MacAddressFormatterDirective,
  ],
  declarations: [
    LoadingButtonComponent,
    DataGridComponent,
    FloatingFilterComponent,
    GrafanaIframeComponent,
    MultiSelectComponent,
    ListFilterPipe,
    FilesizePipe,
    ClickOutsideDirective,
    ModalDeleteComponent,
    ModalReasonComponent,
    ModalBackComponent,
    PaginationComponent,
    ModalInfoComponent,
    RouterLinkComponent,
    ModalInfoTableComponent,
    ModalDeleteVPNServerComponent,
    HelperPageComponent,
    HelperFormComponent,
    HelperFormIconComponent,
    HelperComponent,
    HelperFieldComponent,
    SearchComponent,
    PermissionDirective,
    NotificationAlertComponent,
    ListGridComponent,
    FooterComponent,
    AgGridCellRendererButtonsComponent,
    AgGridFloatingFilterComponent,
  ],
  exports: [
    LoadingButtonComponent,
    DataGridComponent,
    DragDropModule,
    MultiSelectComponent,
    ModalDeleteComponent,
    ModalReasonComponent,
    ModalBackComponent,
    FormsModule,
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    PaginationComponent,
    AlertModule,
    TabsModule,
    ModalModule,
    DigitOnlyModule,
    ModalInfoComponent,
    FilesizePipe,
    GrafanaIframeComponent,
    RouterLinkComponent,
    ModalInfoTableComponent,
    ModalDeleteVPNServerComponent,
    NgOtpInputModule,
    HelperPageComponent,
    MarkdownModule,
    HelperFormIconComponent,
    HelperFormComponent,
    HelperComponent,
    HelperFieldComponent,
    SearchComponent,
    PermissionDirective,
    NotificationAlertComponent,
    ListGridComponent,
    FooterComponent,
    MacAddressFormatterDirective,
  ],
  providers: [GoogleAnalyticsTrackPageService, GoogleAnalyticsEventService],
})
export class SharedModule {}
