import { ROLES } from './constants';

export const enum Permissions {
  updateUser,
  deleteUser,
  createOrganization,
  updateOrganization,
  deleteOrganization,
  remoteSSH,
  notifications,
}

export const UserPermissions = {
  [ROLES.superAdmin]: [
    Permissions.updateUser,
    Permissions.deleteUser,
    Permissions.createOrganization,
    Permissions.updateOrganization,
    Permissions.deleteOrganization,
    Permissions.remoteSSH,
  ],
  [ROLES.customerAdmin]: [Permissions.notifications],
};
