<div class="text-center" [style.height]="panelHeight">
  <div *ngIf="isLoading">
    <i class="fa fa-spinner fa-spin fa-4x text-primary" aria-hidden="true"></i>
    <p>Loading...</p>
  </div>
  <iframe
    title="Grafana panel"
    *ngIf="iframeUrl"
    [class.d-none]="isLoading"
    [src]="iframeUrl"
    (load)="onIframeLoad()"
  ></iframe>
</div>
