import { ROLES } from '@shared/constants/constants';
import { NavData } from './containers/default-layout/default-layout';

export const navItems: NavData[] = [
  {
    key: 'NAVIGATION.DASHBOARD',
    name: 'Dashboard',
    url: '',
    icon: 'icon-chart',
    roles: [ROLES.customerAdmin],
  },
  {
    key: 'NAVIGATION.ORGANIZATIONS',
    name: 'Organizations',
    url: '/organizations',
    icon: 'icon-briefcase',
  },
  {
    key: 'NAVIGATION.DEVICES',
    name: 'Devices',
    url: '/devices',
    icon: 'icon-speedometer',
  },
  {
    key: 'NAVIGATION.USERS',
    name: 'Users',
    url: '/users',
    icon: 'icon-people',
  },
  {
    key: 'NAVIGATION.SOFTWARES',
    name: 'Softwares',
    url: '/softwares',
    icon: 'icon-layers',
  },
  {
    key: 'NAVIGATION.SETTINGS',
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
  },
  {
    key: 'NAVIGATION.SLA',
    name: 'SLA',
    url: '/sla',
    icon: 'icon-doc',
  },
];
