import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SplitDnsConfigurationReducer } from '../models/split-dns.model';

export const getSplitDnsFeatureState =
  createFeatureSelector<SplitDnsConfigurationReducer>('splitDnsConfiguration');

export const getCurrentDeviceSplitDnses = createSelector(
  getSplitDnsFeatureState,
  (state) => state.split_dns
);

export const getCurrentSplitDnsColumns = createSelector(
  getSplitDnsFeatureState,
  (state) => state.shuffledColumns
);

export const getSplitDnsColumns = createSelector(
  getSplitDnsFeatureState,
  (state) => state.shuffledColumns
);
