import { Action } from '@ngrx/store';
import { Column } from '@secure/devices/store/models/shared.model';
import * as type from '../models/vpn-routing.model';

export enum ActionTypes {
  ADD_VPN_ROUTING = 'ADD_VPN_ROUTING',
  EDIT_VPN_ROUTING = 'EDIT_VPN_ROUTING',
  CLEAR_VPN_ROUTING_CONFIGURATION = 'CLEAR_VPN_ROUTING_CONFIGURATION',
  SET_VPN_ROUTING_CONFIGURATION = 'SET_VPN_ROUTING_CONFIGURATION',
  UPDATE_VPN_ROUTING_COLUMN_WIDTH = 'UPDATE_VPN_ROUTING_COLUMN_WIDTH',
  VPN_ROUTING_COLUMN_SELECT = 'VPN_ROUTING_COLUMN_SELECT',
  VPN_ROUTING_COLUMNS_SELECT_ALL = 'VPN_ROUTING_COLUMNS_SELECT_ALL',
  VPN_ROUTING_COLUMN_DESELECT = 'VPN_ROUTING_COLUMN_DESELECT',
  VPN_ROUTING_SHUFFLED_COLUMNS = 'VPN_ROUTING_SHUFFLED_COLUMNS',
  DELETE_VPN_ROUTING = 'DELETE_VPN_ROUTING',
}

export class AddVpnRouting implements Action {
  readonly type = ActionTypes.ADD_VPN_ROUTING;
  constructor(public payload: type.VpnRouting) {}
}

export class EditVpnRouting implements Action {
  readonly type = ActionTypes.EDIT_VPN_ROUTING;
  constructor(public payload: type.VpnRouting) {}
}

export class DeleteVpnAndRouting implements Action {
  readonly type = ActionTypes.DELETE_VPN_ROUTING;
  constructor(public payload: string[]) {}
}

export class ClearVpnRouting implements Action {
  readonly type = ActionTypes.CLEAR_VPN_ROUTING_CONFIGURATION;
}

export class SetVpnRoutingConfiguration implements Action {
  readonly type = ActionTypes.SET_VPN_ROUTING_CONFIGURATION;
  constructor(public payload: type.VpnRoutingCombinedConfiguration) {}
}

export class UpdateVpnRoutingColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_VPN_ROUTING_COLUMN_WIDTH;
  constructor(public payload) {}
}

export class VpnRoutingColumnSelect implements Action {
  readonly type = ActionTypes.VPN_ROUTING_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class VpnRoutingColumnsSelectAll implements Action {
  readonly type = ActionTypes.VPN_ROUTING_COLUMNS_SELECT_ALL;
}

export class VpnRoutingColumnDeSelect implements Action {
  readonly type = ActionTypes.VPN_ROUTING_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class VpnRoutingShuffledColumns implements Action {
  readonly type = ActionTypes.VPN_ROUTING_SHUFFLED_COLUMNS;
  constructor(public payload: Column[]) {}
}

export type Actions =
  | AddVpnRouting
  | EditVpnRouting
  | ClearVpnRouting
  | SetVpnRoutingConfiguration
  | UpdateVpnRoutingColumnWidth
  | VpnRoutingColumnSelect
  | VpnRoutingColumnsSelectAll
  | VpnRoutingColumnDeSelect
  | VpnRoutingShuffledColumns
  | DeleteVpnAndRouting;
