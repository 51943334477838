import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  Columns,
  Device,
  DeviceData,
  DeviceEdit,
  DeviceEditParams,
  DeviceParams,
  GetDeviceResponse,
  MultiSelectWithIdAndName,
} from '@secure/devices/store/models/devices.model';
import { StoreState } from 'app/store/store';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import * as DevicesActions from '../store/actions/devices.action';
import { TrafficSteeringStateStatistics } from '../configuration/traffic-steering/traffic-steering-state-statistics/store/traffic-steering-state-statistics.model';
import { VPNServer } from '../store/models/vpn-routing.model';
import {
  WanDestinationParams,
  WanDestinationResponse,
} from '../configuration/traffic-steering/wan-destination-settings/store/wan-destination-settings.model';
import { DeleteResponse } from '@shared/models/shared.model';
import { ActiveHostsListResponse, NetworkInterfaces } from '../store/models/active-hosts.model';
import { DeviceModelAddOrUpdateResponse } from '@views/secure/settings/device-model/store/device-model.model';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  private readonly url = environment.baseUrl;
  private page: number;
  private globalSearch: string;
  private columnSearch: Device;
  private sortedColumns: any;

  constructor(
    private http: HttpClient,
    public store: Store<StoreState>,
    public toast: ToastrService
  ) {
    this.store.pipe(select('devices')).subscribe((val) => {
      this.page = val.pagination.current_page;
      this.globalSearch = val.globalSearch;
      this.columnSearch = val.columns;
      this.sortedColumns = val.shuffledColumns;
    });
  }

  addDevice(params: DeviceParams): Observable<Device> {
    return this.http.post<Device>(`${this.url}/v1/devices`, params);
  }

  editDevice(id: number, params: DeviceEditParams): Observable<DeviceEdit> {
    return this.http.put<DeviceEdit>(`${this.url}/v1/devices/${id}`, params);
  }

  fetchDevices(gridApi) {
    const visibleColumns: string[] = [];
    gridApi.showLoadingOverlay();
    let params = new HttpParams();
    params = params.append('page', this.page.toString());
    params = params.append('q', this.globalSearch);

    for (const column in this.columnSearch) {
      if (this.columnSearch[column]) {
        params = params.append(`${column}`, this.columnSearch[column]);
      }
    }

    for (const column in this.sortedColumns) {
      if (this.sortedColumns[column].sort !== '') {
        params = params.append(
          `o_${this.sortedColumns[column].field}`,
          this.sortedColumns[column].sort.toUpperCase()
        );
      }
    }

    this.sortedColumns.forEach((column: Columns) => {
      if (!column.hide) {
        visibleColumns.push(column.field);
      }
    });

    params = params.append('visible_columns', visibleColumns.toString());

    this.http
      .get<GetDeviceResponse>(`${this.url}/v1/devices`, { params })
      .pipe(first())
      .subscribe(
        (data) => {
          this.store.dispatch(new DevicesActions.FetchDevices(data));
          gridApi.hideOverlay();
        },
        (error) => {
          this.toast.error(error.message);
          gridApi.hideOverlay();
        }
      );
  }

  fetchDevicesById(id: number): Observable<DeviceData> {
    return this.http.get<DeviceData>(`${this.url}/v1/devices/${id}`);
  }

  updateDeviceConfiguration(id, params): Observable<DeviceData> {
    return this.http.post<DeviceData>(`${this.url}/v1/devices/${id}/configurations`, params);
  }

  deleteDevices(params: any) {
    return this.http.delete(`${this.url}/v1/devices`, { params });
  }

  upgradeDevice(ids) {
    return this.http.post(`${this.url}/v1/system_upgrades`, ids);
  }

  newSSH(id) {
    return this.http.post(`${this.url}/v1/devices/${id}/new_ssh_key`, {});
  }

  getVPNservers(organizationId: number): Observable<VPNServer[]> {
    return this.http.get<VPNServer[]>(`${this.url}/v1/vpn_server/${organizationId}`);
  }

  getVpnClients(organizationId: number, serverName: string) {
    // to do type
    let params = new HttpParams();
    params = params.append('server_name', serverName);
    return this.http.get(`${this.url}/v1/vpn_clients/${organizationId}`, { params });
  }

  getTrafficSteeringStateStatistics(
    deviceId: number
  ): Observable<TrafficSteeringStateStatistics[]> {
    return this.http.get<TrafficSteeringStateStatistics[]>(
      `${this.url}/v1/traffic_steering_details/${deviceId}`
    );
  }

  getPingDestination(deviceId: number): Observable<WanDestinationResponse> {
    return this.http.get<WanDestinationResponse>(
      `${this.url}/v1/traffic_steering/ping_destination/${deviceId}`
    );
  }

  updatePingDestination(
    deviceId: number,
    params: WanDestinationParams
  ): Observable<DeleteResponse> {
    return this.http.put<DeleteResponse>(
      `${this.url}/v1/traffic_steering/ping_destination/${deviceId}`,
      params
    );
  }

  getApplicationDomainsList(deviceId: number): Observable<MultiSelectWithIdAndName[]> {
    return this.http.get<MultiSelectWithIdAndName[]>(
      `${this.url}/v1/application_domains/ids_and_names/${deviceId}`
    );
  }

  getAllNetworkInterfaces(deviceId: number): Observable<NetworkInterfaces[]> {
    return this.http.get<NetworkInterfaces[]>(`${this.url}/v1/network_interfaces/${deviceId}`);
  }

  getActiveHostsList(deviceId: number, params: HttpParams): Observable<ActiveHostsListResponse> {
    return this.http.get<ActiveHostsListResponse>(`${this.url}/v1/active_hosts/${deviceId}`, {
      params,
    });
  }

  exportCsvFile(deviceId: number): Observable<string> {
    return this.http.get(`${this.url}/v1/active_hosts/download/${deviceId}`, {
      responseType: 'text',
    });
  }

  syncDeviceBasedDNSFilter(deviceId: number): Observable<string> {
    return this.http.get<string>(`${this.url}/v1/dns_filter_configurations/sync/${deviceId}`);
  }

  getDeviceModelsList(deviceType: string): Observable<DeviceModelAddOrUpdateResponse[]> {
    let params = new HttpParams();
    params = params.append('device_type', deviceType);
    return this.http.get<DeviceModelAddOrUpdateResponse[]>(
      `${this.url}/v1/device_models/ids_and_names`,
      {
        params,
      }
    );
  }
}
