import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PageHelpResponse } from '@shared/models/shared.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalLanguageService } from './global-language.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  hasPageHelp = false;
  onPageHelpChange = new BehaviorSubject<boolean>(this.hasPageHelp);

  hasFormHelp = false;
  onFormHelpChange = new BehaviorSubject<boolean>(this.hasFormHelp);

  onFieldHelpChange = new Subject<any>();

  apiUrl = environment.baseUrl;

  constructor(
    private globalTranslate: GlobalLanguageService,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.hideAllHelp();
    });
  }

  getHelpContent(url: string): Observable<PageHelpResponse> {
    const params = new HttpParams().append('path', url);
    return this.httpClient.get<PageHelpResponse>(`${this.apiUrl}/v1/help_menu`, { params });
  }

  togglePageHelpView() {
    const currentStatus = !this.hasPageHelp;
    this.hideAllHelp();
    this.hasPageHelp = currentStatus;
    this.onPageHelpChange.next(this.hasPageHelp);
  }

  hidePageHelp() {
    this.hasPageHelp = false;
    this.onPageHelpChange.next(this.hasPageHelp);
  }

  toggleFormHelpView() {
    const currentStatus = !this.hasFormHelp;
    this.hideAllHelp();
    this.hasFormHelp = currentStatus;
    this.onFormHelpChange.next(this.hasFormHelp);
  }

  hideFormHelp() {
    this.hasFormHelp = false;
    this.onFormHelpChange.next(this.hasFormHelp);
  }

  toggleFieldHelpView(currentStatus, message) {
    const data = {
      hasView: currentStatus,
      what: this.globalTranslate.getTranslation(message + '.WHAT'),
      validations: this.globalTranslate.getTranslation(message + '.VALIDATIONS'),
    };
    this.hideAllHelp();
    this.onFieldHelpChange.next(data);
  }

  hideFieldHelp() {
    this.onFieldHelpChange.next({ hasView: false });
  }

  hideAllHelp() {
    this.hidePageHelp();
    this.hideFormHelp();
    this.hideFieldHelp();
  }
}
