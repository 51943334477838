<div class="modal-danger" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" translate>{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p translate>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="cancelDelete()">Cancel</button>
      <button type="button" class="btn btn-secondary" (click)="deleteConfirmation()">Delete</button>
    </div>
  </div>
</div>
