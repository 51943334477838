import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty, size, some } from 'lodash';
import { QoSClass, QoSConfigurationReducer } from '../models/qos-configuration.model';

export const getQoSFeatureState =
  createFeatureSelector<QoSConfigurationReducer>('qosConfiguration');

export const getCurrentDeviceQoSClasses = createSelector(
  getQoSFeatureState,
  (state) => state.qosClasses
);

export const getQoSClassesByInterface = createSelector(
  getCurrentDeviceQoSClasses,
  (qosClasses, props) => {
    let qosList = [];
    if (size(qosClasses) > 0) {
      qosList = qosClasses.filter((qosClass: QoSClass) => qosClass.interface === props.interface);
    }
    return qosList;
  }
);

export const getQoSColumns = createSelector(getQoSFeatureState, (state) => state.columns);

export const getCurrentParentsByInterface = createSelector(
  getCurrentDeviceQoSClasses,
  (qosClasses, props) => {
    let parentList = [];
    if (size(qosClasses) > 0) {
      const filterdQosClasses = qosClasses.filter(
        (qosClass: QoSClass) => qosClass.interface === props.interface
      );

      parentList = filterdQosClasses.map((qosClass) => {
        const parentId = `${qosClass.class_name}/${qosClass.class_id}`;
        return { id: qosClass.id, value: parentId };
      });
    }
    return parentList;
  }
);

export const getLeafNodes = (interfaceId: string) =>
  createSelector(getCurrentDeviceQoSClasses, (qosClasses: QoSClass[]) => {
    const leafNodes: any[] = [];
    if (size(qosClasses) > 0) {
      qosClasses.forEach((qosClass: QoSClass) => {
        if (qosClass.interface === interfaceId) {
          if (!some(qosClasses, { parent_id: qosClass.id })) {
            const parentName = getPath(qosClasses, qosClass.parent_id);
            const leafNode = isEmpty(parentName)
              ? `${qosClass.class_name}/${qosClass.class_id}`
              : `${parentName}/${qosClass.class_name}/${qosClass.class_id}`;
            leafNodes.push({ id: qosClass.id, value: leafNode });
          }
        }
      });
    }
    return leafNodes;
  });

const getPath = (array: QoSClass[], parentId: string): string => {
  const nodes = array.reduce((r, o) => ((r[o.id] = o), r), {}),
    getParent = (id: string) => {
      return id in nodes ? [...getParent(nodes[id].parent_id), nodes[id].class_name] : [];
    };
  return getParent(parentId).join('/');
};
