import { filter, includes, map } from 'lodash';
import { Actions, ActionTypes } from '../actions/static-routing-configuration.action';
import { StaticRouting, StaticRoutingConfigurationReducer } from '../models/static-routing.model';

const INITIAL_STATE: StaticRoutingConfigurationReducer = {
  id: 0,
  deviceId: 0,
  static_routing: [],
  shuffledColumns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 175, hide: false, sort: '' },
    { key: 'Description', field: 'description', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'Network', field: 'network', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'Gateway IP', field: 'gateway_ip', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'Interface', field: 'interface', minWidth: 75, width: 177, hide: false, sort: '' },
    {
      key: 'Interface Type',
      field: 'interface_type',
      minWidth: 75,
      width: 177,
      hide: false,
      sort: '',
    },
    {
      key: 'Routing Table',
      field: 'routing_table',
      minWidth: 75,
      width: 177,
      hide: false,
      sort: '',
    },
  ],
  redistribute: false,
};

export function staticRoutingConfigurationReducer(
  state = INITIAL_STATE,
  action: Actions
): StaticRoutingConfigurationReducer {
  switch (action.type) {
    case ActionTypes.SET_STATIC_ROUTING_CONFIGURATION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        static_routing: action.payload.static_routing,
        redistribute: action.payload.redistribute || false,
      };
    case ActionTypes.STATIC_ROUTING_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.ADD_STATIC_ROUTING:
      return {
        ...state,
        static_routing: [...state.static_routing, action.payload],
      };
    case ActionTypes.EDIT_STATIC_ROUTING:
      const updatedStaticRoutingList = map(state.static_routing, (staticRouting: StaticRouting) =>
        staticRouting.id === action.payload.id
          ? { ...staticRouting, ...action.payload }
          : staticRouting
      );
      return {
        ...state,
        static_routing: updatedStaticRoutingList,
      };
    case ActionTypes.DELETE_STATIC_ROUTING:
      const ids = action.payload;
      const modifiedClassList = filter(
        state.static_routing,
        (item: StaticRouting) => !includes(ids, item.id)
      );
      return {
        ...state,
        static_routing: modifiedClassList,
      };
    case ActionTypes.CLEAR_STATIC_ROUTING_CONFIGURATION:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_STATIC_ROUTING_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_REDISTRIBUTE_CHANGE:
      return {
        ...state,
        redistribute: action.payload,
      };
    default:
      return state;
  }
}
