import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsTrackPageService {
  constructor(public router: Router) {}

  public pageEmitter(screen: string) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_title: screen,
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
