import { Action } from '@ngrx/store';
import { ColumnWidth, Columns, SortColumns } from '@shared/models/shared.model';
import { DateRange, EventType, NotificationsResponse } from './notifications.model';
import { FloatingFilterChangeCallBackParam } from '@shared/components/ag-grid-floating-filter/ag-grid-floating-filter.model';

export enum NotificationsActionTypes {
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  NOTIFICATIONS_GLOBAL_SEARCH = 'NOTIFICATIONS_GLOBAL_SEARCH',
  NOTIFICATIONS_UPDATE_CURRENT_PAGE = 'NOTIFICATIONS_UPDATE_CURRENT_PAGE',
  NOTIFICATIONS_UPDATE_ACTIVE_TAB = 'NOTIFICATIONS_UPDATE_ACTIVE_TAB',
  NOTIFICATIONS_COLUMN_SELECT = 'NOTIFICATIONS_COLUMN_SELECT',
  NOTIFICATIONS_COLUMNS_SELECT_ALL = 'NOTIFICATIONS_COLUMNS_SELECT_ALL',
  NOTIFICATIONS_COLUMN_DESELECT = 'NOTIFICATIONS_COLUMN_DESELECT',
  NOTIFICATIONS_SHUFFLED_COLUMNS = 'NOTIFICATIONS_SHUFFLED_COLUMNS',
  NOTIFICATIONS_UPDATE_COLUMN_WIDTH = 'NOTIFICATIONS_UPDATE_COLUMN_WIDTH',
  UPDATE_COLUMNS_SORT = 'NOTIFICATIONS_UPDATE_COLUMNS_SORT',
  CLEAR_SELECTED_ROWS = 'NOTIFICATIONS_CLEAR_SELECTED_ROWS',
  UPDATE_FLOATING_FILTER = 'NOTIFICATIONS_UPDATE_FLOATING_FILTER',
  COLUMN_SEARCH = 'NOTIFICATION_COLUMN_SEARCH',
  NOTIFICATIONS_DATE_FILTER = 'NOIFICATIONS_DATE_FILTER',
  DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS',
}

export class FetchNotifications implements Action {
  readonly type = NotificationsActionTypes.FETCH_NOTIFICATIONS;
  constructor(public payload: NotificationsResponse) {}
}

export class NotificationsGlobalSearch implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_GLOBAL_SEARCH;
  constructor(public payload: string) {}
}

export class NotificationsUpdateCurrentPage implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_UPDATE_CURRENT_PAGE;
  constructor(public payload: number) {}
}

export class NotificationsUpdateActiveTab implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_UPDATE_ACTIVE_TAB;
  constructor(public payload: EventType | undefined) {}
}

export class NotificationsColumnSelect implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class NotificationsColumnsSelectAll implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_COLUMNS_SELECT_ALL;
}

export class NotificationsColumnDeSelect implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class NotificationsShuffledColumns implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class NotificationsUpdateColumnWidth implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_UPDATE_COLUMN_WIDTH;
  constructor(public payload: ColumnWidth[]) {}
}

export class UpdateColumnsSort implements Action {
  readonly type = NotificationsActionTypes.UPDATE_COLUMNS_SORT;
  constructor(public payload: SortColumns[]) {}
}

export class ClearSelectedRows implements Action {
  readonly type = NotificationsActionTypes.CLEAR_SELECTED_ROWS;
}

export class UpdateFloatingFilter implements Action {
  readonly type = NotificationsActionTypes.UPDATE_FLOATING_FILTER;
  constructor(public payload: boolean) {}
}

export class ColumnSearch implements Action {
  readonly type = NotificationsActionTypes.COLUMN_SEARCH;
  constructor(public payload: FloatingFilterChangeCallBackParam) {}
}

export class NotificationsDateFilter implements Action {
  readonly type = NotificationsActionTypes.NOTIFICATIONS_DATE_FILTER;
  constructor(public payload: DateRange) {}
}

export class DeleteNotifications implements Action {
  readonly type = NotificationsActionTypes.DELETE_NOTIFICATIONS;
  constructor(public payload: number[]) {}
}

export type NotificationsActions =
  | FetchNotifications
  | NotificationsGlobalSearch
  | NotificationsUpdateCurrentPage
  | NotificationsUpdateActiveTab
  | NotificationsColumnSelect
  | NotificationsColumnsSelectAll
  | NotificationsColumnDeSelect
  | NotificationsShuffledColumns
  | NotificationsUpdateColumnWidth
  | UpdateColumnsSort
  | ClearSelectedRows
  | UpdateFloatingFilter
  | ColumnSearch
  | NotificationsDateFilter
  | DeleteNotifications;
