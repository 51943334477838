import { filter, includes, map } from 'lodash';
import { Actions, ActionTypes } from '../actions/vpn-routing-configuration.action';
import { VpnRouting, VpnRoutingConfigurationReducer } from '../models/vpn-routing.model';

const INITIAL_STATE: VpnRoutingConfigurationReducer = {
  id: 0,
  deviceId: 0,
  vpn_and_routing: [],
  shuffledColumns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 148, hide: false, sort: '' },
    { key: 'Description', field: 'description', minWidth: 75, width: 147, hide: false, sort: '' },
    { key: 'LAN Subnets', field: 'lan_subnets', minWidth: 75, width: 147, hide: false, sort: '' },
    {
      key: 'LAN Interfaces',
      field: 'lan_interfaces',
      minWidth: 75,
      width: 147,
      hide: false,
      sort: '',
    },
    { key: 'Server Name', field: 'server_name', minWidth: 75, width: 147, hide: false, sort: '' },
    { key: 'Client Name', field: 'client_name', minWidth: 75, width: 147, hide: false, sort: '' },
    {
      key: 'Packet Forwarding Mode',
      field: 'packet_forwarding_mode',
      minWidth: 75,
      width: 162,
      hide: false,
      sort: '',
    },
    {
      key: 'Local UDP Port',
      field: 'local_udp_port',
      minWidth: 75,
      width: 145,
      hide: false,
      sort: '',
    },
    {
      key: 'Public Server Name',
      field: 'public_server_name',
      minWidth: 75,
      width: 145,
      hide: false,
      sort: '',
    },
    {
      key: 'Public Server Port',
      field: 'public_server_port',
      minWidth: 75,
      width: 145,
      hide: false,
      sort: '',
    },
  ],
};

export function vpnRoutingConfigurationReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_VPN_ROUTING_CONFIGURATION:
      action.payload.vpn_and_routing.forEach((vpnRouting) => {
        vpnRouting.lan_interfaces = vpnRouting.lan_interfaces.toString();
      });
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        vpn_and_routing: action.payload.vpn_and_routing,
      };
    case ActionTypes.VPN_ROUTING_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.VPN_ROUTING_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.VPN_ROUTING_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.VPN_ROUTING_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.ADD_VPN_ROUTING:
      return {
        ...state,
        vpn_and_routing: [...state.vpn_and_routing, action.payload],
      };
    case ActionTypes.EDIT_VPN_ROUTING:
      const updatedVPNRoutingList = map(state.vpn_and_routing, (vpnRouting: VpnRouting) =>
        vpnRouting.id === action.payload.id ? { ...vpnRouting, ...action.payload } : vpnRouting
      );
      return {
        ...state,
        vpn_and_routing: updatedVPNRoutingList,
      };
    case ActionTypes.DELETE_VPN_ROUTING:
      const ids = action.payload;
      const modifiedClassList = filter(
        state.vpn_and_routing,
        (item: VpnRouting) => !includes(ids, item.id)
      );
      return {
        ...state,
        vpn_and_routing: modifiedClassList,
      };
    case ActionTypes.CLEAR_VPN_ROUTING_CONFIGURATION:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_VPN_ROUTING_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
