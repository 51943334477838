<div *ngIf="hasView" class="alert alert-info">
  <i
    class="fa fa-window-close-o text-danger cursor-pointer float-right position-sticky close-icon"
    data-toggle="tooltip"
    title="{{ 'SHARED.CLOSE' | translate }}"
    (click)="onClose()"
  ></i>
  <h5>What</h5>
  <p *ngFor="let what of info.what">{{ what }}</p>
  <div *ngIf="info.validations">
    <h5>Validations</h5>
    <ul>
      <li *ngFor="let validation of info.validations">{{ validation }}</li>
    </ul>
  </div>
</div>
