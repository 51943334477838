import { OrganizationActions, OrganizationActionTypes } from '../actions/organizations.actions';
import { Organization, OrganizationsReducer } from '../models/organizations.model';

export const initialState: OrganizationsReducer = {
  columns: {
    name: '',
    uuid: '',
    devices_count: 0,
    provisioned_devices_count: 0,
    prisma_firewall: false,
    client_name: '',
    client_email: '',
    client_mobile_no: '',
    client_address: '',
  },
  organizations: [],
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  globalSearch: '',
  shuffledColumns: [
    { key: 'Name', field: 'name', hide: false, sort: '', minWidth: 75, width: 127 },
    { key: 'UUID', field: 'uuid', hide: false, sort: '', minWidth: 75, width: 200 },
    {
      key: 'Devices Count',
      field: 'devices_count',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 99,
    },
    {
      key: 'Provisioned Devices Count',
      field: 'provisioned_devices_count',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 170,
    },
    {
      key: 'Prisma Firewall',
      field: 'prisma_firewall',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 170,
    },
    { key: 'Client Name', field: 'client_name', hide: false, sort: '', minWidth: 75, width: 126 },
    { key: 'Client Email', field: 'client_email', hide: false, sort: '', minWidth: 75, width: 150 },
    {
      key: 'Client Mobile Number',
      field: 'client_mobile_no',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 128,
    },
    {
      key: 'Client Address',
      field: 'client_address',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 133,
    },
  ],

  hasFloatingFilter: false,
  selectedRows: [],
  editOrganization: {
    name: '',
    description: '',
    prisma_firewall: false,
    client_name: '',
    client_email: '',
    client_mobile_no: '',
    client_address: '',
  },
};

export function organizationsReducer(state = initialState, action: OrganizationActions) {
  switch (action.type) {
    case OrganizationActionTypes.ORG_FETCH_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload.organizations,
        pagination: action.payload.pagination,
      };
    case OrganizationActionTypes.ORG_SET_EDIT:
      return {
        ...state,
        editOrganization: action.payload,
      };
    case OrganizationActionTypes.ORG_DELETE_ORGANIZATIONS:
      const filteredData: Organization[] = [];
      state.organizations.forEach((data) => {
        if (!action.payload.includes(data.id)) {
          filteredData.push(data);
        }
      });
      return {
        ...state,
        organizations: [...filteredData],
      };
    case OrganizationActionTypes.ORG_GLOBAL_SEARCH:
      return {
        ...state,
        globalSearch: action.payload,
      };
    case OrganizationActionTypes.ORG_UPDATE_CURRENT_PAGE:
      state.pagination.current_page = action.payload;
      return {
        ...state,
      };
    case OrganizationActionTypes.ORG_UPDATE_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [...action.payload],
      };
    case OrganizationActionTypes.ORG_CLEAR_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [],
      };
    case OrganizationActionTypes.ORG_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case OrganizationActionTypes.ORG_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case OrganizationActionTypes.ORG_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case OrganizationActionTypes.ORG_COLUMNS_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case OrganizationActionTypes.ORG_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case OrganizationActionTypes.ORG_UPDATE_FLOATING_FILTER:
      return {
        ...state,
        hasFloatingFilter: action.payload,
      };
    case OrganizationActionTypes.ORG_COLUMN_SEARCH:
      const key = Object.keys(action.payload)[0];
      state.columns[key.toString()] = Object.values(action.payload)[0];
      return {
        ...state,
      };
    case OrganizationActionTypes.ORG_CLEAR_COLUMNS_SEARCH:
      return {
        ...state,
        columns: { ...initialState.columns },
      };
    case OrganizationActionTypes.ORG_UPDATE_COLUMNS_SORT:
      state.shuffledColumns.forEach((column) => {
        let fieldMatch = false;

        action.payload.forEach((data) => {
          if (column.field === data.colId) {
            fieldMatch = true;
            column.sort = data.sort;
          }
        });

        if (!fieldMatch) {
          column.sort = '';
        }
      });
      return {
        ...state,
      };
    case OrganizationActionTypes.ORG_UPDATE_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });

      return {
        ...state,
      };
    default:
      return state;
  }
}
