import { createAction, props } from '@ngrx/store';
import { Columns, SortColumns } from '@shared/models/shared.model';
import { DeviceModelResponse } from './device-model.model';
import { FloatingFilterChangeCallBackParam } from '@shared/components/ag-grid-floating-filter/ag-grid-floating-filter.model';

export const setDeviceModelList = createAction(
  '[Device Model] device model list',
  props<{ deviceModelResponse: DeviceModelResponse }>()
);

export const setDeviceModelGlobalSearch = createAction(
  '[Device Model] global search',
  props<{ globalSearch: string }>()
);

export const setDeviceModelCurrentPage = createAction(
  '[Device Model] current page',
  props<{ currentPage: number }>()
);

export const setDeviceModelShuffledColumns = createAction(
  '[Device Model] shuffled column',
  props<{ shuffledColumns: Columns[] }>()
);

export const setDeviceModelColumnSelect = createAction(
  '[Device Model] column select',
  props<{ columnField: string }>()
);

export const setDeviceModelColumnSelectall = createAction('[Device Model] column selectall');

export const setDeviceModelColumnDeselect = createAction(
  '[Device Model] column deselect',
  props<{ columnField: string }>()
);

export const setDeviceModelColumnsSort = createAction(
  '[Device Model] column sort',
  props<{ sortedColumns: SortColumns[] }>()
);

export const updateDeviceModelFloatingFilter = createAction(
  '[Device Model] floating filter',
  props<{ hasFloatingFilter: boolean }>()
);

export const DeviceModelColumnSearch = createAction(
  '[Device Model] column search',
  props<{ payload: FloatingFilterChangeCallBackParam }>()
);
