<ag-grid-angular
  class="ag-theme-balham"
  [rowData]="rowData"
  [columnDefs]="columnDefs"
  [defaultColDef]="columnDef"
  [overlayNoRowsTemplate]="overlayNoRowsTemplate"
  [frameworkComponents]="frameworkComponents"
  [animateRows]="hasRowAnimation"
  [multiSortKey]="multiSortKey"
  [pagination]="pagination"
  [paginationPageSize]="paginationPageSize"
  [rowSelection]="hasMultipleRows"
  [suppressRowClickSelection]="true"
  (selectionChanged)="onSelectionChanged()"
  (rowDataChanged)="onRowDataChanged()"
  (rowClicked)="onRowClicked($event)"
  (sortChanged)="onSortChanged($event)"
  (columnResized)="onColumnResized()"
  (gridReady)="onGridReady($event)"
  (paginationChanged)="onPaginationChanged($event)"
>
</ag-grid-angular>
