import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-reason',
  templateUrl: './modal-reason.component.html',
})
export class ModalReasonComponent implements OnInit {
  title: string;
  message: string;
  reasonForm: UntypedFormGroup;

  @Output() reason = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.reasonForm = this.formBuilder.group({
      reason: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
    });
  }

  reasonConfirmation(): void {
    this.reason.emit(this.reasonForm.value.reason);
  }
}
