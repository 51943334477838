import { DeviceType } from '@views/secure/devices/store/models/devices.model';

export const PREVIOUS_URL = 'previousUrl';
export const COLOR_PRIMARY = '#20a8d8';
export const COLOR_SECONDARY = '#c8ced3';
export const ICON_ENABLED = 'white';
export const ICON_DISABLED = 'black';

export const DEVICE_MOBILE_COLUMNS = ['name', 'uuid'];

export const PROTOCOL_LIST = [
  { id: 'all', value: 'ALL' },
  { id: 'tcp', value: 'TCP' },
  { id: 'udp', value: 'UDP' },
  { id: 'icmp', value: 'ICMP' },
];

export const DNAT_PROTOCOL_LIST = [
  { id: 'tcp', value: 'TCP' },
  { id: 'udp', value: 'UDP' },
];

export const NETWORK_TYPE = [
  { id: 'LAN', value: 'LAN' },
  { id: 'WAN', value: 'WAN' },
];

export const enum NETWORK_SIDE {
  WAN = 'WAN',
  LAN = 'LAN',
}

export const LEASE_TIME_UNIT = [
  { id: 'm', value: 'MINS' },
  { id: 'h', value: 'HOURS' },
];

export const SPEED_UNIT = [
  { id: 'kbit', value: 'kbit' },
  { id: 'mbit', value: 'mbit' },
  { id: 'gbit', value: 'gbit' },
];

export const DURATION_UNIT = [
  { id: 's', value: 's' },
  { id: 'ms', value: 'ms' },
];

export const ACTION_LIST = [
  { id: 'Drop', value: 'Drop' },
  { id: 'Accept', value: 'Accept' },
  { id: 'Reject', value: 'Reject' },
];

export const DEVICE_TYPES = [
  { id: 'Raspberry Pi', value: DeviceType.raspberryPi },
  { id: 'G1', value: DeviceType.g1 },
  { id: 'Virtual CPE', value: DeviceType.virtualCPE },
  { id: 'AWS Cloud CPE', value: DeviceType.AWS },
  { id: 'Azure Cloud CPE', value: DeviceType.azure },
];

export const PACKET_FORWARDING_MODES = [
  { name: 'NAT', value: 'NAT' },
  { name: 'Routing', value: 'Routing' },
];

export const SERVER_BRIDGES = [
  { id: 'vsbr0', value: 'VSBR0' },
  { id: 'vsbr1', value: 'VSBR1' },
  { id: 'vsbr2', value: 'VSBR2' },
  { id: 'vsbr3', value: 'VSBR3' },
  { id: 'vsbr4', value: 'VSBR4' },
];

export const ALLOWED_PROTOCOLS = ['tcp', 'udp'];

export const PARENT_LIST = [{ id: '0', value: 'RootQueue' }];

export const HW_ID_REGEX = /^hw-\d+-(\w+)-\d{13}$/;

export const FETCH_INTERVAL_DURATION = 10000;

export const MAX_BRIDGE_COUNT = 50;

export const MAX_VLAN_COUNT = 50;

export const IP_ADDRESS_WITH_SUBNET_REGEX = /^\d{1,3}(\.\d{1,3}){3}\/\d{1,2}$/;

export const DNAT_PORT_NUMBER_OR_RANGE =
  /^(([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])|([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])-([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5]))$/;

export const EMAIL_REGEX_PATTERN = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

export const RESEND_OTP_TIMER = 40;

export const AUTOMATIC = 'automatic';

export const LOAD_BALANCING = 'Load Balancing';

export const MAC_IP_BINDING = 'MAC IP Binding';
export const HARDWARE_PREFIX = 'hw';
export const VLAN_PREFIX = 'vl';
export const BRIDGE_PREFIX = 'br';

export const OTP_CONFIG = {
  length: 4,
  allowNumbersOnly: true,
};

export const TUN = 'tun';
export const TAP = 'tap';
export const TAPS = 'taps';
export const IPSEC = 'ipsec';
export const PRISMA = 'prisma';

export const enum DEVICE_STATE {
  PROVISIONED = 'Provisioned',
  NOT_PROVISIONED = 'Not Provisioned',
  BEING_DELETED = 'Being Deleted',
  DELETED = 'Deleted',
  BEING_UPDATED = 'Being Upgraded',
}

export const enum INTERFACE_STATUS {
  DOWN = 'DOWN',
  UP = 'UP',
}

export const enum DEVICE_WEB_SOCKET_CONNECTION {
  ACTIVE = 'Active',
  IN_ACTIVE = 'Inactive',
}

export const enum DEVICE_SOFTWARE_UPDATE_WEB_SOCKET {
  ACTIVE = 'Active',
  IN_ACTIVE = 'Inactive',
}

export const FORM_LEVEL_ERRORS = {
  noSteadyStateSpeed: 'DEVICES.QOS.ERROR.ATLEASEONESPEEDREQUIRED',
};

export const ROUTING_TABLES = ['default routing table', 'wan routing table'];

export const LONGITUDE_REGEX =
  /^(\-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;

export const LATITUDE_REGEX =
  /^(\-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;

export enum ROLES {
  superAdmin = 'Super Admin',
  customerAdmin = 'Customer Admin',
}

export const enum SERVER_TYPES_ENUM {
  chiefNet = 'ChiefNET',
  prisma = 'Prisma',
}

export const SERVER_TYPES = [SERVER_TYPES_ENUM.chiefNet, SERVER_TYPES_ENUM.prisma];

export const ENCRYPTIONS = ['aes-256-cbc', 'aes-128-cbc', 'aes-256-gcm', 'aes-128-gcm'];

export const AUTHENTICATIONS = ['sha1', 'sha256', 'sha384', 'sha512'];

export const DH_GROUPS = ['group1', 'group2', 'group5', 'group14', 'group19', 'group20'];

export const WAN_INTERFACES = {
  wan_interface: 'WAN',
  backup_wan_interface: 'Backup WAN',
  primary_wan_1_interface: 'WAN 1',
  primary_wan_2_interface: 'WAN 2',
  backup_wan_1_interface: 'Backup WAN 1',
  backup_wan_2_interface: 'Backup WAN 2',
};

export const enum INTERFACE_TYPE {
  hardwareInterfaces = 'hardware_interfaces',
  vlanInterfaces = 'vlan_interfaces',
  bridgeInterfaces = 'bridge_interfaces',
}

export const BROADCAST_MAC_ADDRESS = 'FF:FF:FF:FF:FF:FF';

export enum DEVICE_STATE_FOR_DNS_SYNC {
  Provisioned = 'Provisioned',
  BeingUpgraded = 'Being Upgraded',
  UpgradeFailed = 'Upgrade Failed',
  ReplacingDevice = 'Replacing Device',
}

export enum STATUS {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export const DNS_FILTER_DOMAIN_REGEX =
  /^(\*\.)?(?=.{1,253}$)((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)*[A-Za-z]{2,63}\.?$/;
