<div class="modal-info" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
      <table class="table table-bordered" aria-describedby="VPNs to delete">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Device Name</th>
            <th>VPN Client Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vpnClient of vpnClients; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ vpnClient.device_name }}</td>
            <td>{{ vpnClient.name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()" translate>
        SHARED.CLOSE
      </button>
    </div>
  </div>
</div>
