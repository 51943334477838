import { createAction, props } from '@ngrx/store';
import { IpsecServer } from './ipsec-server.interface';

export const addIpsecServerAction = createAction(
  '[Ipsec server] add',
  props<{ server: IpsecServer }>()
);

export const updateIpsecServerAction = createAction(
  '[Ipsec server] update',
  props<{ server: IpsecServer }>()
);

export const deleteIpsecServersAction = createAction(
  '[Ipsec server] delete',
  props<{ serverIds: string[] }>()
);

export const setIpsecServerAction = createAction(
  '[Ipsec server] set',
  props<{ servers: IpsecServer[] }>()
);

export const clearIpsecServerAction = createAction('[Ipsec server] clear');
