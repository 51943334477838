import { createAction, props } from '@ngrx/store';
import { TrafficSteeringStateStatistics } from './traffic-steering-state-statistics.model';

export const setTrafficSteeringStateStatisticsAction = createAction(
  '[Traffic steering state statistics] set',
  props<{ stateStatistics: TrafficSteeringStateStatistics[] }>()
);

export const clearTrafficSteeringStateStatisticsAction = createAction(
  '[Traffic steering state statistics] clear'
);
