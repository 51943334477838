export interface PacketCaptureRequestPrams {
  hardware_interface_system_defined_name: string;
  duration: number;
  packets_count: number;
}

export interface PacketCaptureStatusResponse extends PacketCaptureRequestPrams {
  status: PACKET_CAPTURE_STATUS;
  updated_at: string;
}

export enum PACKET_CAPTURE_STATUS {
  REQUESTING = 'Requesting',
  REQUESTED = 'Requested',
  CAPTURING = 'Capturing',
  CAPTURED = 'Captured',
  ERRORED = 'Errored',
  COMPLETED = 'Completed',
  TIME_OUT = 'Time out',
  STOP_REQUESTING = 'Stop requesting',
  STOP_REQUESTED = 'Stop requested',
  STOPPING = 'Stopping',
  STOPPED = 'Stopped',
}
