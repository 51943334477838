import { Columns, Pagination } from '@shared/models/shared.model';

export interface NotificationsResponse {
  events: Notification[];
  pagination: Pagination;
  unread_counts: UnreadCounts;
}

export interface Notification {
  id: number;
  name: string;
  has_read: true;
  organization_id: number;
  alert_setting_id: number;
  user_id: number;
  message: string;
  event_type_id: number;
  event_type: EventType;
  event_type_name: string;
  organization_name: string;
  created_at: string;
  updated_at: string;
}

export enum EventType {
  alert = 'Alert',
  deviceConfiguration = 'Device Configuration',
  deviceProvision = 'Device Provision',
  userActivity = 'User Activity',
  device = 'device',
}

export interface UnreadCounts {
  alert: number;
  device_configuration: number;
  device_provision: number;
  total: number;
  user_activity: number;
}

export interface MarkAllAsReadResponse {
  message: string;
}

export interface NotificationsReducer {
  globalSearch: string;
  notifications: Notification[];
  pagination: Pagination;
  shuffledColumns: Columns[];
  activeTab: EventType | undefined;
  unreadCounts: UnreadCounts;
  hasFloatingFilter: boolean;
  columns: NotificationColumnSearch;
  selectedDateRange: DateRange;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface NotificationEvent {
  created_at: string;
  event_device_id: string;
  event_type: NotificationEventTypes;
  event_type_id: string;
  event_type_name: string;
  has_read: boolean;
  id: string;
  message: string;
  organization_name: string;
  updated_at: string;
  user_id: string;
}

export enum NotificationEventTypes {
  device = 'device',
  user = 'user',
}

export interface NotificationEventResponse {
  event_count: number;
  events: NotificationEvent[];
}

export interface MarkAsReadParam {
  event_type: EventType;
}

export type NotificationColumnSearch = Pick<Notification, 'message'>;

export interface NotificationDetailResponse {
  id: number;
  name: string;
  has_read: boolean;
  organization_id: number;
  alert_setting_id: number;
  user_id: number;
  message: string;
  event_type_id: number;
  event_type: string;
  event_type_name: string;
  organization_name: string;
  created_at: string;
  updated_at: string;
}
