import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get, map } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GlobalLanguageService {
  constructor(public translate: TranslateService) {}

  getTranslation(key: string) {
    const currentLanguage = this.translate.currentLang;
    const selectedLanguage = this.translate.translations[currentLanguage];

    let translateFn = get(selectedLanguage, key);
    if (translateFn === undefined) {
      const defaultLanguage = this.translate.translations.en;
      translateFn = get(defaultLanguage, key);
    }

    if (translateFn) {
      try {
        return translateFn();
      } catch {
        const translatedValues = map(translateFn, (fn) => fn());
        return translatedValues;
      }
    } else {
      return '';
    }
  }

  changeLanguage(language: string) {
    this.translate.use(language);
  }
}
