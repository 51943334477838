import { Action } from '@ngrx/store';
import { TunnelInterfaceConfiguration } from '@secure/devices/store/models/tunnel-interface-configuration.model';
import { Columns } from '../models/shared.model';

export enum ActionTypes {
  SET_TUNNEL_INTERFACE_CONFIGURAION = 'SET_TUNNEL_INTERFACE_CONFIGURAION',
  CLEAR_CURRENT_TUNNEL_INTERFACE_CONFIGURATION = 'CLEAR_CURRENT_TUNNEL_INTERFACE_CONFIGURATION',
  HARDWARE_INTERFACE_COLUMN_SELECT = 'HARDWARE_INTERFACE_COLUMN_SELECT',
  HARDWARE_INTERFACE_COLUMNS_SELECT_ALL = 'HARDWARE_INTERFACE_COLUMNS_SELECT_ALL',
  HARDWARE_INTERFACE_COLUMN_DESELECT = 'HARDWARE_INTERFACE_COLUMN_DESELECT',
  HARDWARE_INTERFACE_SHUFFLED_COLUMNS = 'HARDWARE_INTERFACE_SHUFFLED_COLUMNS',
}

export class SetTunnelInterfaceConfiguration implements Action {
  readonly type = ActionTypes.SET_TUNNEL_INTERFACE_CONFIGURAION;
  constructor(public payload: TunnelInterfaceConfiguration) {}
}

export class ClearCurrentTunnelInterfaceConfiguration implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_TUNNEL_INTERFACE_CONFIGURATION;
}

export class TunnelInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class TunnelInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_COLUMNS_SELECT_ALL;
}

export class TunnelInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class TunnelInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export type Actions =
  | SetTunnelInterfaceConfiguration
  | ClearCurrentTunnelInterfaceConfiguration
  | TunnelInterfaceColumnSelect
  | TunnelInterfaceColumnsSelectAll
  | TunnelInterfaceColumnDeSelect
  | TunnelInterfaceShuffledColumns;
