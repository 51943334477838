import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TunnelInterfaceConfigurationReducer } from '../models/tunnel-interface-configuration.model';

export const getTunnelInterfaceFeatureState =
  createFeatureSelector<TunnelInterfaceConfigurationReducer>('tunnelInterfaceConfiguration');

export const getTUNInterfaces = createSelector(
  getTunnelInterfaceFeatureState,
  (tunInterfaceState) => {
    return tunInterfaceState.interfaces.map((tunInterface) => ({
      id: tunInterface.id,
      value: tunInterface.user_defined_name,
    }));
  }
);

export const getCurrentDeviceTunnelInterfaces = createSelector(
  getTunnelInterfaceFeatureState,
  (state) => state.interfaces
);

export const getCurrentDeviceTunnelInterfaceById = createSelector(
  getCurrentDeviceTunnelInterfaces,
  (tunnelInterfaces, props) => {
    const currentInterface = tunnelInterfaces.find(
      (tunnelInterface) => tunnelInterface.id === props.interfaceId
    );
    return currentInterface;
  }
);

export const getCurrentTunnelInterfaceColumns = createSelector(
  getTunnelInterfaceFeatureState,
  (state) => state.shuffledColumns
);
