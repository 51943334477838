import { Action } from '@ngrx/store';
import { Columns } from '@secure/devices/store/models/static-routing-detail.model';
import * as type from '../models/static-routing-detail.model';

export enum ActionTypes {
  SET_STATIC_ROUTING_DETAIL_CONFIGURATION = 'SET_STATIC_ROUTING_DETAIL_CONFIGURATION',
  CLEAR_STATIC_ROUTING_DETAIL_CONFIGURATION = 'CLEAR_STATIC_ROUTING_DETAIL_CONFIGURATION',
  UPDATE_STATIC_ROUTING_DETAIL_COLUMN_WIDTH = 'UPDATE_STATIC_ROUTING_DETAIL_COLUMN_WIDTH',
  STATIC_ROUTING_DETAIL_COLUMN_SELECT = 'STATIC_ROUTING_DETAIL_COLUMN_SELECT',
  STATIC_ROUTING_DETAIL_COLUMNS_SELECT_ALL = 'STATIC_ROUTING_DETAIL_COLUMNS_SELECT_ALL',
  STATIC_ROUTING_DETAIL_COLUMN_DESELECT = 'STATIC_ROUTING_DETAIL_COLUMN_DESELECT',
  STATIC_ROUTING_DETAIL_DESELECT_ALL = 'STATIC_ROUTING_DETAIL_DESELECT_ALL',
  STATIC_ROUTING_DETAIL_SHUFFLED_COLUMNS = 'STATIC_ROUTING_DETAIL_SHUFFLED_COLUMNS',
  STATIC_ROUTING_DETAIL_REDISTRIBUTE_CHANGE = 'STATIC_ROUTING_DETAIL_REDISTRIBUTE_CHANGE',
}

export class ClearStaticRoutingDetail implements Action {
  readonly type = ActionTypes.CLEAR_STATIC_ROUTING_DETAIL_CONFIGURATION;
}

export class SetStaticRoutingDetailConfiguration implements Action {
  readonly type = ActionTypes.SET_STATIC_ROUTING_DETAIL_CONFIGURATION;
  constructor(public payload: type.StaticRoutingDetailConfiguration) {}
}

export class UpdateStaticRoutingDetailColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_STATIC_ROUTING_DETAIL_COLUMN_WIDTH;
  constructor(public payload) {}
}

export class StaticRoutingDetailColumnSelect implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_DETAIL_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class StaticRoutingDetailColumnsSelectAll implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_DETAIL_COLUMNS_SELECT_ALL;
}

export class StaticRoutingDetailColumnDeSelect implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_DETAIL_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class StaticRoutingDetailColumnsDeSelectAll implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_DETAIL_DESELECT_ALL;
}
export class StaticRoutingDetailShuffledColumns implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_DETAIL_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class StaticRoutingRedistributeChange implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_DETAIL_REDISTRIBUTE_CHANGE;
  constructor(public payload: boolean) {}
}

export type Actions =
  | SetStaticRoutingDetailConfiguration
  | ClearStaticRoutingDetail
  | UpdateStaticRoutingDetailColumnWidth
  | StaticRoutingDetailColumnSelect
  | StaticRoutingDetailColumnsSelectAll
  | StaticRoutingDetailColumnDeSelect
  | StaticRoutingDetailColumnsDeSelectAll
  | StaticRoutingDetailShuffledColumns
  | StaticRoutingRedistributeChange;
