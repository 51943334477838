import { Component } from '@angular/core';
import { IFloatingFilterComp } from 'ag-grid-angular';
import { IFloatingFilterParams } from 'ag-grid-community';
import { debounce } from 'lodash';
import { CustomFloatingFilterParams } from './ag-grid-floating-filter.model';

@Component({
  templateUrl: './ag-grid-floating-filter.component.html',
  styleUrls: ['./ag-grid-floating-filter.component.scss'],
})
export class AgGridFloatingFilterComponent implements IFloatingFilterComp {
  params: IFloatingFilterParams & CustomFloatingFilterParams;
  currentValue: string;

  readonly onInputBoxChanged = debounce(() => {
    const colId = this.params.column.getColId();
    if (this.params.floatingFilterChangeCallBack) {
      this.params.floatingFilterChangeCallBack({ [colId]: this.currentValue });
    }
  }, 500);

  agInit(params: IFloatingFilterParams & CustomFloatingFilterParams): void {
    this.params = params;
    this.currentValue = params.currentValue;
  }

  onParentModelChanged(parentModel: any): void {
    if (!parentModel) {
      this.currentValue = null;
    } else {
      this.currentValue = parentModel.filter;
    }
  }
}
