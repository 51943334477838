import { Actions, ActionTypes } from '../actions/static-routing-detail-configuration.action';
import { StaticRoutingDetailConfigurationReducer } from '../models/static-routing-detail.model';

const INITIAL_STATE: StaticRoutingDetailConfigurationReducer = {
  id: 0,
  deviceId: 0,
  static_routing_detail: [],
  shuffledColumns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 175, hide: false, sort: '' },
    { key: 'Description', field: 'description', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'Network', field: 'network', minWidth: 75, width: 177, hide: false, sort: '' },
  ],
};

export function staticRoutingDetailConfigurationReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_STATIC_ROUTING_DETAIL_CONFIGURATION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        static_routing_detail: action.payload.static_routing,
      };
    case ActionTypes.STATIC_ROUTING_DETAIL_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_DETAIL_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_DETAIL_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_DETAIL_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case ActionTypes.STATIC_ROUTING_DETAIL_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.CLEAR_STATIC_ROUTING_DETAIL_CONFIGURATION:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_STATIC_ROUTING_DETAIL_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
