import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROLES } from '@shared/constants/constants';
import { AuthGuard } from '@shared/guards/auth-guard.service';
import { RoleGuard } from '@shared/guards/role.guard';
import { P404Component } from '@views/public/error/404.component';
import { DefaultLayoutComponent } from './containers';
import { DetailComponent } from '@views/secure/organizations/detail/detail.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'devices',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./views/public/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard/:id',
        canActivate: [RoleGuard],
        data: { roles: [ROLES.customerAdmin], title: 'Dashboard' },
        component: DetailComponent,
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('./views/secure/organizations/organizations.module').then(
            (m) => m.OrganizationsModule
          ),
      },
      {
        path: 'devices',
        loadChildren: () =>
          import('./views/secure/devices/devices.module').then((m) => m.DevicesModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./views/secure/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'softwares',
        loadChildren: () =>
          import('./views/secure/softwares/softwares.module').then((m) => m.SoftwaresModule),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./views/secure/settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'sla',
        loadChildren: () => import('./views/secure/sla/sla.module').then((m) => m.SLAModule),
      },
      {
        path: 'notifications',
        canActivate: [RoleGuard],
        data: { roles: [ROLES.customerAdmin] },
        loadChildren: () =>
          import('./views/secure/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
    ],
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
