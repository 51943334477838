import { Component, OnDestroy, OnInit } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { DevicesService } from '@secure/devices/services';
import * as DevicesActions from '@secure/devices/store/actions/devices.action';
import { StoreState } from '@secure/devices/store/models/devices.model';
import * as OrganizationActions from '@secure/organizations/store/actions/organizations.actions';
import { UsersService } from '@secure/users/services';
import * as UsersActions from '@secure/users/store/actions/users.action';
import { OrganizationsService } from '@views/secure/organizations/services/organizations.service';
import { AgFrameworkComponent } from 'ag-grid-angular';
import { IFloatingFilter, IFloatingFilterParams, TextFilter } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface FloatingFilterParams extends IFloatingFilterParams {
  value: number;
  listType: string;
  initialValue: string;
}

@Component({
  templateUrl: './floating-filter.component.html',
  styleUrls: ['./floating-filter.component.scss'],
})
export class FloatingFilterComponent
  implements IFloatingFilter, AgFrameworkComponent<FloatingFilterParams>, OnInit, OnDestroy
{
  public params: FloatingFilterParams;

  public currentValue: string;
  public initialValue: string;
  public listType: string;

  public columnSearch: Subscription;
  search = new Subject<string>();

  constructor(
    public store: Store<StoreState>,
    public devicesService: DevicesService,
    public usersService: UsersService,
    public organizationService: OrganizationsService,
    public state: State<StoreState>
  ) {}

  ngOnInit() {
    this.columnSearch = this.search
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        const columnName = this.params.filterParams.colDef.field;
        if (columnName) {
          const searchData = {
            [columnName]: value,
          };
          this.filterColumns(searchData, this.params.listType);
        }
      });
  }

  ngOnDestroy() {
    this.columnSearch.unsubscribe();
  }

  agInit(params: FloatingFilterParams) {
    this.params = params;
    const columnName: string | undefined = this.params.filterParams.colDef.field;
    if (columnName) {
      const state: any = this.state.getValue();
      const type: string = this.params.listType;
      const selectedState: any = state[type];
      this.currentValue = selectedState.columns[columnName];
    }
  }

  onParentModelChanged(parentModel: TextFilter) {
    if (!parentModel) {
      this.currentValue = '';
    }
  }

  filterColumns(searchData: any, listType: string) {
    if (listType === 'devices') {
      this.store.dispatch(new DevicesActions.UpdateCurrentPage(1));
      this.store.dispatch(new DevicesActions.ColumnSearch(searchData));
      this.devicesService.fetchDevices(this.params.api);
    }
    if (listType === 'users') {
      this.store.dispatch(new UsersActions.UpdateCurrentPage(1));
      this.store.dispatch(new UsersActions.ColumnSearch(searchData));
      this.usersService.fetchUsers(this.params.api);
    }
    if (listType === 'organizations') {
      this.store.dispatch(new OrganizationActions.UpdateCurrentPage(1));
      this.store.dispatch(new OrganizationActions.ColumnSearch(searchData));
      this.organizationService.fetchOrganizations(this.params.api);
    }
  }
}
