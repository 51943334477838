import { Action } from '@ngrx/store';
import { Columns } from '@secure/devices/store/models/dnat.model';
import * as type from '../models/dnat.model';

export enum ActionTypes {
  ADD_DNAT = 'ADD_DNAT',
  EDIT_DNAT = 'EDIT_DNAT',
  SET_DNAT_CONFIGURATION = 'SET_DNAT_CONFIGURATION',
  DELETE_DNAT = 'DELETE_DNAT',
  CLEAR_DNAT_CONFIGURATION = 'CLEAR_DNAT_CONFIGURATION',
  UPDATE_DNAT_COLUMN_WIDTH = 'UPDATE_DNAT_COLUMN_WIDTH',
  DNAT_COLUMN_SELECT = 'DNAT_COLUMN_SELECT',
  DNAT_COLUMNS_SELECT_ALL = 'DNAT_COLUMNS_SELECT_ALL',
  DNAT_COLUMN_DESELECT = 'DNAT_COLUMN_DESELECT',
  DNAT_DESELECT_ALL = 'DNAT_DESELECT_ALL',
  DNAT_SHUFFLED_COLUMNS = 'DNAT_SHUFFLED_COLUMNS',
  SET_DNAT_PINNING = 'SET_DNAT_PINNING',
}

export class AddDnat implements Action {
  readonly type = ActionTypes.ADD_DNAT;
  constructor(public payload: type.DNAT) {}
}

export class EditDnat implements Action {
  readonly type = ActionTypes.EDIT_DNAT;
  constructor(public payload: type.DNAT) {}
}

export class DeleteDnat implements Action {
  readonly type = ActionTypes.DELETE_DNAT;
  constructor(public payload: string[]) {}
}

export class ClearDnat implements Action {
  readonly type = ActionTypes.CLEAR_DNAT_CONFIGURATION;
}

export class SetDnatConfiguration implements Action {
  readonly type = ActionTypes.SET_DNAT_CONFIGURATION;
  constructor(public payload: type.DNATConfiguration) {}
}

export class UpdateDnatColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_DNAT_COLUMN_WIDTH;
  constructor(public payload) {}
}

export class DnatColumnSelect implements Action {
  readonly type = ActionTypes.DNAT_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class DnatColumnsSelectAll implements Action {
  readonly type = ActionTypes.DNAT_COLUMNS_SELECT_ALL;
}

export class DnatColumnDeSelect implements Action {
  readonly type = ActionTypes.DNAT_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class DnatColumnsDeSelectAll implements Action {
  readonly type = ActionTypes.DNAT_DESELECT_ALL;
}
export class DnatShuffledColumns implements Action {
  readonly type = ActionTypes.DNAT_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class SetDNATPinning implements Action {
  readonly type = ActionTypes.SET_DNAT_PINNING;
  constructor(public payload: boolean) {}
}

export type Actions =
  | AddDnat
  | EditDnat
  | SetDnatConfiguration
  | DeleteDnat
  | ClearDnat
  | UpdateDnatColumnWidth
  | DnatColumnSelect
  | DnatColumnsSelectAll
  | DnatColumnDeSelect
  | DnatColumnsDeSelectAll
  | DnatShuffledColumns
  | SetDNATPinning;
