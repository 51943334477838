import { createAction, props } from '@ngrx/store';
import { ApplicationDomainsResponse } from '@shared/components/application-domains/application-domains.model';
import { Columns, SortColumns } from '@shared/models/shared.model';

export const setDefaultDomainsList = createAction(
  '[Default Domains] default domains list',
  props<{ applicationDomainsResponse: ApplicationDomainsResponse }>()
);

export const setDefaultDomainsGlobalSearch = createAction(
  '[Default Domains] global search',
  props<{ globalSearch: string }>()
);

export const setDefaultDomainsCurrentPage = createAction(
  '[Default Domains] current page',
  props<{ currentPage: number }>()
);

export const setDefaultDomainsShuffledColumns = createAction(
  '[Default Domains] shuffled column',
  props<{ shuffledColumns: Columns[] }>()
);

export const setDefaultDomainsColumnSelect = createAction(
  '[Default Domains] column select',
  props<{ columnField: string }>()
);

export const setDefaultDomainsColumnSelectall = createAction('[Default Domains] column selectall');

export const setDefaultDomainsColumnDeselect = createAction(
  '[Default Domains] column deselect',
  props<{ columnField: string }>()
);

export const setDefaultDomainsColumnsSort = createAction(
  '[Default Domains] column sort',
  props<{ sortedColumns: SortColumns[] }>()
);
