import { filter, includes, map } from 'lodash';
import { Actions, ActionTypes } from '../actions/remote-ssh-configuration.action';
import { RemoteSSH, RemoteSSHConfigurationReducer } from '../models/remote-ssh.model';

const INITIAL_STATE: RemoteSSHConfigurationReducer = {
  id: 0,
  deviceId: 0,
  remoteSSHs: [],
  ssh_key: '',
  shuffledColumns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 175, hide: false, sort: '' },
    {
      key: 'Reverse SSH Port',
      field: 'reverse_ssh_port',
      minWidth: 75,
      width: 177,
      hide: false,
      sort: '',
    },
    {
      key: 'Server IP / Domain Name',
      field: 'server_ip_domain_name',
      minWidth: 75,
      width: 177,
      hide: false,
      sort: '',
    },
    {
      key: 'Server Side User Name',
      field: 'server_side_user_name',
      minWidth: 75,
      width: 177,
      hide: false,
      sort: '',
    },
  ],
};

export function remoteSSHConfigurationReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_REMOTE_SSH_CONFIGURATION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        remoteSSHs: action.payload.remote_ssh,
        ssh_key: action.payload.ssh_key,
      };
    case ActionTypes.REMOTE_SSH_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.REMOTE_SSH_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.REMOTE_SSH_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.REMOTE_SSH_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case ActionTypes.REMOTE_SSH_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.ADD_REMOTE_SSH:
      return {
        ...state,
        remoteSSHs: [...state.remoteSSHs, action.payload],
      };
    case ActionTypes.EDIT_REMOTE_SSH:
      const updatedRemoteSSHList = map(state.remoteSSHs, (remoteSSH: RemoteSSH) =>
        remoteSSH.id === action.payload.id ? { ...remoteSSH, ...action.payload } : remoteSSH
      );
      return {
        ...state,
        remoteSSHs: updatedRemoteSSHList,
      };
    case ActionTypes.DELETE_REMOTE_SSH:
      const ids = action.payload;
      const modifiedClassList = filter(
        state.remoteSSHs,
        (item: RemoteSSH) => !includes(ids, item.id)
      );
      return {
        ...state,
        remoteSSHs: modifiedClassList,
      };
    case ActionTypes.CLEAR_REMOTE_SSH_CONFIGURATION:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_REMOTE_SSH_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
