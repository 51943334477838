<span
  *ngFor="let button of customButtons"
  [ngClass]="button.content ? 'd-flex justify-content-between' : 'mx-auto'"
>
  <span *ngIf="button.content">{{ button.content(params.data) }}</span
  >&nbsp;
  <button
    class="btn btn-sm"
    [ngClass]="button.btnClass(params.data)"
    aria-hidden="true"
    [title]="button.btnTitle(params.data)"
    (click)="button.onClick(params.data)"
  >
    <i [ngClass]="button.iconClass(params.data)" aria-hidden="true"></i>
  </button>
</span>
