import { Action, createReducer, on } from '@ngrx/store';
import {
  clearTrafficSteeringStateStatisticsAction,
  setTrafficSteeringStateStatisticsAction,
} from './traffic-steering-state-statistics.action';
import { TrafficSteeringStateStatisticsState } from './traffic-steering-state-statistics.model';

const initialState: TrafficSteeringStateStatisticsState = {
  stateStatistics: [],
};

const reducer = createReducer(
  initialState,
  on(setTrafficSteeringStateStatisticsAction, (state, props) => ({
    ...state,
    stateStatistics: props.stateStatistics,
  })),
  on(clearTrafficSteeringStateStatisticsAction, () => initialState)
);

export function trafficSteeringStateStatisticsReducer(
  state: TrafficSteeringStateStatisticsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
