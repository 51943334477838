import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permissions, UserPermissions } from '@shared/constants/permission.constant';
import { SharedService } from '@shared/services/shared.service';

@Directive({
  selector: '[appPermission]',
})
export class PermissionDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private sharedService: SharedService
  ) {}

  @Input() set appPermission(permission: Permissions) {
    const allow =
      this.sharedService.role && UserPermissions[this.sharedService.role].includes(permission);
    if (allow) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
