import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getAuthToken } from '@views/public/store/selectors/auth.selector';
import { NotificationEventResponse } from '@views/secure/notifications/store/notifications.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SseService {
  private currentUserToken: string;

  hasUpdatedNotification = new Subject<void>();

  constructor(private store: Store) {
    this.store.select(getAuthToken).subscribe((token) => {
      this.currentUserToken = token?.replace('Bearer ', '');
    });
  }

  private getEventSource(url: string): EventSource {
    const options: EventSourceInit = {
      withCredentials: false,
    };
    return new EventSource(`${url}?token=${this.currentUserToken}`, options);
  }

  getServerSentEvent(url: string): Observable<NotificationEventResponse> {
    return new Observable((subscriber) => {
      const eventSource = this.getEventSource(url);

      eventSource.addEventListener('notification', (event: MessageEvent<string>) => {
        subscriber.next(<NotificationEventResponse>JSON.parse(event.data));
        this.hasUpdatedNotification.next();
      });

      return function unsubscribe(): void {
        eventSource.close();
      };
    });
  }
}
