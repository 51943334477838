import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VpnRoutingServerConfigurationReducer } from '../models/vpn-routing.model';

export const getVpnRoutingFeatureState =
  createFeatureSelector<VpnRoutingServerConfigurationReducer>('vpnRoutingServerConfiguration');

export const getCurrentDeviceVpnRoutingServers = createSelector(
  getVpnRoutingFeatureState,
  (state) => state.vpn_and_routing_server
);

export const getVpnRoutingServerColumns = createSelector(
  getVpnRoutingFeatureState,
  (state) => state.shuffledColumns
);
