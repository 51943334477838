import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '@shared/services/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-helper-form-icon',
  templateUrl: './helper-form-icon.component.html',
  styleUrls: ['./helper-form-icon.component.scss'],
})
export class HelperFormIconComponent implements OnInit, OnDestroy {
  hasView: boolean;
  subscription = new Subscription();

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    const viewObserver = this.helperService.onFormHelpChange.subscribe(
      (view) => (this.hasView = view)
    );
    this.subscription.add(viewObserver);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onIconClick() {
    this.helperService.toggleFormHelpView();
  }
}
