import { ActionReducerMap } from '@ngrx/store';
import { AuthReducer } from '@public/store/models/auth.model';
import { authReducer } from '@public/store/reducers/auth.reducer';
import { BridgeInterfaceConfigurationReducer } from '@secure/devices/store/models/bridge-interface-configuration.model';
import { DeviceConfigurationReducer } from '@secure/devices/store/models/device-configuration.model';
import { DNATConfigurationReducer } from '@secure/devices/store/models/dnat.model';
import { HardwareInterfaceConfigurationReducer } from '@secure/devices/store/models/hardware-interface-configuration.model';
import { QoSConfigurationReducer } from '@secure/devices/store/models/qos-configuration.model';
import { RemoteSSHConfigurationReducer } from '@secure/devices/store/models/remote-ssh.model';
import { SplitDnsConfigurationReducer } from '@secure/devices/store/models/split-dns.model';
import { StaticRoutingDetailConfigurationReducer } from '@secure/devices/store/models/static-routing-detail.model';
import { StaticRoutingConfigurationReducer } from '@secure/devices/store/models/static-routing.model';
import { TrafficSteeringConfigurationReducer } from '@secure/devices/store/models/traffic-steering-configuration.model';
import { UsbInterfaceConfigurationReducer } from '@secure/devices/store/models/usb-interface-configuration.model';
import { VlanInterfaceConfigurationReducer } from '@secure/devices/store/models/vlan-interface-configuration.model';
import {
  VpnRoutingConfigurationReducer,
  VpnRoutingServerConfigurationReducer,
} from '@secure/devices/store/models/vpn-routing.model';
import { WirelessInterfaceConfigurationReducer } from '@secure/devices/store/models/wireless-interface-configuration.model';
import { bridgeInterfaceConfigurationReducer } from '@secure/devices/store/reducers/bridge-interface-configuration.reducer';
import { deviceConfigurationReducer } from '@secure/devices/store/reducers/device-configuration.reducer';
import { devicesReducer } from '@secure/devices/store/reducers/devices.reducer';
import { hardwareInterfaceConfigurationReducer } from '@secure/devices/store/reducers/hardware-interface-configuration.reducer';
import { qosConfigurationReducer } from '@secure/devices/store/reducers/qos-configuration.reducer';
import { splitDnsConfigurationReducer } from '@secure/devices/store/reducers/split-dns-configuration.reducer';
import { staticRoutingConfigurationReducer } from '@secure/devices/store/reducers/static-routing-configuration.reducer';
import { staticRoutingDetailConfigurationReducer } from '@secure/devices/store/reducers/static-routing-detail-configuration.reducer';
import { trafficSteeringReducer } from '@secure/devices/store/reducers/traffic-steering-configuration.reducer';
import { tunnelInterfaceConfigurationReducer } from '@secure/devices/store/reducers/tunnel-interface-configuration.reducer';
import { usbInterfaceConfigurationReducer } from '@secure/devices/store/reducers/usb-interface-configuration.reducer';
import { vlanInterfaceConfigurationReducer } from '@secure/devices/store/reducers/vlan-interface-configuration.reducer';
import { vpnRoutingConfigurationReducer } from '@secure/devices/store/reducers/vpn-routing-configuration.reducer';
import { vpnRoutingServerConfigurationReducer } from '@secure/devices/store/reducers/vpn-routing-server-configuration.reducer';
import { wirelessInterfaceConfigurationReducer } from '@secure/devices/store/reducers/wireless-interface-configuration.reducer';
import { organizationsReducer } from '@secure/organizations/store/reducers/organizations.reducers';
import { usersReducer } from '@secure/users/store/reducers/users.reducer';
import { ApplicationDomainState } from '@shared/components/application-domains/application-domains.model';
import { IpsecCertificateState } from '@views/secure/devices/configuration/ipsec/certificate/store/ipsec-certificate.interface';
import { ipsecCertificateReducer } from '@views/secure/devices/configuration/ipsec/certificate/store/ipsec-certificate.reducer';
import { IpsecClientState } from '@views/secure/devices/configuration/ipsec/client/store/ipsec-client.interface';
import { ipsecClientReducer } from '@views/secure/devices/configuration/ipsec/client/store/ipsec-client.reducer';
import { IpsecStatisticsState } from '@views/secure/devices/configuration/ipsec/ipsec-statistics/store/ipsec-statistics.interface';
import { ipsecStatisticsReducer } from '@views/secure/devices/configuration/ipsec/ipsec-statistics/store/ipsec-statistics.reducer';
import { IpsecServerState } from '@views/secure/devices/configuration/ipsec/server/store/ipsec-server.interface';
import { ipsecServerReducer } from '@views/secure/devices/configuration/ipsec/server/store/ipsec-server.reducer';
import { TrafficSteeringStateStatisticsState } from '@views/secure/devices/configuration/traffic-steering/traffic-steering-state-statistics/store/traffic-steering-state-statistics.model';
import { trafficSteeringStateStatisticsReducer } from '@views/secure/devices/configuration/traffic-steering/traffic-steering-state-statistics/store/traffic-steering-state-statistics.reducer';
import { ActiveHostsReducer } from '@views/secure/devices/store/models/active-hosts.model';
import { IpsecInterfaceReducer } from '@views/secure/devices/store/models/ipsec-interface.model';
import { MacIpBindingReducer } from '@views/secure/devices/store/models/mac-ip-binding.model';
import { PrismaInterfaceReducer } from '@views/secure/devices/store/models/prisma-interface.model';
import { TrafficSteeringStatisticsReducer } from '@views/secure/devices/store/models/traffic-steering-statistics.model';
import { TunnelInterfaceConfigurationReducer } from '@views/secure/devices/store/models/tunnel-interface-configuration.model';
import { activeHostsReducer } from '@views/secure/devices/store/reducers/active-hosts.reducer';
import { dnatConfigurationReducer } from '@views/secure/devices/store/reducers/dnat-configuration.reducer';
import { ipsecInterfaceReducer } from '@views/secure/devices/store/reducers/ipsec-interface.reducer';
import { macIPBindingReducer } from '@views/secure/devices/store/reducers/mac-ip-binding.reducer';
import { prismaInterfaceReducer } from '@views/secure/devices/store/reducers/prisma-interface.reducer';
import { remoteSSHConfigurationReducer } from '@views/secure/devices/store/reducers/remote-ssh-configuration.reducer';
import { trafficSteeringStatisticsReducer } from '@views/secure/devices/store/reducers/traffic-steering-statistics.reducer';
import { NotificationsReducer } from '@views/secure/notifications/store/notifications.model';
import { notificationsReducer } from '@views/secure/notifications/store/notifications.reducer';
import { customDomainsReducer } from '@views/secure/organizations/settings/custom-app-domains/store/custom-domains.reducer';
import { OrganizationsReducer } from '@views/secure/organizations/store/models/organizations.model';
import { defaultDomainsReducer } from '@views/secure/settings/default-app-domains/store/default-domains.reducer';
import { PrismaServersReducer } from '@views/secure/settings/prisma-servers/store/prisma-servers.model';
import { prismaServersReducer } from '@views/secure/settings/prisma-servers/store/prisma-servers.reducer';
import { SoftwareState } from '@views/secure/softwares/store/models/software.model';
import { softwaresReducer } from '@views/secure/softwares/store/reducers/softwares.reducers';
import { UserReducer } from '@views/secure/users/store/models/users.model';
import { DeviceModelState } from '@views/secure/settings/device-model/store/device-model.model';
import { deviceModelReducer } from '@views/secure/settings/device-model/store/device-model.reducer';

export interface StoreState {
  devices: any;
  users: UserReducer;
  deviceConfiguration: DeviceConfigurationReducer;
  trafficSteeringConfiguration: TrafficSteeringConfigurationReducer;
  trafficSteeringStatistics: TrafficSteeringStatisticsReducer;
  trafficSteeringStateStatistics: TrafficSteeringStateStatisticsState;
  qosConfiguration: QoSConfigurationReducer;
  hardwareInterfaceConfiguration: HardwareInterfaceConfigurationReducer;
  wirelessInterfaceConfiguration: WirelessInterfaceConfigurationReducer;
  usbInterfaceConfiguration: UsbInterfaceConfigurationReducer;
  tunnelInterfaceConfiguration: TunnelInterfaceConfigurationReducer;
  vlanInterfaceConfiguration: VlanInterfaceConfigurationReducer;
  bridgeInterfaceConfiguration: BridgeInterfaceConfigurationReducer;
  splitDnsConfiguration: SplitDnsConfigurationReducer;
  vpnRoutingConfiguration: VpnRoutingConfigurationReducer;
  vpnRoutingServerConfiguration: VpnRoutingServerConfigurationReducer;
  auth: AuthReducer;
  organizations: OrganizationsReducer;
  softwares: SoftwareState;
  staticRoutingConfiguration: StaticRoutingConfigurationReducer;
  staticRoutingDetailConfiguration: StaticRoutingDetailConfigurationReducer;
  dnatConfiguration: DNATConfigurationReducer;
  remoteSSHConfiguration: RemoteSSHConfigurationReducer;
  ipsecServer: IpsecServerState;
  ipsecClient: IpsecClientState;
  ipsecCertificate: IpsecCertificateState;
  ipsecStatistics: IpsecStatisticsState;
  prismaServers: PrismaServersReducer;
  notifications: NotificationsReducer;
  ipsecInterface: IpsecInterfaceReducer;
  prismaInterface: PrismaInterfaceReducer;
  defaultDomains: ApplicationDomainState;
  customDomains: ApplicationDomainState;
  activeHosts: ActiveHostsReducer;
  macIpBinding: MacIpBindingReducer;
  deviceModel: DeviceModelState;
}

export const reducers: ActionReducerMap<StoreState, any> = {
  devices: devicesReducer,
  users: usersReducer,
  deviceConfiguration: deviceConfigurationReducer,
  trafficSteeringConfiguration: trafficSteeringReducer,
  trafficSteeringStatistics: trafficSteeringStatisticsReducer,
  trafficSteeringStateStatistics: trafficSteeringStateStatisticsReducer,
  qosConfiguration: qosConfigurationReducer,
  hardwareInterfaceConfiguration: hardwareInterfaceConfigurationReducer,
  wirelessInterfaceConfiguration: wirelessInterfaceConfigurationReducer,
  usbInterfaceConfiguration: usbInterfaceConfigurationReducer,
  tunnelInterfaceConfiguration: tunnelInterfaceConfigurationReducer,
  vlanInterfaceConfiguration: vlanInterfaceConfigurationReducer,
  bridgeInterfaceConfiguration: bridgeInterfaceConfigurationReducer,
  splitDnsConfiguration: splitDnsConfigurationReducer,
  vpnRoutingConfiguration: vpnRoutingConfigurationReducer,
  vpnRoutingServerConfiguration: vpnRoutingServerConfigurationReducer,
  auth: authReducer,
  organizations: organizationsReducer,
  softwares: softwaresReducer,
  staticRoutingConfiguration: staticRoutingConfigurationReducer,
  staticRoutingDetailConfiguration: staticRoutingDetailConfigurationReducer,
  dnatConfiguration: dnatConfigurationReducer,
  remoteSSHConfiguration: remoteSSHConfigurationReducer,
  ipsecServer: ipsecServerReducer,
  ipsecClient: ipsecClientReducer,
  ipsecCertificate: ipsecCertificateReducer,
  ipsecStatistics: ipsecStatisticsReducer,
  prismaServers: prismaServersReducer,
  notifications: notificationsReducer,
  ipsecInterface: ipsecInterfaceReducer,
  prismaInterface: prismaInterfaceReducer,
  defaultDomains: defaultDomainsReducer,
  customDomains: customDomainsReducer,
  activeHosts: activeHostsReducer,
  macIpBinding: macIPBindingReducer,
  deviceModel: deviceModelReducer,
};
