import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './modal-delete.component.html',
})
export class ModalDeleteComponent {
  title: string;
  message: string;

  @Output() delete = new EventEmitter<boolean>();

  constructor(public bsModalRef: BsModalRef) {}

  deleteConfirmation(): void {
    this.delete.emit(true);
  }

  cancelDelete() {
    this.bsModalRef.hide();
    this.delete.emit(false);
  }
}
