import { filter, includes, map } from 'lodash';
import { Actions, ActionTypes } from '../actions/split-dns-configuration.action';
import { SplitDns, SplitDnsConfigurationReducer } from '../models/split-dns.model';

const INITIAL_STATE: SplitDnsConfigurationReducer = {
  id: 0,
  deviceId: 0,
  split_dns: [],
  shuffledColumns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 175, hide: false, sort: '' },
    { key: 'Description', field: 'description', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'Domain Name', field: 'domain_name', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'DNS Server 1', field: 'dns_server_1', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'DNS Server 2', field: 'dns_server_2', minWidth: 75, width: 177, hide: false, sort: '' },
  ],
};

export function splitDnsConfigurationReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_SPLIT_DNS_CONFIGURATION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        split_dns: action.payload.split_dns,
      };
    case ActionTypes.SPLIT_DNS_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.SPLIT_DNS_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.SPLIT_DNS_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.SPLIT_DNS_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case ActionTypes.SPLIT_DNS_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.ADD_SPLIT_DNS:
      return {
        ...state,
        split_dns: [...state.split_dns, action.payload],
      };
    case ActionTypes.EDIT_SPLIT_DNS:
      const updatedSplitDnsList = map(state.split_dns, (splitDns: SplitDns) =>
        splitDns.id === action.payload.id ? { ...splitDns, ...action.payload } : splitDns
      );
      return {
        ...state,
        split_dns: updatedSplitDnsList,
      };
    case ActionTypes.DELETE_SPLIT_DNS:
      const ids = action.payload;
      const modifiedClassList = filter(
        state.split_dns,
        (item: SplitDns) => !includes(ids, item.id)
      );
      return {
        ...state,
        split_dns: modifiedClassList,
      };
    case ActionTypes.CLEAR_SPLIT_DNS_CONFIGURATION:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_SPLIT_DNS_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
