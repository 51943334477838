import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
})
export class ModalInfoComponent {
  title: string;
  message: string;
  buttonText: string;
  hasActionButton: boolean;
  isLoading = false;
  loadingText: string;

  @Output() action = new EventEmitter<boolean>();

  constructor(public bsModalRef: BsModalRef) {}

  public confirmAction(): void {
    this.isLoading = true;
    this.action.emit(true);
  }

  public resetLoading(): void {
    this.isLoading = false;
  }
}
