import { filter, includes } from 'lodash';
import { Actions, ActionTypes } from '../actions/vpn-routing-server-configuration.action';
import {
  VpnRoutingServer,
  VpnRoutingServerConfigurationReducer,
} from '../models/vpn-routing.model';

const INITIAL_STATE: VpnRoutingServerConfigurationReducer = {
  id: 0,
  deviceId: 0,
  vpn_and_routing_server: [],
  shuffledColumns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 148, hide: false, sort: '' },
    { key: 'Description', field: 'description', minWidth: 75, width: 147, hide: false, sort: '' },
    { key: 'Server Name', field: 'server_name', minWidth: 75, width: 147, hide: false, sort: '' },
    {
      key: 'Server Bridge',
      field: 'server_bridge',
      minWidth: 75,
      width: 147,
      hide: false,
      sort: '',
    },
    { key: 'Port', field: 'port', minWidth: 75, width: 147, hide: false, sort: '' },
    { key: 'LAN Subnets', field: 'lan_subnets', minWidth: 75, width: 147, hide: false, sort: '' },
    {
      key: 'LAN Interfaces',
      field: 'lan_interfaces',
      minWidth: 75,
      width: 147,
      hide: false,
      sort: '',
    },
  ],
};

export function vpnRoutingServerConfigurationReducer(
  state = INITIAL_STATE,
  action: Actions
): VpnRoutingServerConfigurationReducer {
  switch (action.type) {
    case ActionTypes.SET_VPN_ROUTING_SERVER_CONFIGURATION:
      action.payload.vpn_and_routing_server.forEach((vpnRouting) => {
        vpnRouting.lan_interfaces = vpnRouting.lan_interfaces.toString();
      });
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        vpn_and_routing_server: action.payload.vpn_and_routing_server,
      };
    case ActionTypes.VPN_ROUTING_SERVER_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.VPN_ROUTING_SERVER_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.VPN_ROUTING_SERVER_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.VPN_ROUTING_SERVER_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case ActionTypes.VPN_ROUTING_SERVER_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.ADD_VPN_ROUTING_SERVER:
      return {
        ...state,
        vpn_and_routing_server: [...state.vpn_and_routing_server, action.payload],
      };
    case ActionTypes.EDIT_VPN_ROUTING_SERVER:
      const updatedVPNRoutingList: VpnRoutingServer[] = state.vpn_and_routing_server.map(
        (vpnRouting) =>
          vpnRouting.id === action.payload.id ? { ...vpnRouting, ...action.payload } : vpnRouting
      );
      return {
        ...state,
        vpn_and_routing_server: updatedVPNRoutingList,
      };
    case ActionTypes.DELETE_VPN_ROUTING_SERVER:
      const ids = action.payload;
      const modifiedClassList: VpnRoutingServer[] = filter(
        state.vpn_and_routing_server,
        (item: VpnRoutingServer) => !includes(ids, item.id)
      );
      return {
        ...state,
        vpn_and_routing_server: modifiedClassList,
      };
    case ActionTypes.CLEAR_VPN_ROUTING_SERVER_CONFIGURATION:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_VPN_ROUTING_SERVER_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
