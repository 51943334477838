<div *ngIf="hasView" class="alert alert-info">
  <i
    class="fa fa-window-close-o text-danger cursor-pointer float-right position-sticky close-icon"
    data-toggle="tooltip"
    title="{{ 'SHARED.CLOSE' | translate }}"
    (click)="onClose()"
  ></i>
  <p *ngIf="isAllFormValid; else elseBlock">All validations are successful</p>
  <ng-template #elseBlock>
    <div>
      <h6>Following fields are not valid</h6>
      <ng-container *ngFor="let errorGroup of errorFields">
        <p *ngFor="let errorField of errorGroup">
          <i class="fa fa-times text-danger"></i>
          {{ errorField }}
        </p>
      </ng-container>
      <p *ngFor="let error of formErrors">
        <i class="fa fa-times text-danger"></i>
        {{ error | translate }}
      </p>
    </div>
  </ng-template>
</div>
