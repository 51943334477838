import { Actions, ActionTypes } from '../actions/mac-ip-binding.action';
import { MacIpBindingReducer } from '../models/mac-ip-binding.model';

export const initialState: MacIpBindingReducer = {
  macIpBindingToBeEdited: [],
};

export function macIPBindingReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.EDIT_MAC_IP_BINDING_LIST:
      return {
        ...state,
        macIpBindingToBeEdited: [...action.payload],
      };
    case ActionTypes.CLEAR_MAC_IP_BINDING_LIST_TO_BE_EDITED:
      return {
        ...state,
        macIpBindingToBeEdited: [],
      };

    default:
      return state;
  }
}
