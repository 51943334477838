import { Component, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '@shared/services/helper.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-helper-field',
  templateUrl: './helper-field.component.html',
  styleUrls: ['./helper-field.component.scss'],
})
export class HelperFieldComponent implements OnInit, OnDestroy {
  hasView = false;
  info: any;
  subscriptions = new Subscription();

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    const Observer = this.helperService.onFieldHelpChange.subscribe((info) => {
      this.hasView = info.hasView;
      this.info = info;
    });
    this.subscriptions.add(Observer);
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  onClose() {
    this.helperService.hideFieldHelp();
  }
}
