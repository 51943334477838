import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthService } from '@views/public/auth/services/auth.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-grafana-iframe',
  templateUrl: './grafana-iframe.component.html',
  styleUrls: ['./grafana-iframe.component.scss'],
})
export class GrafanaIframeComponent implements OnInit {
  isLoading: boolean;
  iframeUrl: SafeUrl;
  private _panelUrl: string;
  private token: string;
  private readonly grfanaBaseUrl = environment.grafanaBaseUrl;

  @Input() set panelUrl(url: string) {
    this.isLoading = true;
    this._panelUrl = url;
    this.updateIframeUrl();
  }
  @Input() refresh = '10s';
  @Input() panelHeight: string;

  constructor(
    private domSanitizer: DomSanitizer,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.token = this.authService.authToken()?.replace('Bearer ', '');
  }

  private updateIframeUrl(): void {
    if (this.token && this._panelUrl) {
      const url = encodeURI(
        `${this.grfanaBaseUrl}/${this._panelUrl}&orgId=1&refresh=${this.refresh}&var-grafana_base_url=${this.grfanaBaseUrl}&var-user_token=${this.token}&token=${this.token}`
      );
      this.iframeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  public onIframeLoad(): void {
    setTimeout(() => (this.isLoading = false), 1500);
  }
}
