import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TrafficSteeringConfigurationReducer } from '../models/traffic-steering-configuration.model';

export const getDeviceFeatureState = createFeatureSelector<TrafficSteeringConfigurationReducer>(
  'trafficSteeringConfiguration'
);

export const getCurrentTrafficSteering = createSelector(
  getDeviceFeatureState,
  (state) => state.traffic_steering
);
