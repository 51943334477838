import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  standalone: true,
  selector: '[appMacAddressFormatter]',
})
export class MacAddressFormatterDirective {
  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const macAddress = (event.target as HTMLInputElement).value
      .replace(/[^0-9A-Fa-f]/g, '')
      .toUpperCase();
    const withoutColonsPattern = /^[0-9A-F]{12}$/;
    if (macAddress && withoutColonsPattern.test(macAddress)) {
      const formattedValue = macAddress.match(/.{1,2}/g)?.join(':') || '';
      this.control.control?.setValue(formattedValue);
    }
  }
}
