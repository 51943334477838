import { Action } from '@ngrx/store';
import { Columns } from '@secure/devices/store/models/static-routing.model';
import * as type from '../models/static-routing.model';

export enum ActionTypes {
  ADD_STATIC_ROUTING = 'ADD_STATIC_ROUTING',
  EDIT_STATIC_ROUTING = 'EDIT_STATIC_ROUTING',
  SET_STATIC_ROUTING_CONFIGURATION = 'SET_STATIC_ROUTING_CONFIGURATION',
  DELETE_STATIC_ROUTING = 'DELETE_STATIC_ROUTING',
  CLEAR_STATIC_ROUTING_CONFIGURATION = 'CLEAR_STATIC_ROUTING_CONFIGURATION',
  UPDATE_STATIC_ROUTING_COLUMN_WIDTH = 'UPDATE_STATIC_ROUTING_COLUMN_WIDTH',
  STATIC_ROUTING_COLUMN_SELECT = 'STATIC_ROUTING_COLUMN_SELECT',
  STATIC_ROUTING_COLUMNS_SELECT_ALL = 'STATIC_ROUTING_COLUMNS_SELECT_ALL',
  STATIC_ROUTING_COLUMN_DESELECT = 'STATIC_ROUTING_COLUMN_DESELECT',
  STATIC_ROUTING_DESELECT_ALL = 'STATIC_ROUTING_DESELECT_ALL',
  STATIC_ROUTING_SHUFFLED_COLUMNS = 'STATIC_ROUTING_SHUFFLED_COLUMNS',
  STATIC_ROUTING_REDISTRIBUTE_CHANGE = 'STATIC_ROUTING_REDISTRIBUTE_CHANGE',
}

export class AddStaticRouting implements Action {
  readonly type = ActionTypes.ADD_STATIC_ROUTING;
  constructor(public payload: type.StaticRouting) {}
}

export class EditStaticRouting implements Action {
  readonly type = ActionTypes.EDIT_STATIC_ROUTING;
  constructor(public payload: type.StaticRouting) {}
}

export class DeleteStaticRouting implements Action {
  readonly type = ActionTypes.DELETE_STATIC_ROUTING;
  constructor(public payload: string[]) {}
}

export class ClearStaticRouting implements Action {
  readonly type = ActionTypes.CLEAR_STATIC_ROUTING_CONFIGURATION;
}

export class SetStaticRoutingConfiguration implements Action {
  readonly type = ActionTypes.SET_STATIC_ROUTING_CONFIGURATION;
  constructor(public payload: type.StaticRoutingConfiguration) {}
}

export class UpdateStaticRoutingColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_STATIC_ROUTING_COLUMN_WIDTH;
  constructor(public payload) {}
}

export class StaticRoutingColumnSelect implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class StaticRoutingColumnsSelectAll implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_COLUMNS_SELECT_ALL;
}

export class StaticRoutingColumnDeSelect implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class StaticRoutingColumnsDeSelectAll implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_DESELECT_ALL;
}
export class StaticRoutingShuffledColumns implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class StaticRoutingRedistributeChange implements Action {
  readonly type = ActionTypes.STATIC_ROUTING_REDISTRIBUTE_CHANGE;
  constructor(public payload: boolean) {}
}

export type Actions =
  | AddStaticRouting
  | EditStaticRouting
  | SetStaticRoutingConfiguration
  | DeleteStaticRouting
  | ClearStaticRouting
  | UpdateStaticRoutingColumnWidth
  | StaticRoutingColumnSelect
  | StaticRoutingColumnsSelectAll
  | StaticRoutingColumnDeSelect
  | StaticRoutingColumnsDeSelectAll
  | StaticRoutingShuffledColumns
  | StaticRoutingRedistributeChange;
