import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BridgeInterfaceConfigurationReducer } from '../models/bridge-interface-configuration.model';

export const getBridgeInterfaceFeatureState =
  createFeatureSelector<BridgeInterfaceConfigurationReducer>('bridgeInterfaceConfiguration');

export const getCurrentDeviceBridgeInterfaces = createSelector(
  getBridgeInterfaceFeatureState,
  (state) => state.interfaces
);

export const getCurrentDeviceBridgeInterfaceById = createSelector(
  getCurrentDeviceBridgeInterfaces,
  (bridgeInterfaces, props) => {
    const currentInterface = bridgeInterfaces.find(
      (bridgeInterface) => bridgeInterface.id === props.interfaceId
    );
    return currentInterface;
  }
);

export const getCurrentBridgeInterfaceColumns = createSelector(
  getBridgeInterfaceFeatureState,
  (state) => state.shuffledColumns
);
