import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@views/public/auth/services/auth.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.authToken();
    if (authToken && request.url.startsWith(environment.baseUrl)) {
      request = request.clone({
        setHeaders: {
          Authorization: `${authToken}`,
        },
      });
    }

    return next.handle(request);
  }
}
