import { Action } from '@ngrx/store';
import {
  AddOrEditTrafficSteering,
  DeleteTrafficSteering,
  ReorderTrafficSteering,
} from '@secure/devices/store/models/traffic-steering-configuration.model';
import { TrafficSteeringConfiguration } from './../models/traffic-steering-configuration.model';

export enum ActionTypes {
  ADD_TRAFFIC_STEERING_CONFIGURATION = 'ADD_TRAFFIC_STEERING_CONFIGURATION',
  EDIT_TRAFFIC_STEERING_CONFIGURATION = 'EDIT_TRAFFIC_STEERING_CONFIGURATION',
  SET_TRAFFIC_STEERING_CONFIGURATION_BY_ID = 'SET_TRAFFIC_STEERING_CONFIGURATION_BY_ID',
  DELETE_TRAFFIC_STEERING_CONFIGURATION = 'DELETE_TRAFFIC_STEERING_CONFIGURATION',
  CLEAR_CURRENT_TRAFFIC_STEERING_CONFIGURATION = 'CLEAR_CURRENT_TRAFFIC_STEERING_CONFIGURATION',
  REORDER_TRAFFIC_STEERING_CONFIGURATION = 'REORDER_TRAFFIC_STEERING_CONFIGURATION',
}

export class AddDeviceConfiguration implements Action {
  readonly type = ActionTypes.ADD_TRAFFIC_STEERING_CONFIGURATION;
  constructor(public payload: AddOrEditTrafficSteering) {}
}

export class EditDeviceConfiguration implements Action {
  readonly type = ActionTypes.EDIT_TRAFFIC_STEERING_CONFIGURATION;
  constructor(public payload: AddOrEditTrafficSteering) {}
}

export class DeleteDeviceConfiguration implements Action {
  readonly type = ActionTypes.DELETE_TRAFFIC_STEERING_CONFIGURATION;
  constructor(public payload: DeleteTrafficSteering) {}
}

export class SetTrafficSteeringById implements Action {
  readonly type = ActionTypes.SET_TRAFFIC_STEERING_CONFIGURATION_BY_ID;
  constructor(public payload: TrafficSteeringConfiguration) {}
}

export class ClearCurrentDevice implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_TRAFFIC_STEERING_CONFIGURATION;
}

export class OrderTrafficSteering implements Action {
  readonly type = ActionTypes.REORDER_TRAFFIC_STEERING_CONFIGURATION;
  constructor(public payload: ReorderTrafficSteering) {}
}

export type Actions =
  | SetTrafficSteeringById
  | AddDeviceConfiguration
  | EditDeviceConfiguration
  | DeleteDeviceConfiguration
  | ClearCurrentDevice
  | OrderTrafficSteering;
