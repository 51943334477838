import { Actions, ActionTypes } from '../actions/traffic-steering-statistics.action';
import { TrafficSteeringStatisticsReducer } from '../models/traffic-steering-statistics.model';

export const initialState: TrafficSteeringStatisticsReducer = {
  id: 0,
  device_id: 0,
  traffic_steering: {
    interface_state: null,
    iptables_stats: null,
  },
};

export function trafficSteeringStatisticsReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_TRAFFIC_STEERING_STATISTICS:
      return {
        ...state,
        id: action.payload.id,
        device_id: action.payload.device_id,
        traffic_steering: action.payload.traffic_steering,
      };
    case ActionTypes.CLEAR_TRAFFIC_STEERING_STATISTICS:
      return initialState;
    default:
      return state;
  }
}
