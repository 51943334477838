import { SoftwareActions, SoftwareActionTypes } from '../actions/softwares.actions';
import { SoftwareState } from '../models/software.model';

export const initialState: SoftwareState = {
  columns: {
    name: '',
    description: '',
    version: '',
  },
  softwares: [],
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  globalSearch: '',
  shuffledColumns: [
    { key: 'Name', field: 'name', hide: false, sort: '', minWidth: 75, width: 127 },
    {
      key: 'Description',
      field: 'description',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 99,
    },
    {
      key: 'Version',
      field: 'version',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 170,
    },
  ],
  hasFloatingFilter: false,
};

export function softwaresReducer(state = initialState, action: SoftwareActions) {
  switch (action.type) {
    case SoftwareActionTypes.SOFTWARE_FETCH:
      return {
        ...state,
        softwares: action.payload,
      };
    case SoftwareActionTypes.SOFTWARE_GLOBAL_SEARCH:
      return {
        ...state,
        globalSearch: action.payload,
      };
    case SoftwareActionTypes.SOFTWARE_UPDATE_CURRENT_PAGE:
      state.pagination.current_page = action.payload;
      return {
        ...state,
      };
    case SoftwareActionTypes.SOFTWARE_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case SoftwareActionTypes.SOFTWARE_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case SoftwareActionTypes.SOFTWARE_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case SoftwareActionTypes.SOFTWARE_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case SoftwareActionTypes.SOFTWARE_UPDATE_FLOATING_FILTER:
      return {
        ...state,
        hasFloatingFilter: action.payload,
      };
    case SoftwareActionTypes.SOFTWARE_CLEAR_COLUMNS_SEARCH:
      return {
        ...state,
        columns: { ...initialState.columns },
      };
    case SoftwareActionTypes.SOFTWARE_UPDATE_COLUMNS_SORT:
      state.shuffledColumns.forEach((column) => {
        let fieldMatch = false;

        action.payload.forEach((data) => {
          if (column.field === data.colId) {
            fieldMatch = true;
            column.sort = data.sort;
          }
        });

        if (!fieldMatch) {
          column.sort = '';
        }
      });
      return {
        ...state,
      };
    case SoftwareActionTypes.SOFTWARE_UPDATE_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });

      return {
        ...state,
      };
    default:
      return state;
  }
}
