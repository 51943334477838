import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  template: '<a [routerLink]="[]" (click)="navigate(params.inRouterLink)">{{params.value}}</a>',
})
export class RouterLinkComponent implements AgRendererComponent {
  params: any;

  constructor(
    private ngZone: NgZone,
    private router: Router
  ) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  navigate(link) {
    this.ngZone.run(() => {
      this.router.navigate([link, this.params.valueFormatted]);
    });
  }
}
