import { GetUser, UserReducer } from '@secure/users/store/models/users.model';
import { Actions, ActionTypes } from '../actions/users.action';

export const initialState: UserReducer = {
  columns: {
    name: '',
    email: '',
    role: null,
    organization_id: '',
  },
  hasFloatingFilter: false,
  globalSearch: '',
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  selectedRows: [],
  shuffledColumns: [
    { key: 'Name', field: 'name', hide: false, sort: '', minWidth: 75, width: 200 },
    { key: 'Email', field: 'email', hide: false, sort: '', minWidth: 75, width: 200 },
    { key: 'Role', field: 'role', hide: false, sort: '', minWidth: 75, width: 200 },
    {
      key: 'Organization',
      field: 'organization.name',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 200,
    },
  ],
  users: [],
  editUser: {
    name: '',
    email: '',
    role: null,
    organization_id: '',
  },
};

export function usersReducer(state = initialState, action: Actions): UserReducer {
  switch (action.type) {
    case ActionTypes.FETCH_USERS:
      return {
        ...state,
        users: action.payload.users,
        pagination: action.payload.pagination,
      };
    case ActionTypes.USER_UPDATE_CURRENT_PAGE:
      state.pagination.current_page = action.payload;
      return {
        ...state,
      };
    case ActionTypes.USER_GLOBAL_SEARCH:
      return {
        ...state,
        globalSearch: action.payload,
      };
    case ActionTypes.USER_CLEAR_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [],
      };
    case ActionTypes.USER_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.USER_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.USER_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.USER_COLUMNS_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case ActionTypes.USER_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.USER_COLUMN_SEARCH:
      const key = Object.keys(action.payload)[0];
      state.columns[key] = Object.values(action.payload)[0];
      return {
        ...state,
      };
    case ActionTypes.USER_UPDATE_FLOATING_FILTER:
      return {
        ...state,
        hasFloatingFilter: action.payload,
      };
    case ActionTypes.USER_CLEAR_COLUMNS_SEARCH:
      return {
        ...state,
        columns: { ...initialState.columns },
      };
    case ActionTypes.USER_CLEAR_COLUMNS_SORT:
      state.shuffledColumns.forEach((data) => {
        data.sort = '';
      });
      return {
        ...state,
      };
    case ActionTypes.USER_UPDATE_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [...action.payload],
      };
    case ActionTypes.USER_UPDATE_COLUMNS_SORT:
      state.shuffledColumns.forEach((column) => {
        let fieldMatch = false;

        action.payload.forEach((data) => {
          if (column.field === data.colId) {
            fieldMatch = true;
            column.sort = data.sort;
          }
        });

        if (!fieldMatch) {
          column.sort = '';
        }
      });

      return {
        ...state,
      };
    case ActionTypes.USER_UPDATE_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });

      return {
        ...state,
      };
    case ActionTypes.DELETE_USERS:
      const filteredData: GetUser[] = [];
      state.users.forEach((data) => {
        if (!action.payload.includes(data.id)) {
          filteredData.push(data);
        }
      });
      return {
        ...state,
        users: [...filteredData],
      };
    case ActionTypes.SET_EDIT_USER:
      return {
        ...state,
        editUser: action.payload,
      };
    default:
      return state;
  }
}
