<div class="pagination-container">
  <div class="row row-top">
    <div class="col-12 grid-bottom-pagination py-2">
      <div class="flex-container pull-right pr-3">
        <span
          class="mr-2"
          translate
          [translateParams]="{ start: pagination.start_at | number, end: pagination.end_at | number, total: pagination.total_count | number }"
        >
          PAGINATION.LISTCOUNT
        </span>
        <button
          id="firstPage"
          class="pagination-btn d-inline-flex"
          [disabled]="pagination.is_first_page"
          (click)="onFirstPageClick()"
        >
          <img
            src="assets/img/icons/first.svg"
            alt="first"
            class="mr-1"
            [class.clickable]="!pagination.is_first_page"
          />
        </button>
        <button
          id="previouspage"
          class="pagination-btn d-inline-flex"
          [disabled]="pagination.is_first_page"
          (click)="onPreviousPageClick()"
        >
          <img
            src="assets/img/icons/previous.svg"
            alt="previous"
            class="mr-1"
            [class.clickable]="!pagination.is_first_page"
          />
        </button>
        <span
          class="mr-2"
          translate
          [translateParams]="{ current: pagination.current_page | number, total: pagination.total_pages | number }"
        >
          PAGINATION.PAGESCOUNT
        </span>
        <button
          type="button"
          id="nextPage"
          class="pagination-btn d-inline-flex"
          [disabled]="pagination.is_last_page"
          (click)="onNextPageClick()"
        >
          <img
            src="assets/img/icons/next.svg"
            alt="next"
            class="mr-1"
            [class.clickable]="!pagination.is_last_page"
          />
        </button>
        <button
          type="button"
          id="lastPage"
          class="pagination-btn d-inline-flex"
          [disabled]="pagination.is_last_page"
          (click)="onLastPageClick()"
        >
          <img
            src="assets/img/icons/last.svg"
            alt="last"
            class="mr-1"
            [class.clickable]="!pagination.is_last_page"
          />
        </button>
      </div>
    </div>
  </div>
</div>
