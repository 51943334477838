import { Action, ActionReducer, MetaReducer } from '@ngrx/store';
import { ActionTypes } from '@views/public/store/actions/auth.action';
import { localStorageSync } from 'ngrx-store-localstorage';
import { StoreState } from './store';

// FIXME: Do we need to persis in local?
// Should be same as one of the key in interface StoreState
const STORE_KEYS_TO_PERSIST = [
  'devices',
  'users',
  'deviceConfiguration',
  'trafficSteeringConfiguration',
  'trafficSteeringStatistics',
  'qosConfiguration',
  'hardwareInterfaceConfiguration',
  'wirelessInterfaceConfiguration',
  'usbInterfaceConfiguration',
  'tunnelInterfaceConfiguration',
  'vlanInterfaceConfiguration',
  'bridgeInterfaceConfiguration',
  'splitDnsConfiguration',
  'vpnRoutingConfiguration',
  'vpnRoutingServerConfiguration',
  'auth',
  'organizations',
  'softwares',
  'staticRoutingConfiguration',
  'staticRoutingDetailConfiguration',
  'dnatConfiguration',
  'remoteSSHConfiguration',
  'ipsecServer',
  'ipsecClient',
  'ipsecCertificate',
  'ipsecStatistics',
  'ipsecInterface',
  'prismaInterface',
];

export function localStorageSyncReducer(
  reducer: ActionReducer<StoreState>
): ActionReducer<StoreState> {
  return localStorageSync({ keys: STORE_KEYS_TO_PERSIST, rehydrate: true })(reducer);
}

export function logOut(reducer: ActionReducer<StoreState>): ActionReducer<StoreState> {
  return (state: StoreState, action: Action) => {
    if (action.type === ActionTypes.SIGN_OUT) {
      state = {} as StoreState;
    }
    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<StoreState, Action>> = [
  localStorageSyncReducer,
  logOut,
];
