import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ROLES } from '@shared/constants/constants';
import { AuthReducer } from '../models/auth.model';

export const getAuthFeatureState = createFeatureSelector<AuthReducer>('auth');

export const getUserDetail = createSelector(getAuthFeatureState, (state) => ({
  name: state.name,
  email: state.email,
  organizationId: state.organization_id,
}));

export const getAuthToken = createSelector(getAuthFeatureState, (state) => state.token);

export const getUserEmail = createSelector(getAuthFeatureState, (state) => state.email);

export const getOrganizationName = createSelector(
  getAuthFeatureState,
  (state) => state.organization_name
);

export const getUserRole = createSelector(getAuthFeatureState, (state): ROLES => state.role);

export const isSuperAdmin = createSelector(
  getUserRole,
  (role): boolean => role === ROLES.superAdmin
);
