import { Action } from '@ngrx/store';
import { Columns, ColumnWidth, SortColumns } from '@shared/models/shared.model';
import { Organization, OrganizationsResponse } from '../models/organizations.model';

export enum OrganizationActionTypes {
  ORG_FETCH_ORGANIZATIONS = 'ORG_FETCH_ORGANIZATIONS',
  ORG_SET_EDIT = 'ORG_SET_EDIT',
  ORG_DELETE_ORGANIZATIONS = 'ORG_DELETE_ORGANIZATIONS',
  ORG_GLOBAL_SEARCH = 'ORG_GLOBAL_SEARCH',
  ORG_UPDATE_CURRENT_PAGE = 'ORG_UPDATE_CURRENT_PAGE',
  ORG_UPDATE_SELECTED_ROWS = 'ORG_UPDATE_SELECTED_ROWS',
  ORG_CLEAR_SELECTED_ROWS = 'ORG_CLEAR_SELECTED_ROWS',
  ORG_COLUMN_SELECT = 'ORG_COLUMN_SELECT',
  ORG_COLUMNS_SELECT_ALL = 'ORG_COLUMNS_SELECT_ALL',
  ORG_COLUMN_DESELECT = 'ORG_COLUMN_DESELECT',
  ORG_COLUMNS_DESELECT_ALL = 'ORG_COLUMNS_DESELECT_ALL',
  ORG_SHUFFLED_COLUMNS = 'ORG_SHUFFLED_COLUMNS',
  ORG_UPDATE_FLOATING_FILTER = 'ORG_UPDATE_FLOATING_FILTER',
  ORG_COLUMN_SEARCH = 'ORG_COLUMN_SEARCH',
  ORG_CLEAR_COLUMNS_SEARCH = 'ORG_CLEAR_COLUMNS_SEARCH',
  ORG_UPDATE_COLUMNS_SORT = 'ORG_UPDATE_COLUMNS_SORT',
  ORG_UPDATE_COLUMN_WIDTH = 'ORG_UPDATE_COLUMN_WIDTH',
}

export class FetchOrganizations implements Action {
  readonly type = OrganizationActionTypes.ORG_FETCH_ORGANIZATIONS;
  constructor(public payload: OrganizationsResponse) {}
}

export class SetEditOrganization implements Action {
  readonly type = OrganizationActionTypes.ORG_SET_EDIT;
  constructor(public payload: Organization) {}
}

export class DeleteOrganizations implements Action {
  readonly type = OrganizationActionTypes.ORG_DELETE_ORGANIZATIONS;
  constructor(public payload: number[]) {}
}

export class GlobalSearch implements Action {
  readonly type = OrganizationActionTypes.ORG_GLOBAL_SEARCH;
  constructor(public payload: string) {}
}

export class UpdateCurrentPage implements Action {
  readonly type = OrganizationActionTypes.ORG_UPDATE_CURRENT_PAGE;
  constructor(public payload: number) {}
}

export class UpdateSelectedRows implements Action {
  readonly type = OrganizationActionTypes.ORG_UPDATE_SELECTED_ROWS;
  constructor(public payload: Organization[]) {}
}

export class ClearSelectedRows implements Action {
  readonly type = OrganizationActionTypes.ORG_CLEAR_SELECTED_ROWS;
}

export class ColumnSelect implements Action {
  readonly type = OrganizationActionTypes.ORG_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class ColumnsSelectAll implements Action {
  readonly type = OrganizationActionTypes.ORG_COLUMNS_SELECT_ALL;
}

export class ColumnDeSelect implements Action {
  readonly type = OrganizationActionTypes.ORG_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class ColumnsDeSelectAll implements Action {
  readonly type = OrganizationActionTypes.ORG_COLUMNS_DESELECT_ALL;
}

export class ShuffledColumns implements Action {
  readonly type = OrganizationActionTypes.ORG_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class UpdateFloatingFilter implements Action {
  readonly type = OrganizationActionTypes.ORG_UPDATE_FLOATING_FILTER;
  constructor(public payload: boolean) {}
}

export class ColumnSearch implements Action {
  readonly type = OrganizationActionTypes.ORG_COLUMN_SEARCH;
  constructor(public payload: any) {}
}

export class ClearColumnSearch implements Action {
  readonly type = OrganizationActionTypes.ORG_CLEAR_COLUMNS_SEARCH;
}

export class UpdateColumnsSort implements Action {
  readonly type = OrganizationActionTypes.ORG_UPDATE_COLUMNS_SORT;
  constructor(public payload: SortColumns[]) {}
}

export class UpdateColumnWidth implements Action {
  readonly type = OrganizationActionTypes.ORG_UPDATE_COLUMN_WIDTH;
  constructor(public payload: ColumnWidth[]) {}
}

export type OrganizationActions =
  | FetchOrganizations
  | SetEditOrganization
  | DeleteOrganizations
  | GlobalSearch
  | UpdateCurrentPage
  | UpdateSelectedRows
  | ClearSelectedRows
  | ColumnSelect
  | ColumnsSelectAll
  | ColumnDeSelect
  | ColumnsDeSelectAll
  | ShuffledColumns
  | UpdateFloatingFilter
  | ColumnSearch
  | ClearColumnSearch
  | UpdateColumnsSort
  | UpdateColumnWidth;
