import { Action } from '@ngrx/store';
import { Columns, ColumnWidth, SortColumns } from '@shared/models/shared.model';
import { GetSoftwareResponse } from '../models/software.model';

export enum SoftwareActionTypes {
  SOFTWARE_FETCH = 'SOFTWARE_FETCH',
  SOFTWARE_GLOBAL_SEARCH = 'SOFTWARE_GLOBAL_SEARCH',
  SOFTWARE_UPDATE_CURRENT_PAGE = 'SOFTWARE_UPDATE_CURRENT_PAGE',
  SOFTWARE_COLUMN_SELECT = 'SOFTWARE_COLUMN_SELECT',
  SOFTWARE_COLUMNS_SELECT_ALL = 'SOFTWARE_COLUMNS_SELECT_ALL',
  SOFTWARE_COLUMN_DESELECT = 'SOFTWARE_COLUMN_DESELECT',
  SOFTWARE_SHUFFLED_COLUMNS = 'SOFTWARE_SHUFFLED_COLUMNS',
  SOFTWARE_UPDATE_FLOATING_FILTER = 'SOFTWARE_UPDATE_FLOATING_FILTER',
  SOFTWARE_CLEAR_COLUMNS_SEARCH = 'SOFTWARE_CLEAR_COLUMNS_SEARCH',
  SOFTWARE_UPDATE_COLUMNS_SORT = 'SOFTWARE_UPDATE_COLUMNS_SORT',
  SOFTWARE_UPDATE_COLUMN_WIDTH = 'SOFTWARE_UPDATE_COLUMN_WIDTH',
}

export class FetchSoftwares implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_FETCH;
  constructor(public payload: GetSoftwareResponse[]) {}
}

export class GlobalSearch implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_GLOBAL_SEARCH;
  constructor(public payload: string) {}
}

export class UpdateCurrentPage implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_UPDATE_CURRENT_PAGE;
  constructor(public payload: number) {}
}

export class ColumnSelect implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class ColumnsSelectAll implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_COLUMNS_SELECT_ALL;
}

export class ColumnDeSelect implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class ShuffledColumns implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class UpdateFloatingFilter implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_UPDATE_FLOATING_FILTER;
  constructor(public payload: boolean) {}
}

export class ClearColumnSearch implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_CLEAR_COLUMNS_SEARCH;
}

export class UpdateColumnsSort implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_UPDATE_COLUMNS_SORT;
  constructor(public payload: SortColumns[]) {}
}

export class UpdateColumnWidth implements Action {
  readonly type = SoftwareActionTypes.SOFTWARE_UPDATE_COLUMN_WIDTH;
  constructor(public payload: ColumnWidth[]) {}
}

export type SoftwareActions =
  | FetchSoftwares
  | GlobalSearch
  | UpdateCurrentPage
  | ColumnSelect
  | ColumnsSelectAll
  | ColumnDeSelect
  | ShuffledColumns
  | UpdateFloatingFilter
  | ClearColumnSearch
  | UpdateColumnsSort
  | UpdateColumnWidth;
