import { Action } from '@ngrx/store';
import { Columns } from '@secure/devices/store/models/split-dns.model';
import * as type from '../models/split-dns.model';

export enum ActionTypes {
  ADD_SPLIT_DNS = 'ADD_SPLIT_DNS',
  EDIT_SPLIT_DNS = 'EDIT_SPLIT_DNS',
  SET_SPLIT_DNS_CONFIGURATION = 'SET_SPLIT_DNS_CONFIGURATION',
  DELETE_SPLIT_DNS = 'DELETE_SPLIT_DNS',
  CLEAR_SPLIT_DNS_CONFIGURATION = 'CLEAR_SPLIT_DNS_CONFIGURATION',
  UPDATE_SPLIT_DNS_COLUMN_WIDTH = 'UPDATE_SPLIT_DNS_COLUMN_WIDTH',
  SPLIT_DNS_COLUMN_SELECT = 'SPLIT_DNS_COLUMN_SELECT',
  SPLIT_DNS_COLUMNS_SELECT_ALL = 'SPLIT_DNS_COLUMNS_SELECT_ALL',
  SPLIT_DNS_COLUMN_DESELECT = 'SPLIT_DNS_COLUMN_DESELECT',
  SPLIT_DNS_DESELECT_ALL = 'SPLIT_DNS_DESELECT_ALL',
  SPLIT_DNS_SHUFFLED_COLUMNS = 'SPLIT_DNS_SHUFFLED_COLUMNS',
}

export class AddSplitDns implements Action {
  readonly type = ActionTypes.ADD_SPLIT_DNS;
  constructor(public payload: type.SplitDns) {}
}

export class EditSplitDns implements Action {
  readonly type = ActionTypes.EDIT_SPLIT_DNS;
  constructor(public payload: type.SplitDns) {}
}

export class DeleteSplitDns implements Action {
  readonly type = ActionTypes.DELETE_SPLIT_DNS;
  constructor(public payload: string[]) {}
}

export class ClearSplitDns implements Action {
  readonly type = ActionTypes.CLEAR_SPLIT_DNS_CONFIGURATION;
}

export class SetSplitDnsConfiguration implements Action {
  readonly type = ActionTypes.SET_SPLIT_DNS_CONFIGURATION;
  constructor(public payload: type.SplitDnsConfiguration) {}
}

export class UpdateSplitDnsColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_SPLIT_DNS_COLUMN_WIDTH;
  constructor(public payload) {}
}

export class SplitDnsColumnSelect implements Action {
  readonly type = ActionTypes.SPLIT_DNS_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class SplitDnsColumnsSelectAll implements Action {
  readonly type = ActionTypes.SPLIT_DNS_COLUMNS_SELECT_ALL;
}

export class SplitDnsColumnDeSelect implements Action {
  readonly type = ActionTypes.SPLIT_DNS_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class SplitDnsColumnsDeSelectAll implements Action {
  readonly type = ActionTypes.SPLIT_DNS_DESELECT_ALL;
}
export class SplitDnsShuffledColumns implements Action {
  readonly type = ActionTypes.SPLIT_DNS_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export type Actions =
  | AddSplitDns
  | EditSplitDns
  | SetSplitDnsConfiguration
  | DeleteSplitDns
  | ClearSplitDns
  | UpdateSplitDnsColumnWidth
  | SplitDnsColumnSelect
  | SplitDnsColumnsSelectAll
  | SplitDnsColumnDeSelect
  | SplitDnsColumnsDeSelectAll
  | SplitDnsShuffledColumns;
