import { PrismaServersActions, PrismaServersActionTypes } from './prisma-servers.action';
import { PrismaServersReducer } from './prisma-servers.model';

export const initialState: PrismaServersReducer = {
  prismaServer: [],
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  globalSearch: '',
  shuffledColumns: [
    { key: 'Name', field: 'name', hide: false, sort: '', minWidth: 100, width: 127 },
    {
      key: 'Pre-shared key',
      field: 'pre_shared_key',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 200,
    },
    {
      key: 'Organization Name',
      field: 'organization_name',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 170,
    },
    {
      key: 'Remote Address',
      field: 'remote_address',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 126,
    },
    {
      key: 'IKE Identification',
      field: 'id',
      hide: false,
      sort: '',
      minWidth: 75,
      width: 126,
    },
  ],
  hasFloatingFilter: false,
  columns: {
    name: '',
    description: '',
    organization_id: 0,
    remote_address: '',
  },
  selectedRows: [],
};

export function prismaServersReducer(state = initialState, action: PrismaServersActions) {
  switch (action.type) {
    case PrismaServersActionTypes.FETCH_PRISMA_SERVERS:
      return {
        ...state,
        prismaServer: action.payload.prisma_servers,
        pagination: action.payload.pagination,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_GLOBAL_SEARCH:
      return {
        ...state,
        globalSearch: action.payload,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_CURRENT_PAGE:
      state.pagination.current_page = action.payload;
      return {
        ...state,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [...action.payload],
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_CLEAR_SELECTED_ROWS:
      return {
        ...state,
        selectedRows: [],
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_FLOATING_FILTER:
      return {
        ...state,
        hasFloatingFilter: action.payload,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_COLUMN_SEARCH:
      const key = Object.keys(action.payload)[0];
      state.columns[key.toString()] = Object.values(action.payload)[0];
      return {
        ...state,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_COLUMNS_SORT:
      state.shuffledColumns.forEach((column) => {
        let fieldMatch = false;

        action.payload.forEach((data) => {
          if (column.field === data.colId) {
            fieldMatch = true;
            column.sort = data.sort;
          }
        });

        if (!fieldMatch) {
          column.sort = '';
        }
      });
      return {
        ...state,
      };
    case PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    case PrismaServersActionTypes.DELETE_PRISMA_SERVERS:
      const filteredData = state.prismaServer.filter((data) => !action.payload.includes(data.id));
      return {
        ...state,
        prismaServer: [...filteredData],
      };
    default:
      return state;
  }
}
