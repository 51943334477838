import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageHelpResponse } from '@shared/models/shared.model';
import { HelperService } from '@shared/services/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-helper-page',
  templateUrl: './helper-page.component.html',
  styleUrls: ['./helper-page.component.scss'],
})
export class HelperPageComponent implements OnInit, OnDestroy {
  hasView: boolean;
  pageMarkdown: string;
  subscriptions = new Subscription();
  @Input() gitPath: string;

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    const viewObserver = this.helperService.onPageHelpChange.subscribe(
      (view) => (this.hasView = view)
    );
    this.subscriptions.add(viewObserver);

    const githubObserver = this.helperService
      .getHelpContent(this.gitPath)
      .subscribe((data: PageHelpResponse) => {
        this.pageMarkdown = atob(data.data);
      });
    this.subscriptions.add(githubObserver);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onClose() {
    this.helperService.hidePageHelp();
  }
}
