import { createFeatureSelector, createSelector } from '@ngrx/store';
import { INTERFACE_STATUS } from '@shared/constants/constants';
import { HardwareInterfaceConfigurationReducer } from '../models/hardware-interface-configuration.model';

export const getHardwareInterfaceFeatureState =
  createFeatureSelector<HardwareInterfaceConfigurationReducer>('hardwareInterfaceConfiguration');

export const getCurrentDeviceHardwareInterfaces = createSelector(
  getHardwareInterfaceFeatureState,
  (state) => state.interfaces
);

export const getCurrentDeviceHardwareInterfaceById = createSelector(
  getCurrentDeviceHardwareInterfaces,
  (hardwareInterfaces, props) => {
    const currentInterface = hardwareInterfaces.find(
      (hardwareInterface) => hardwareInterface.id === props.interfaceId
    );
    return currentInterface;
  }
);

export const getCurrentHardwareInterfaceColumns = createSelector(
  getHardwareInterfaceFeatureState,
  (state) => state.shuffledColumns
);

export const GetHardwareInterfaceWithUpSate = createSelector(
  getCurrentDeviceHardwareInterfaces,
  (interfaces) =>
    interfaces.filter((interface_) => interface_.runtime_status === INTERFACE_STATUS.UP)
);
