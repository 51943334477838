import { filter, includes, map } from 'lodash';
import { Actions, ActionTypes } from '../actions/dnat-configuration.action';
import { DNAT, DNATConfigurationReducer } from '../models/dnat.model';

const INITIAL_STATE: DNATConfigurationReducer = {
  id: 0,
  deviceId: 0,
  dnats: [],
  enable_dnat_pinning: false,
  shuffledColumns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 175, hide: false, sort: '' },
    {
      key: 'Input WAN Interface',
      field: 'input_wan_interface',
      minWidth: 75,
      width: 177,
      hide: false,
      sort: '',
    },
    { key: 'Source IP', field: 'source_ip', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'Protocol', field: 'protocol', minWidth: 75, width: 177, hide: false, sort: '' },
    {
      key: 'Destination Port',
      field: 'destination_port',
      minWidth: 75,
      width: 75,
      hide: false,
      sort: '',
    },
    { key: 'Server IP', field: 'server_ip', minWidth: 75, width: 177, hide: false, sort: '' },
    { key: 'Source Port', field: 'source_port', minWidth: 75, width: 177, hide: false, sort: '' },
  ],
};

export function dnatConfigurationReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_DNAT_CONFIGURATION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        dnats: action.payload.dnat,
        enable_dnat_pinning: action.payload.enable_dnat_pinning,
      };
    case ActionTypes.DNAT_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.DNAT_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.DNAT_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.DNAT_DESELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = true));
      return {
        ...state,
      };
    case ActionTypes.DNAT_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.ADD_DNAT:
      return {
        ...state,
        dnats: [...state.dnats, action.payload],
      };
    case ActionTypes.EDIT_DNAT:
      const updatedDnatList = map(state.dnats, (dnat: DNAT) =>
        dnat.id === action.payload.id ? { ...dnat, ...action.payload } : dnat
      );
      return {
        ...state,
        dnats: updatedDnatList,
      };
    case ActionTypes.DELETE_DNAT:
      const ids = action.payload;
      const modifiedClassList = filter(state.dnats, (item: DNAT) => !includes(ids, item.id));
      return {
        ...state,
        dnats: modifiedClassList,
      };
    case ActionTypes.CLEAR_DNAT_CONFIGURATION:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_DNAT_COLUMN_WIDTH:
      state.shuffledColumns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    case ActionTypes.SET_DNAT_PINNING:
      return {
        ...state,
        enable_dnat_pinning: action.payload,
      };
    default:
      return state;
  }
}
