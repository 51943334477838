import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  backButtonClicked = false;
  constructor(public location: LocationStrategy) {
    location.onPopState(() => {
      this.backButtonClicked = true;
    });
  }

  canDeactivate(component: CanComponentDeactivate) {
    if (this.backButtonClicked) {
      this.backButtonClicked = false;
      history.pushState(null, '', location.href);
    }
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
