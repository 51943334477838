import { Injectable, NgZone } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@public/auth/services/auth.service';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private authService: AuthService
  ) {}

  canActivate() {
    const authToken = this.authService.authToken();
    if (isEmpty(authToken)) {
      this.ngZone.run(() => this.router.navigate(['/signin']));
      return false;
    }
    return true;
  }
}
