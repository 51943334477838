import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IpsecInterfaceReducer } from '../models/ipsec-interface.model';

export const getIpsecInterfaceFeatureState =
  createFeatureSelector<IpsecInterfaceReducer>('ipsecInterface');

export const getCurrentDeviceIpsecInterfaces = createSelector(
  getIpsecInterfaceFeatureState,
  (state) => {
    const ipsecInterfaces = state.interfaces?.filter(
      (currentIpsecInterface) => currentIpsecInterface.is_configured
    );
    return ipsecInterfaces;
  }
);

export const getCurrentDeviceIpsecInterfaceById = createSelector(
  getCurrentDeviceIpsecInterfaces,
  (ipsecInterfaces, props) => {
    const currentInterface = ipsecInterfaces.find(
      (ipsecInterfaces) => ipsecInterfaces.id === props.interfaceId
    );
    return currentInterface;
  }
);
