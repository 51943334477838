import { Action } from '@ngrx/store';
import { Columns, ColumnWidth, SortColumns } from '@shared/models/shared.model';
import { PrismaServers, PrismaServersResponse } from './prisma-servers.model';

export enum PrismaServersActionTypes {
  FETCH_PRISMA_SERVERS = 'FETCH_PRISMA_SERVERS',
  PRISMA_SERVERS_GLOBAL_SEARCH = 'PRISMA_SERVERS_GLOBAL_SEARCH',
  PRISMA_SERVERS_UPDATE_CURRENT_PAGE = 'PRISMA_SERVERS_UPDATE_CURRENT_PAGE',
  PRISMA_SERVERS_UPDATE_SELECTED_ROWS = 'PRISMA_SERVERS_UPDATE_SELECTED_ROWS',
  PRISMA_SERVERS_CLEAR_SELECTED_ROWS = 'PRISMA_SERVERS_CLEAR_SELECTED_ROWS',
  PRISMA_SERVERS_COLUMN_SELECT = 'PRISMA_SERVERS_COLUMN_SELECT',
  PRISMA_SERVERS_COLUMNS_SELECT_ALL = 'PRISMA_SERVERS_COLUMNS_SELECT_ALL',
  PRISMA_SERVERS_COLUMN_DESELECT = 'PRISMA_SERVERS_COLUMN_DESELECT',
  PRISMA_SERVERS_SHUFFLED_COLUMNS = 'PRISMA_SERVERS_SHUFFLED_COLUMNS',
  PRISMA_SERVERS_UPDATE_FLOATING_FILTER = 'PRISMA_SERVERS_UPDATE_FLOATING_FILTER',
  PRISMA_SERVERS_COLUMN_SEARCH = 'PRISMA_SERVERS_COLUMN_SEARCH',
  PRISMA_SERVERS_CLEAR_COLUMNS_SEARCH = 'PRISMA_SERVERS_CLEAR_COLUMNS_SEARCH',
  PRISMA_SERVERS_UPDATE_COLUMNS_SORT = 'PRISMA_SERVERS_UPDATE_COLUMNS_SORT',
  PRISMA_SERVERS_UPDATE_COLUMN_WIDTH = 'PRISMA_SERVERS_UPDATE_COLUMN_WIDTH',
  DELETE_PRISMA_SERVERS = 'DELETE_PRISMA_SERVERS',
}

export class FetchPrismaServers implements Action {
  readonly type = PrismaServersActionTypes.FETCH_PRISMA_SERVERS;
  constructor(public payload: PrismaServersResponse) {}
}

export class PrismaServersGlobalSearch implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_GLOBAL_SEARCH;
  constructor(public payload: string) {}
}

export class PrismaServersUpdateCurrentPage implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_CURRENT_PAGE;
  constructor(public payload: number) {}
}

export class PrismaServersUpdateSelectedRows implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_SELECTED_ROWS;
  constructor(public payload: PrismaServers[]) {}
}

export class PrismaServersClearSelectedRows implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_CLEAR_SELECTED_ROWS;
}

export class PrismaServersColumnSelect implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class PrismaServersColumnsSelectAll implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_COLUMNS_SELECT_ALL;
}

export class PrismaServersColumnDeSelect implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class PrismaServersShuffledColumns implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class PrismaServersUpdateFloatingFilter implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_FLOATING_FILTER;
  constructor(public payload: boolean) {}
}

export class PrismaServersColumnSearch implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_COLUMN_SEARCH;
  constructor(public payload: any) {}
}

export class PrismaServersUpdateColumnsSort implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_COLUMNS_SORT;
  constructor(public payload: SortColumns[]) {}
}

export class PrismaServersUpdateColumnWidth implements Action {
  readonly type = PrismaServersActionTypes.PRISMA_SERVERS_UPDATE_COLUMN_WIDTH;
  constructor(public payload: ColumnWidth[]) {}
}

export class DeletePrismaServers implements Action {
  readonly type = PrismaServersActionTypes.DELETE_PRISMA_SERVERS;
  constructor(public payload: number[]) {}
}

export type PrismaServersActions =
  | FetchPrismaServers
  | PrismaServersGlobalSearch
  | PrismaServersUpdateCurrentPage
  | PrismaServersUpdateSelectedRows
  | PrismaServersClearSelectedRows
  | PrismaServersColumnSelect
  | PrismaServersColumnsSelectAll
  | PrismaServersColumnDeSelect
  | PrismaServersShuffledColumns
  | PrismaServersUpdateFloatingFilter
  | PrismaServersColumnSearch
  | PrismaServersUpdateColumnsSort
  | PrismaServersUpdateColumnWidth
  | DeletePrismaServers;
