import { Action } from '@ngrx/store';
import {
  HardwareInterface,
  HardwareInterfaceConfiguration,
} from '@secure/devices/store/models/hardware-interface-configuration.model';
import { Columns } from '../models/shared.model';
import { InterfaceAndId } from '../models/mac-ip-binding.model';

export enum ActionTypes {
  SET_HARDWARE_INTERFACE_CONFIGURAION = 'SET_HARDWARE_INTERFACE_CONFIGURAION',
  CLEAR_CURRENT_HARDWARE_INTERFACE_CONFIGURATION = 'CLEAR_CURRENT_HARDWARE_INTERFACE_CONFIGURATION',
  HARDWARE_INTERFACE_COLUMN_SELECT = 'HARDWARE_INTERFACE_COLUMN_SELECT',
  HARDWARE_INTERFACE_COLUMNS_SELECT_ALL = 'HARDWARE_INTERFACE_COLUMNS_SELECT_ALL',
  HARDWARE_INTERFACE_COLUMN_DESELECT = 'HARDWARE_INTERFACE_COLUMN_DESELECT',
  HARDWARE_INTERFACE_SHUFFLED_COLUMNS = 'HARDWARE_INTERFACE_SHUFFLED_COLUMNS',
  EDIT_HARDWARE_INTERFACE = 'EDIT_HARDWARE_INTERFACE',
  DELETE_HARDWARE_MAC_IP_BINDING_LIST = 'DELETE_HARDWARE_MAC_IP_BINDING_LIST',
}

export class SetHardwareInterfaceConfiguration implements Action {
  readonly type = ActionTypes.SET_HARDWARE_INTERFACE_CONFIGURAION;
  constructor(public payload: HardwareInterfaceConfiguration) {}
}

export class ClearCurrentHardwareInterfaceConfiguration implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_HARDWARE_INTERFACE_CONFIGURATION;
}

export class HardwareInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class HardwareInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_COLUMNS_SELECT_ALL;
}

export class HardwareInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class HardwareInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.HARDWARE_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export class EditHardwareInterface implements Action {
  readonly type = ActionTypes.EDIT_HARDWARE_INTERFACE;
  constructor(public payload: HardwareInterface) {}
}

export class DeleteHardwareMacIpBindingList implements Action {
  readonly type = ActionTypes.DELETE_HARDWARE_MAC_IP_BINDING_LIST;
  constructor(public payload: InterfaceAndId) {}
}

export type Actions =
  | SetHardwareInterfaceConfiguration
  | ClearCurrentHardwareInterfaceConfiguration
  | HardwareInterfaceColumnSelect
  | HardwareInterfaceColumnsSelectAll
  | HardwareInterfaceColumnDeSelect
  | HardwareInterfaceShuffledColumns
  | EditHardwareInterface
  | DeleteHardwareMacIpBindingList;
