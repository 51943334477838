import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { StoreState } from '@store/store';
import { SignOut } from '@views/public/store/actions/auth.action';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface Error {
  hasValidationError: boolean;
  message: string;
  errorList?: string[];
  status?: number;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<StoreState['auth']>,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorObj = typeof error.error == 'string' ? JSON.parse(error.error) : error.error;
        const errorResponse: Error = {
          hasValidationError: false,
          message: 'Something went wrong. Please contact admin',
          status: error.status,
        };
        if (error.status <= 0) {
          errorResponse.message = 'Sorry, no Internet connectivity, Please reconnect and try again';
        } else if (error.status === 422) {
          errorResponse.hasValidationError = true;
          errorResponse.errorList = errorObj.errors;
          errorResponse.message = 'Validation errors';
        } else if (error.status === 400) {
          errorResponse.errorList = errorObj.errors;
          errorResponse.message = 'Bad request';
        } else if (error.status === 401) {
          this.store.dispatch(new SignOut());
          this.router.navigate(['/signin']);
          errorResponse.message =
            'Sorry for the inconvenience, but you are not authorized to look into this page';
          errorResponse.errorList = errorObj.errors;
        } else if (error.status === 403) {
          errorResponse.message = 'You are not permitted to perform this action';
        } else if (error.status === 404) {
          errorResponse.message =
            errorObj.errors && errorObj.errors[0]
              ? errorObj.errors[0]
              : 'Something went wrong. Please contact admin';
        }
        return throwError(errorResponse);
      })
    );
  }
}
