import { NotificationsReducer } from './notifications.model';
import { NotificationsActions, NotificationsActionTypes } from './notifications.actions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const initialState: NotificationsReducer = {
  notifications: [],
  pagination: {
    current_page: 1,
    end_at: 0,
    is_first_page: false,
    is_last_page: false,
    next_page: 0,
    prev_page: 0,
    per_page: 10,
    start_at: 0,
    total_count: 0,
    total_pages: 0,
  },
  unreadCounts: {
    alert: 0,
    device_configuration: 0,
    device_provision: 0,
    total: 0,
    user_activity: 0,
  },
  globalSearch: '',
  activeTab: undefined,
  shuffledColumns: [
    { key: 'Date & Time', field: 'created_at', hide: false, sort: '', minWidth: 75, width: 137 },
    { key: 'Type', field: 'name', hide: false, sort: '', minWidth: 75, width: 155 },
    { key: 'Device', field: 'event_type_name', hide: false, sort: '', minWidth: 75, width: 137 },
    { key: 'Message', field: 'message', hide: false, sort: '', minWidth: 75, width: 488 },
    { key: 'Actions', field: 'actions', hide: false, sort: '', minWidth: 75, width: 85 },
  ],
  hasFloatingFilter: false,
  columns: {
    message: '',
  },
  selectedDateRange: {
    startDate: dayjs().startOf('day').utc().format(),
    endDate: dayjs().endOf('day').utc().format(),
  },
};

export function notificationsReducer(
  state = initialState,
  action: NotificationsActions
): NotificationsReducer {
  switch (action.type) {
    case NotificationsActionTypes.FETCH_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.events,
        pagination: action.payload.pagination,
        unreadCounts: action.payload.unread_counts,
      };

    case NotificationsActionTypes.NOTIFICATIONS_GLOBAL_SEARCH:
      state.pagination.current_page = 1;
      return {
        ...state,
        globalSearch: action.payload,
      };
    case NotificationsActionTypes.NOTIFICATIONS_UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    case NotificationsActionTypes.NOTIFICATIONS_UPDATE_CURRENT_PAGE:
      state.pagination.current_page = action.payload;
      return {
        ...state,
      };

    case NotificationsActionTypes.NOTIFICATIONS_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case NotificationsActionTypes.NOTIFICATIONS_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case NotificationsActionTypes.NOTIFICATIONS_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case NotificationsActionTypes.NOTIFICATIONS_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case NotificationsActionTypes.NOTIFICATIONS_UPDATE_COLUMN_WIDTH:
      action.payload.forEach((data) => {
        state.shuffledColumns.forEach((column) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });

      return {
        ...state,
      };
    case NotificationsActionTypes.UPDATE_COLUMNS_SORT:
      state.shuffledColumns.forEach((column) => {
        const found = action.payload.find((data) => column.field === data.colId);
        column.sort = found ? found.sort : '';
      });
      return {
        ...state,
      };
    case NotificationsActionTypes.UPDATE_FLOATING_FILTER:
      return {
        ...state,
        hasFloatingFilter: action.payload,
      };
    case NotificationsActionTypes.COLUMN_SEARCH:
      const key = Object.keys(action.payload)[0];
      state.columns[key.toString()] = Object.values(action.payload)[0];
      return {
        ...state,
      };
    case NotificationsActionTypes.NOTIFICATIONS_DATE_FILTER:
      return {
        ...state,
        selectedDateRange: {
          ...action.payload,
        },
      };
    case NotificationsActionTypes.DELETE_NOTIFICATIONS:
      const filteredData = state.notifications.filter((data) => !action.payload.includes(data.id));
      return {
        ...state,
        notifications: [...filteredData],
      };
    default:
      return state;
  }
}
