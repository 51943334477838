import { Action } from '@ngrx/store';
import { IpsecInterface } from '../models/ipsec-interface.model';
import { Columns } from '../models/shared.model';

export enum ActionTypes {
  SET_IPSEC_INTERFACE = 'SET_IPSEC_INTERFACE',
  CLEAR_CURRENT_IPSEC_INTERFACE = 'CLEAR_CURRENT_IPSEC_INTERFACE',
  IPSEC_INTERFACE_COLUMN_SELECT = 'IPSEC_INTERFACE_COLUMN_SELECT',
  IPSEC_INTERFACE_COLUMNS_SELECT_ALL = 'IPSEC_INTERFACE_COLUMNS_SELECT_ALL',
  IPSEC_INTERFACE_COLUMN_DESELECT = '  IPSEC_INTERFACE_COLUMN_DESELECT',
  IPSEC_INTERFACE_SHUFFLED_COLUMNS = 'IPSEC_INTERFACE_SHUFFLED_COLUMNS',
}

export class SetIpsecInterface implements Action {
  readonly type = ActionTypes.SET_IPSEC_INTERFACE;
  constructor(public payload: IpsecInterface[]) {}
}

export class ClearCurrentIpsecInterface implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_IPSEC_INTERFACE;
}

export class IpsecInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.IPSEC_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class IpsecInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.IPSEC_INTERFACE_COLUMNS_SELECT_ALL;
}

export class IpsecInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.IPSEC_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class IpsecInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.IPSEC_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export type Actions =
  | SetIpsecInterface
  | ClearCurrentIpsecInterface
  | IpsecInterfaceColumnSelect
  | IpsecInterfaceColumnsSelectAll
  | IpsecInterfaceColumnDeSelect
  | IpsecInterfaceShuffledColumns;
