import { Action } from '@ngrx/store';
import { Columns } from '@secure/devices/store/models/remote-ssh.model';
import * as type from '../models/remote-ssh.model';

export enum ActionTypes {
  ADD_REMOTE_SSH = 'ADD_REMOTE_SSH',
  EDIT_REMOTE_SSH = 'EDIT_REMOTE_SSH',
  SET_REMOTE_SSH_CONFIGURATION = 'SET_REMOTE_SSH_CONFIGURATION',
  DELETE_REMOTE_SSH = 'DELETE_REMOTE_SSH',
  CLEAR_REMOTE_SSH_CONFIGURATION = 'CLEAR_REMOTE_SSH_CONFIGURATION',
  UPDATE_REMOTE_SSH_COLUMN_WIDTH = 'UPDATE_REMOTE_SSH_COLUMN_WIDTH',
  REMOTE_SSH_COLUMN_SELECT = 'REMOTE_SSH_COLUMN_SELECT',
  REMOTE_SSH_COLUMNS_SELECT_ALL = 'REMOTE_SSH_COLUMNS_SELECT_ALL',
  REMOTE_SSH_COLUMN_DESELECT = 'REMOTE_SSH_COLUMN_DESELECT',
  REMOTE_SSH_DESELECT_ALL = 'REMOTE_SSH_DESELECT_ALL',
  REMOTE_SSH_SHUFFLED_COLUMNS = 'REMOTE_SSH_SHUFFLED_COLUMNS',
}

export class AddRemoteSSH implements Action {
  readonly type = ActionTypes.ADD_REMOTE_SSH;
  constructor(public payload: type.RemoteSSH) {}
}

export class EditRemoteSSH implements Action {
  readonly type = ActionTypes.EDIT_REMOTE_SSH;
  constructor(public payload: type.RemoteSSH) {}
}

export class DeleteRemoteSSH implements Action {
  readonly type = ActionTypes.DELETE_REMOTE_SSH;
  constructor(public payload: string[]) {}
}

export class ClearRemoteSSH implements Action {
  readonly type = ActionTypes.CLEAR_REMOTE_SSH_CONFIGURATION;
}

export class SetRemoteSSHConfiguration implements Action {
  readonly type = ActionTypes.SET_REMOTE_SSH_CONFIGURATION;
  constructor(public payload: any) {}
}

export class UpdateRemoteSSHColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_REMOTE_SSH_COLUMN_WIDTH;
  constructor(public payload) {}
}

export class RemoteSSHColumnSelect implements Action {
  readonly type = ActionTypes.REMOTE_SSH_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class RemoteSSHColumnsSelectAll implements Action {
  readonly type = ActionTypes.REMOTE_SSH_COLUMNS_SELECT_ALL;
}

export class RemoteSSHColumnDeSelect implements Action {
  readonly type = ActionTypes.REMOTE_SSH_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class RemoteSSHColumnsDeSelectAll implements Action {
  readonly type = ActionTypes.REMOTE_SSH_DESELECT_ALL;
}
export class RemoteSSHShuffledColumns implements Action {
  readonly type = ActionTypes.REMOTE_SSH_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export type Actions =
  | AddRemoteSSH
  | EditRemoteSSH
  | SetRemoteSSHConfiguration
  | DeleteRemoteSSH
  | ClearRemoteSSH
  | UpdateRemoteSSHColumnWidth
  | RemoteSSHColumnSelect
  | RemoteSSHColumnsSelectAll
  | RemoteSSHColumnDeSelect
  | RemoteSSHColumnsDeSelectAll
  | RemoteSSHShuffledColumns;
