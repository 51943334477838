import { Action, createReducer, on } from '@ngrx/store';
import {
  addIpsecClientAction,
  clearIpsecClientAction,
  deleteIpsecClientsAction,
  setIpsecClientAction,
  updateIpsecClientAction,
  updateIPsecClientServerConfigured,
} from './ipsec-client.action';
import { IpsecClientState } from './ipsec-client.interface';

const initialState: IpsecClientState = {
  clients: [],
};

const reducer = createReducer(
  initialState,
  on(addIpsecClientAction, (state, props) => ({
    ...state,
    clients: [...state.clients, props.client],
  })),
  on(updateIpsecClientAction, (state, props) => {
    const updatedClient = props.client;
    const currentId = props.client.id;
    const updatedClientList = state.clients.map((client) =>
      client.id === currentId ? { ...client, ...updatedClient } : client
    );
    return {
      ...state,
      clients: updatedClientList,
    };
  }),
  on(deleteIpsecClientsAction, (state, props) => ({
    ...state,
    clients: state.clients.filter((client) => !props.clientIds.includes(client.id)),
  })),
  on(setIpsecClientAction, (state, props) => ({ ...state, clients: props.clients })),
  on(clearIpsecClientAction, () => initialState),
  on(updateIPsecClientServerConfigured, (state, props) => {
    state.clients.forEach((client) => (client.is_server_changed = props.status));
    return state;
  })
);

export function ipsecClientReducer(state: IpsecClientState | undefined, action: Action) {
  return reducer(state, action);
}
