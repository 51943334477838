import { Action, createReducer, on } from '@ngrx/store';
import {
  addIpsecServerAction,
  clearIpsecServerAction,
  deleteIpsecServersAction,
  setIpsecServerAction,
  updateIpsecServerAction,
} from './ipsec-server.action';
import { IpsecServerState } from './ipsec-server.interface';

const initialState: IpsecServerState = {
  servers: [],
};

const reducer = createReducer(
  initialState,
  on(addIpsecServerAction, (state, props) => ({
    ...state,
    servers: [...state.servers, props.server],
  })),
  on(updateIpsecServerAction, (state, props) => {
    const updatedServer = props.server;
    const currentId = props.server.id;
    const serverToUpdate = state.servers.find((server) => server.id === currentId);
    Object.keys(updatedServer).forEach((key) => (serverToUpdate[key] = updatedServer[key]));
    return state;
  }),
  on(deleteIpsecServersAction, (state, props) => ({
    ...state,
    servers: state.servers.filter((server) => !props.serverIds.includes(server.id)),
  })),
  on(setIpsecServerAction, (state, props) => ({ ...state, servers: props.servers })),
  on(clearIpsecServerAction, () => initialState)
);

export function ipsecServerReducer(state: IpsecServerState | undefined, action: Action) {
  return reducer(state, action);
}
