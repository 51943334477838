import { Action } from '@ngrx/store';
import { Column } from '@secure/devices/store/models/shared.model';
import * as type from '../models/vpn-routing.model';

export enum ActionTypes {
  ADD_VPN_ROUTING_SERVER = 'ADD_VPN_ROUTING_SERVER',
  EDIT_VPN_ROUTING_SERVER = 'EDIT_VPN_ROUTING_SERVER',
  CLEAR_VPN_ROUTING_SERVER_CONFIGURATION = 'CLEAR_VPN_ROUTING_SERVER_CONFIGURATION',
  SET_VPN_ROUTING_SERVER_CONFIGURATION = 'SET_VPN_ROUTING_SERVER_CONFIGURATION',
  UPDATE_VPN_ROUTING_SERVER_COLUMN_WIDTH = 'UPDATE_VPN_ROUTING_SERVER_COLUMN_WIDTH',
  VPN_ROUTING_SERVER_COLUMN_SELECT = 'VPN_ROUTING_SERVER_COLUMN_SELECT',
  VPN_ROUTING_SERVER_COLUMNS_SELECT_ALL = 'VPN_ROUTING_SERVER_COLUMNS_SELECT_ALL',
  VPN_ROUTING_SERVER_COLUMN_DESELECT = 'VPN_ROUTING_SERVER_COLUMN_DESELECT',
  VPN_ROUTING_SERVER_DESELECT_ALL = 'VPN_ROUTING_SERVER_DESELECT_ALL',
  VPN_ROUTING_SERVER_SHUFFLED_COLUMNS = 'VPN_ROUTING_SERVER_SHUFFLED_COLUMNS',
  DELETE_VPN_ROUTING_SERVER = 'DELETE_VPN_ROUTING_SERVER',
}

export class AddVpnRoutingServer implements Action {
  readonly type = ActionTypes.ADD_VPN_ROUTING_SERVER;
  constructor(public payload: type.VpnRoutingServer) {}
}

export class EditVpnRoutingServer implements Action {
  readonly type = ActionTypes.EDIT_VPN_ROUTING_SERVER;
  constructor(public payload: type.VpnRoutingServer) {}
}

export class DeleteVpnAndRoutingServer implements Action {
  readonly type = ActionTypes.DELETE_VPN_ROUTING_SERVER;
  constructor(public payload: string[]) {}
}

export class ClearVpnRoutingServer implements Action {
  readonly type = ActionTypes.CLEAR_VPN_ROUTING_SERVER_CONFIGURATION;
}

export class SetVpnRoutingServerConfiguration implements Action {
  readonly type = ActionTypes.SET_VPN_ROUTING_SERVER_CONFIGURATION;
  constructor(public payload: type.VpnRoutingCombinedConfiguration) {}
}

export class UpdateVpnRoutingServerColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_VPN_ROUTING_SERVER_COLUMN_WIDTH;
  constructor(public payload) {}
}

export class VpnRoutingServerColumnSelect implements Action {
  readonly type = ActionTypes.VPN_ROUTING_SERVER_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class VpnRoutingServerColumnsSelectAll implements Action {
  readonly type = ActionTypes.VPN_ROUTING_SERVER_COLUMNS_SELECT_ALL;
}

export class VpnRoutingServerColumnDeSelect implements Action {
  readonly type = ActionTypes.VPN_ROUTING_SERVER_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class VpnRoutingServerColumnsDeSelectAll implements Action {
  readonly type = ActionTypes.VPN_ROUTING_SERVER_DESELECT_ALL;
}

export class VpnRoutingServerShuffledColumns implements Action {
  readonly type = ActionTypes.VPN_ROUTING_SERVER_SHUFFLED_COLUMNS;
  constructor(public payload: Column[]) {}
}

export type Actions =
  | AddVpnRoutingServer
  | EditVpnRoutingServer
  | ClearVpnRoutingServer
  | SetVpnRoutingServerConfiguration
  | UpdateVpnRoutingServerColumnWidth
  | VpnRoutingServerColumnSelect
  | VpnRoutingServerColumnsSelectAll
  | VpnRoutingServerColumnDeSelect
  | VpnRoutingServerColumnsDeSelectAll
  | VpnRoutingServerShuffledColumns
  | DeleteVpnAndRoutingServer;
