<button
  [disabled]="isLoading || isFormInvalid"
  [type]="type"
  [class]="buttonClass || buttonPrimary"
  size="buttonSize"
  (click)="onFormSubmit()"
>
  <span *ngIf="isLoading">
    <span class="btn-text">{{ loadingText | translate }}</span> &nbsp;
    <i class="fa fa-spinner fa-spin"></i>
  </span>
  <span *ngIf="!isLoading"
    ><i *ngIf="buttonIcon" [class]="buttonIcon" class="mr-2"></i>
    <span class="btn-text">{{ text | translate }}</span></span
  >
</button>
