import { sortBy } from 'lodash';
import { Actions, ActionTypes } from '../actions/traffic-steering-configuration.action';
import { TrafficSteeringConfigurationReducer } from '../models/traffic-steering-configuration.model';

export const initialState: TrafficSteeringConfigurationReducer = {
  id: 0,
  deviceId: 0,
  traffic_steering: [],
};

export function trafficSteeringReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_TRAFFIC_STEERING_CONFIGURATION_BY_ID:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        traffic_steering: action.payload.traffic_steering,
      };
    case ActionTypes.ADD_TRAFFIC_STEERING_CONFIGURATION:
      const newStateAdd = { ...state };
      const newConfiguration = action.payload.configuration;
      newConfiguration.order = newStateAdd.traffic_steering.length + 1;
      newStateAdd.traffic_steering.push(newConfiguration);

      return {
        ...newStateAdd,
      };
    case ActionTypes.EDIT_TRAFFIC_STEERING_CONFIGURATION:
      const newStateEdit = { ...state };
      const deviceConfigurations = state.traffic_steering;
      const configurationIndex = deviceConfigurations.findIndex(
        (c) => c.id === action.payload.configuration.id
      );
      newStateEdit.traffic_steering[configurationIndex] = {
        order: newStateEdit.traffic_steering[configurationIndex]['order'],
        ...action.payload.configuration,
      };

      return {
        ...newStateEdit,
      };
    case ActionTypes.DELETE_TRAFFIC_STEERING_CONFIGURATION:
      const newStateDelete = { ...state };
      const filteredDeviceConfiguration = newStateDelete.traffic_steering.filter(
        (c) => action.payload.configuration_ids.indexOf(c.id) === -1
      );
      filteredDeviceConfiguration.forEach((a, i) => (a.order = i + 1));

      return {
        ...state,
        traffic_steering: filteredDeviceConfiguration,
      };

    case ActionTypes.CLEAR_CURRENT_TRAFFIC_STEERING_CONFIGURATION:
      return {
        ...state,
        id: 0,
        deviceId: 0,
        traffic_steering: [],
      };
    case ActionTypes.REORDER_TRAFFIC_STEERING_CONFIGURATION:
      const newStateReorder = { ...state };
      const currentTrafficSteering = newStateReorder.traffic_steering.filter(
        (a) => a.id === action.payload.trafficSteeringId
      );
      const filteredTrafficSteering = newStateReorder.traffic_steering.filter(
        (a) => a.id !== action.payload.trafficSteeringId
      );

      filteredTrafficSteering.forEach((a, i) => (a.order = i + 1));
      filteredTrafficSteering.splice(action.payload.newOrder - 1, 0, currentTrafficSteering[0]);
      filteredTrafficSteering.forEach((a, i) => (a.order = i + 1));
      const sortedTrafficSteering = sortBy(filteredTrafficSteering, 'order');

      return {
        ...state,
        traffic_steering: sortedTrafficSteering,
      };
    default:
      return state;
  }
}
