import { Component } from '@angular/core';
import { MappedInterfaces } from '@secure/devices/store/models/shared.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-info-table',
  templateUrl: './modal-info-table.component.html',
})
export class ModalInfoTableComponent {
  title: string;
  message: string;
  mappedInterfaces: MappedInterfaces[];
  columnDef: any[];

  constructor(public bsModalRef: BsModalRef) {}
}
