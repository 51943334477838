import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsEventService {
  public eventEmitter(eventAction: string, eventCategory: string, eventLabel: string) {
    gtag('event', eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
}
