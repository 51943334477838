import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './go-back.component.html',
})
export class ModalBackComponent {
  title: string;
  message: string;

  @Output() goBack = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) {}

  backConfirmation(): void {
    this.goBack.emit(true);
  }
}
