import { Action } from '@ngrx/store';
import { TrafficSteeringStatistics } from '../models/traffic-steering-statistics.model';

export enum ActionTypes {
  SET_TRAFFIC_STEERING_STATISTICS = 'SET_TRAFFIC_STEERING_STATISTICS',
  CLEAR_TRAFFIC_STEERING_STATISTICS = 'CLEAR_TRAFFIC_STEERING_STATISTICS',
}

export class SetTrafficSteeringStatistics implements Action {
  readonly type = ActionTypes.SET_TRAFFIC_STEERING_STATISTICS;
  constructor(public payload: TrafficSteeringStatistics) {}
}

export class ClearTrafficSteeringStatistics implements Action {
  readonly type = ActionTypes.CLEAR_TRAFFIC_STEERING_STATISTICS;
}

export type Actions = SetTrafficSteeringStatistics | ClearTrafficSteeringStatistics;
