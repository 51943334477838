import { filter, includes, map } from 'lodash';
import { Actions, ActionTypes } from '../actions/vlan-interface-configuration.action';
import {
  VlanInterface,
  VlanInterfaceConfigurationReducer,
} from '../models/vlan-interface-configuration.model';
import { NETWORK_SIDE } from '@shared/constants/constants';

export const initialState: VlanInterfaceConfigurationReducer = {
  id: 0,
  deviceId: 0,
  interfaces: [],
  shuffledColumns: [
    { key: 'Name', field: 'user_defined_name', hide: false },
    { key: 'MAC Address', field: 'mac_address', hide: false },
    { key: 'Admin Status', field: 'enable_interface', hide: false },
    { key: 'Dynamic IP', field: 'dynamic_ip_addressing_mode', hide: false },
    { key: 'DHCP Server', field: 'dhcp_server_configurations', hide: false },
    { key: 'Runtime Status', field: 'runtime_status', hide: false },
    { key: 'IP Addresses', field: 'system_defined_ip_addresses', hide: false },
    { key: 'Default Gateway', field: 'default_gateway', hide: false },
    { key: 'Bridge', field: 'bridge_id', hide: false },
    { key: 'Hardware Interfaces', field: 'hardware_interface_id', hide: false },
    { key: 'VLAN Tag', field: 'vlan_tag', hide: false },
    { key: 'Type', field: 'network_side', hide: false },
    { key: 'TX-Packets', field: 'statistics.sent.packets', hide: false },
    { key: 'TX-Bytes', field: 'statistics.sent.bytes', hide: false },
    { key: 'TX-Errors', field: 'statistics.sent.errors', hide: false },
    { key: 'RX-Packets', field: 'statistics.received.packets', hide: false },
    { key: 'RX-Bytes', field: 'statistics.received.bytes', hide: false },
    { key: 'RX-Errors', field: 'statistics.received.errors', hide: false },
  ],
};

export function vlanInterfaceConfigurationReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_VLAN_INTERFACE_CONFIGURAION:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        interfaces: action.payload.interfaces,
      };
    case ActionTypes.CLEAR_CURRENT_VLAN_INTERFACE_CONFIGURATION:
      return initialState;
    case ActionTypes.VLAN_INTERFACE_COLUMN_SELECT:
      const selectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (selectedColumn) {
        selectedColumn.hide = false;
      }
      return {
        ...state,
      };
    case ActionTypes.VLAN_INTERFACE_COLUMNS_SELECT_ALL:
      state.shuffledColumns.forEach((column) => (column.hide = false));
      return {
        ...state,
      };
    case ActionTypes.VLAN_INTERFACE_COLUMN_DESELECT:
      const unSelectedColumn = state.shuffledColumns.find(
        (column) => column.field === action.payload
      );
      if (unSelectedColumn) {
        unSelectedColumn.hide = true;
      }
      return {
        ...state,
      };
    case ActionTypes.VLAN_INTERFACE_SHUFFLED_COLUMNS:
      return {
        ...state,
        shuffledColumns: [...action.payload],
      };
    case ActionTypes.VLAN_INTERFACE_ADD:
      const newVlanInterface = action.payload;
      if (newVlanInterface.network_side === NETWORK_SIDE.WAN) {
        delete newVlanInterface.mac_ip_binding;
      }
      return {
        ...state,
        interfaces: [...state.interfaces, newVlanInterface],
      };
    case ActionTypes.VLAN_INTERFACE_EDIT:
      const newVlan = action.payload;
      const updatedVlanList = map(state.interfaces, (vlanInterface: VlanInterface) => {
        if (vlanInterface.id === newVlan.id) {
          if (newVlan.network_side === NETWORK_SIDE.LAN) {
            let macIpBinding = vlanInterface?.mac_ip_binding || [];
            const macIpMap = new Map(macIpBinding.map((macIp) => [macIp.id, macIp]));

            newVlan.mac_ip_binding?.forEach((newMacIp) => {
              macIpMap.set(newMacIp.id, newMacIp);
            });

            macIpBinding = Array.from(macIpMap.values());

            return {
              ...vlanInterface,
              ...newVlan,
              mac_ip_binding: macIpBinding,
            };
          } else {
            const updatedVlan = { ...vlanInterface, ...newVlan };
            delete updatedVlan.mac_ip_binding;
            return updatedVlan;
          }
        } else return vlanInterface;
      });

      return {
        ...state,
        interfaces: updatedVlanList,
      };
    case ActionTypes.VLAN_INTERFACE_DELETE:
      const id = action.payload;
      const modifiedVlanList = filter(
        state.interfaces,
        (vlanInterface: VlanInterface) => !includes(id, vlanInterface.id)
      );
      return {
        ...state,
        interfaces: modifiedVlanList,
      };
    case ActionTypes.DELETE_VLAN_MAC_IP_BINDING_LIST: {
      return {
        ...state,
        interfaces: state.interfaces.map((interfaceItem) => {
          if (interfaceItem.system_defined_name === action.payload.interfaceName) {
            return {
              ...interfaceItem,
              mac_ip_binding: interfaceItem.mac_ip_binding.filter(
                (macIp) => !action.payload.ids.includes(macIp.id)
              ),
            };
          }
          return interfaceItem;
        }),
      };
    }
    default:
      return state;
  }
}
