import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { getAuthToken, getUserEmail } from '@public/store/selectors/auth.selector';
import { Message } from '@shared/models/shared.model';
import {
  OtpParams,
  OtpResponse,
  PasswordResetParams,
  ResendLinkResponse,
  ResendPassCodeParams,
  ResetPasswordParams,
  SignInParams,
  SignInResponse,
} from '@views/public/store/models/auth.model';
import { StoreState } from 'app/store/store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  url = environment.baseUrl;

  constructor(
    public store: Store<StoreState['auth']>,
    private http: HttpClient
  ) {}

  authToken(): string {
    let authenticationToken: string;

    this.store
      .pipe(select(getAuthToken))
      .pipe(take(1))
      .subscribe((token) => {
        authenticationToken = token;
      });

    return authenticationToken;
  }

  email(): string {
    let currentUserEmail: string;

    this.store
      .pipe(select(getUserEmail))
      .pipe(take(1))
      .subscribe((email) => {
        currentUserEmail = email;
      });

    return currentUserEmail;
  }

  signIn(params: SignInParams): Observable<SignInResponse> {
    return this.http.post<SignInResponse>(`${this.url}/v1/signin`, params);
  }

  verifyOtp(params: OtpParams): Observable<HttpResponse<OtpResponse>> {
    return this.http.post<OtpResponse>(`${this.url}/v1/verify_otp`, params, {
      observe: 'response',
    });
  }

  resetPasswordLink(params: PasswordResetParams): Observable<ResendLinkResponse> {
    return this.http.put<ResendLinkResponse>(`${this.url}/v1/password/reset_link/send`, params);
  }

  validateUserToken(token: string): Observable<Message> {
    const headers = new HttpHeaders({
      Authorization: token,
    });
    return this.http.get<Message>(`${this.url}/v1/password/reset/validate`, {
      headers,
    });
  }

  resetPassword(params: ResetPasswordParams): Observable<ResendLinkResponse> {
    return this.http.put<ResendLinkResponse>(`${this.url}/v1/password/reset`, params);
  }

  resendOtp(params: ResendPassCodeParams): Observable<SignInResponse> {
    return this.http.post<SignInResponse>(`${this.url}/v1/resent_otp`, params);
  }

  signOut() {
    return this.http.delete(`${this.url}/v1/signout`);
  }
}
