import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NotificationsReducer } from './notifications.model';

export const getNotificationsFeatureState =
  createFeatureSelector<NotificationsReducer>('notifications');

export const getNotifications = createSelector(
  getNotificationsFeatureState,
  (state) => state.notifications
);
