<div class="modal-primary" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="reasonForm" novalidate class="form-horizontal">
        <div class="form-group row">
          <label class="col-md-12 col-form-label" for="reason" translate
            >DEVICES.DETAIL.REASON <span>*</span></label
          >
          <textarea
            id="textarea-input"
            name="textarea-input"
            rows="9"
            class="form-control mx-3"
            formControlName="reason"
          ></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()" translate>
        SHARED.CANCEL
      </button>
      <app-loading-button
        (formSubmit)="reasonConfirmation()"
        text="DEVICES.ADD.SAVE"
        loadingText="DEVICES.ADD.SAVING"
        buttonSize="lg"
        buttonIcon="fa fa-check"
        [isFormInvalid]="reasonForm.invalid"
      >
      </app-loading-button>
    </div>
  </div>
</div>
