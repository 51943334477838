import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VlanInterfaceConfigurationReducer } from '../models/vlan-interface-configuration.model';

export const getVlanInterfaceFeatureState =
  createFeatureSelector<VlanInterfaceConfigurationReducer>('vlanInterfaceConfiguration');

export const getCurrentDeviceVlanInterfaces = createSelector(
  getVlanInterfaceFeatureState,
  (state) => state.interfaces
);

export const getCurrentDeviceVlanInterfaceById = createSelector(
  getCurrentDeviceVlanInterfaces,
  (vlanInterfaces, props) => {
    const currentInterface = vlanInterfaces.find(
      (vlanInterface) => vlanInterface.id === props.interfaceId
    );
    return currentInterface;
  }
);

export const getCurrentVLanInterfaceColumns = createSelector(
  getVlanInterfaceFeatureState,
  (state) => state.shuffledColumns
);
