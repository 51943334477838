import { Action } from '@ngrx/store';
import { WirelessInterfaceConfiguration } from '@secure/devices/store/models/wireless-interface-configuration.model';
import { Columns } from '../models/shared.model';

export enum ActionTypes {
  SET_WIRELESS_INTERFACE_CONFIGURAION = 'SET_WIRELESS_INTERFACE_CONFIGURAION',
  CLEAR_CURRENT_WIRELESS_INTERFACE_CONFIGURATION = 'CLEAR_CURRENT_WIRELESS_INTERFACE_CONFIGURATION',
  WIRELESS_INTERFACE_COLUMN_SELECT = 'WIRELESS_INTERFACE_COLUMN_SELECT',
  WIRELESS_INTERFACE_COLUMNS_SELECT_ALL = 'WIRELESS_INTERFACE_COLUMNS_SELECT_ALL',
  WIRELESS_INTERFACE_COLUMN_DESELECT = 'WIRELESS_INTERFACE_COLUMN_DESELECT',
  WIRELESS_INTERFACE_SHUFFLED_COLUMNS = 'WIRELESS_INTERFACE_SHUFFLED_COLUMNS',
  EDIT_WIRELESS_INTERFACE = 'EDIT_WIRELESS_INTERFACE',
}

export class SetWirelessInterfaceConfiguration implements Action {
  readonly type = ActionTypes.SET_WIRELESS_INTERFACE_CONFIGURAION;
  constructor(public payload: WirelessInterfaceConfiguration) {}
}

export class ClearCurrentWirelessInterfaceConfiguration implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_WIRELESS_INTERFACE_CONFIGURATION;
}

export class WirelessInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.WIRELESS_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class WirelessInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.WIRELESS_INTERFACE_COLUMNS_SELECT_ALL;
}

export class WirelessInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.WIRELESS_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class WirelessInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.WIRELESS_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export type Actions =
  | SetWirelessInterfaceConfiguration
  | ClearCurrentWirelessInterfaceConfiguration
  | WirelessInterfaceColumnSelect
  | WirelessInterfaceColumnsSelectAll
  | WirelessInterfaceColumnDeSelect
  | WirelessInterfaceShuffledColumns;
