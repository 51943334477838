import { Action } from '@ngrx/store';
import * as type from '../models/qos-configuration.model';
import { ColumnWidth } from '../models/qos-configuration.model';

export enum ActionTypes {
  ADD_QOS_CLASS = 'ADD_QOS_CLASS',
  EDIT_QOS_CLASS = 'EDIT_QOS_CLASS',
  SET_QOS_CONFIG_BY_ID = 'SET_QOS_CONFIG_BY_ID',
  DELETE_QOS_CLASS = 'DELETE_QOS_CLASS',
  CLEAR_CURRENT_QOS_CONFIG = 'CLEAR_CURRENT_QOS_CONFIG',
  UPDATE_QOS_COLUMN_WIDTH = 'UPDATE_QOS_COLUMN_WIDTH',
}

export class AddQoSClass implements Action {
  readonly type = ActionTypes.ADD_QOS_CLASS;
  constructor(public payload: type.QoSClass) {}
}

export class EditQoSClass implements Action {
  readonly type = ActionTypes.EDIT_QOS_CLASS;
  constructor(public payload: type.QoSClass) {}
}

export class DeleteQoSClass implements Action {
  readonly type = ActionTypes.DELETE_QOS_CLASS;
  constructor(public payload: string[]) {}
}

export class SetQoSById implements Action {
  readonly type = ActionTypes.SET_QOS_CONFIG_BY_ID;
  constructor(public payload: type.QoSConfiguration) {}
}

export class ClearCurrentQoS implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_QOS_CONFIG;
}

export class UpdateQosColumnWidth implements Action {
  readonly type = ActionTypes.UPDATE_QOS_COLUMN_WIDTH;
  constructor(public payload: ColumnWidth[]) {}
}

export type Actions =
  | SetQoSById
  | AddQoSClass
  | EditQoSClass
  | DeleteQoSClass
  | ClearCurrentQoS
  | UpdateQosColumnWidth;
