import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VpnRoutingConfigurationReducer } from './../models/vpn-routing.model';

export const getVpnRoutingFeatureState =
  createFeatureSelector<VpnRoutingConfigurationReducer>('vpnRoutingConfiguration');

export const getCurrentDeviceVpnRoutings = createSelector(
  getVpnRoutingFeatureState,
  (state) => state.vpn_and_routing
);

export const getVpnRoutingColumns = createSelector(
  getVpnRoutingFeatureState,
  (state) => state.shuffledColumns
);
