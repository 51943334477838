<div class="modal-info" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
      <table class="table table-bordered" *ngIf="!columnDef" aria-describedby="Mapped interfaces">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Type</th>
            <th>Name</th>
            <th>Configured in</th>
            <th>Configured Interfaces</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let mappedInterface of mappedInterfaces; index as i">
            <td>{{ i + 1 }}</td>
            <td>{{ mappedInterface.type }}</td>
            <td>{{ mappedInterface.name }}</td>
            <td>{{ mappedInterface.configuredIn }}</td>
            <td>
              {{ mappedInterface.configuredInterfaces || '-' }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered" *ngIf="columnDef" aria-describedby="Mapped interfaces">
        <thead>
          <tr>
            <th>S.No.</th>
            <th *ngFor="let column of columnDef">{{ column.headerName }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let mappedInterface of mappedInterfaces; index as i">
            <td>{{ i + 1 }}</td>
            <td *ngFor="let column of columnDef">{{ mappedInterface[column.field] }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()" translate>
        SHARED.CLOSE
      </button>
    </div>
  </div>
</div>
