import { createAction, props } from '@ngrx/store';
import { IpsecClient } from './ipsec-client.interface';

export const addIpsecClientAction = createAction(
  '[Ipsec client] add',
  props<{ client: IpsecClient }>()
);

export const updateIpsecClientAction = createAction(
  '[Ipsec client] update',
  props<{ client: IpsecClient }>()
);

export const deleteIpsecClientsAction = createAction(
  '[Ipsec client] delete',
  props<{ clientIds: string[] }>()
);

export const setIpsecClientAction = createAction(
  '[Ipsec client] set',
  props<{ clients: IpsecClient[] }>()
);

export const updateIPsecClientServerConfigured = createAction(
  '[Ipsec client] updateIPsecClientServerConfigured',
  props<{ status: boolean }>()
);

export const clearIpsecClientAction = createAction('[Ipsec client] clear');
