import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CustomButton } from './ag-grid-cell-renderer-buttons.model';

@Component({
  templateUrl: './ag-grid-cell-renderer-buttons.component.html',
})
export class AgGridCellRendererButtonsComponent implements AgRendererComponent {
  params: ICellRendererParams;
  customButtons: CustomButton[];

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.customButtons = params.context;
  }

  refresh(): boolean {
    return false;
  }
}
