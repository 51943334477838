export interface NotificationSettings {
  alert_settings: AlertSettings;
}

export interface AlertSettings {
  bandwidth_utilization: Alert;
  cpu_temp: Alert;
  cpu_usage_system: Alert;
  cpu_usage_user: Alert;
  disk_space: Alert;
  main_memory: Alert;
  ping_jitter: Alert;
  ping_latency: Alert;
  system_status: Alert;
  vpn_status: Alert;
  wan_link: Alert;
  wan_status: Alert;
  device_configuration: Alert;
  device_provision: Alert;
  user_activity: Alert;
  lan_status: Alert;
  system_power: Alert;
  tunnel_latency: Alert;
  tunnel_wan_change: Alert;
  vpn_link: Alert;
  wan_failover: Alert;
  wan_ip_change: Alert;
}

export interface Alert {
  id: number;
  organization_id: number;
  conditions: Conditions | null;
  is_enabled?: boolean;
  is_event_enabled: boolean;
  alert_type: AlertType;
  created_at: string;
  updated_at: string;
}

export enum AlertType {
  bandwidthUtilization = 'Bandwidth Utilization',
  CPUTemperature = 'CPU Temp',
  systemCPUUsage = 'CPU Usage System',
  userCPUUsage = 'CPU Usage User',
  diskSpace = 'Disk Space',
  mainMemory = 'Main Memory',
  pingJitter = 'Ping Jitter',
  pingLatency = 'Ping Latency',
  systemStatus = 'System Status',
  VPNStatus = 'VPN Status',
  WANLink = 'WAN Link',
  WANStatus = 'WAN Status',
  deviceConfiguration = 'Device Configuration',
  deviceProvision = 'Device Provision',
  userActivity = 'User Activity',
  lanStatus = 'LAN Status',
  systemPower = 'System Power',
  tunnelLatency = 'Tunnel Latency',
  tunnelWANChange = 'Tunnel WAN Change',
  VPNLink = 'VPN Link',
  WANFailover = 'WAN Failover',
  WANIPChange = 'WAN IP Change',
}

interface Conditions {
  any: ConditionAny[];
}

export interface ConditionAny {
  fact: string;
  value: Value;
  params: Params;
  operator: Operator;
}

export enum Operator {
  allBetween = 'allBetween',
  allEqual = 'allEqual',
  allGreaterThanOrEqual = 'allGreaterThanOrEqual',
  allLessThanOrEqual = 'allLessThanOrEqual',
}

interface Params {
  name: string;
}

interface Value {
  val?: number;
  state: State;
  gte?: number;
  lte?: number;
}

export enum State {
  normal = 'NORMAL',
  high = 'HIGH',
  stable = 'STABLE',
  unStable = 'UNSTABLE',
  unReachable = 'UNREACHABLE',
}

export interface SettingsChangeEvent {
  [key: string]: Alert;
}

export interface CustomAlertSettings {
  bandwidth_utilization: CustomAlert;
  cpu_temp: CustomAlert;
  cpu_usage_system: CustomAlert;
  cpu_usage_user: CustomAlert;
  disk_space: CustomAlert;
  main_memory: CustomAlert;
  ping_jitter: CustomAlert;
  ping_latency: CustomAlert;
  system_status: CustomAlert;
  wan_link: CustomAlert;
  wan_status: CustomAlert;
  vpn_status: CustomAlert;
  device_configuration: CustomAlert;
  device_provision: CustomAlert;
  user_activity: CustomAlert;
  lan_status: CustomAlert;
  system_power: CustomAlert;
  tunnel_latency: CustomAlert;
  tunnel_wan_change: CustomAlert;
  vpn_link: CustomAlert;
  wan_failover: CustomAlert;
  wan_ip_change: CustomAlert;
}

export interface CustomAlert {
  title: string;
  icon: string;
  hasThreshold?: boolean;
  unit?: string;
  thresholdLabel?: string;
}
