import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RemoteSSHConfigurationReducer } from '../models/remote-ssh.model';

export const getRemoteSSHFeatureState =
  createFeatureSelector<RemoteSSHConfigurationReducer>('remoteSSHConfiguration');

export const getCurrentDeviceRemoteSSHs = createSelector(
  getRemoteSSHFeatureState,
  (state) => state.remoteSSHs
);

export const getRemoteSSHColumns = createSelector(
  getRemoteSSHFeatureState,
  (state) => state.shuffledColumns
);
