import { DEVICE_STATE } from '@shared/constants/constants';
import { Columns } from '@shared/models/shared.model';

export interface OrganizationsResponse {
  organizations: Organization[];
  pagination: OrganizationPagination;
}

export interface Organization {
  id: number;
  name: string;
  description: string;
  uuid: string;
  client_name: string;
  client_email: string;
  client_mobile_no: string;
  client_address: string;
  devices_count: number;
  provisioned_devices_count: number;
  prisma_firewall: boolean;
  created_by: number;
  updated_by: number;
  created_at: string;
  updated_at: string;
}

export interface OrganizationPagination {
  current_page: number;
  end_at: number;
  is_first_page: boolean;
  is_last_page: boolean;
  next_page: number;
  prev_page: number;
  per_page: number;
  start_at: number;
  total_count: number;
  total_pages: number;
}

export interface OrganizationsReducer {
  organizations: Organization[];
  pagination: OrganizationPagination;
  globalSearch: string;
  shuffledColumns: Columns[];
  hasFloatingFilter: boolean;
  selectedRows: any[];
  columns: OrganizationColumns;
  editOrganization: OrganizationFields;
}

export interface OrganizationParams {
  organization: {
    name: string;
    description?: string;
    prisma_firewall?: boolean;
    client_name?: string;
    client_email?: string;
    client_mobile_no?: string;
    client_address?: string;
  };
}

export interface OrganizationFields {
  name: string;
  description: string;
  prisma_firewall: boolean;
  client_name: string;
  client_email: string;
  client_mobile_no: string;
  client_address: string;
}

export interface OrganizationColumns {
  name: string;
  uuid: string;
  devices_count: number;
  provisioned_devices_count: number;
  prisma_firewall: boolean;
  client_name: string;
  client_email: string;
  client_mobile_no: string;
  client_address: string;
}
export interface ColumnFilter {
  suppressFilterButton: boolean;
  listType: string;
}

export interface OrganizationsStoreState {
  organizations: any;
}

export interface OrganizationDetail extends Organization {
  devices: Device[];
}

export interface Device {
  id: number;
  name: string;
  uuid: string;
  is_online: boolean;
  organization_id: number;
  type: string;
  state: DEVICE_STATE;
  provisioned_at: string;
  mac_address: string;
  os_version: string;
  latitude: number;
  longitude: number;
  lan_interfaces: string[];
  wan_interfaces: string[];
  wan_1_status: string;
  wan_2_status: string;
  created_at: string;
  updated_at: string;
}

export enum DeviceListType {
  deviceList,
  activeDeviceList,
  offlineDeviceList,
  notProvisionedDeviceList,
  wanUpDeviceList,
  wanDownDeviceList,
}
