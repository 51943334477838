import { Action } from '@ngrx/store';
import { PrismaInterface } from '../models/prisma-interface.model';
import { Columns } from '../models/shared.model';

export enum ActionTypes {
  SET_PRISMA_INTERFACE = 'SET_PRISMA_INTERFACE',
  CLEAR_CURRENT_PRISMA_INTERFACE = 'CLEAR_CURRENT_PRISMA_INTERFACE',
  PRISMA_INTERFACE_COLUMN_SELECT = 'PRISMA_INTERFACE_COLUMN_SELECT',
  PRISMA_INTERFACE_COLUMNS_SELECT_ALL = 'PRISMA_INTERFACE_COLUMNS_SELECT_ALL',
  PRISMA_INTERFACE_COLUMN_DESELECT = '  PRISMA_INTERFACE_COLUMN_DESELECT',
  PRISMA_INTERFACE_SHUFFLED_COLUMNS = 'PRISMA_INTERFACE_SHUFFLED_COLUMNS',
}

export class SetPrismaInterface implements Action {
  readonly type = ActionTypes.SET_PRISMA_INTERFACE;
  constructor(public payload: PrismaInterface[]) {}
}

export class ClearCurrentPrismaInterface implements Action {
  readonly type = ActionTypes.CLEAR_CURRENT_PRISMA_INTERFACE;
}

export class PrismaInterfaceColumnSelect implements Action {
  readonly type = ActionTypes.PRISMA_INTERFACE_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class PrismaInterfaceColumnsSelectAll implements Action {
  readonly type = ActionTypes.PRISMA_INTERFACE_COLUMNS_SELECT_ALL;
}

export class PrismaInterfaceColumnDeSelect implements Action {
  readonly type = ActionTypes.PRISMA_INTERFACE_COLUMN_DESELECT;
  constructor(public payload: string) {}
}

export class PrismaInterfaceShuffledColumns implements Action {
  readonly type = ActionTypes.PRISMA_INTERFACE_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}

export type Actions =
  | SetPrismaInterface
  | ClearCurrentPrismaInterface
  | PrismaInterfaceColumnSelect
  | PrismaInterfaceColumnsSelectAll
  | PrismaInterfaceColumnDeSelect
  | PrismaInterfaceShuffledColumns;
