import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HelperService } from '@shared/services/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.scss'],
})
export class HelperComponent implements OnInit, OnDestroy {
  hasView = false;
  @Input() message: string;
  subscriptions = new Subscription();

  constructor(private helperService: HelperService) {}

  ngOnInit() {
    const viewObserver = this.helperService.onFieldHelpChange.subscribe(
      () => (this.hasView = false)
    );
    this.subscriptions.add(viewObserver);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onFiledHelpIconClick() {
    const myViewStatus = !this.hasView;
    this.helperService.toggleFieldHelpView(myViewStatus, this.message);
    this.hasView = myViewStatus;
  }
}
