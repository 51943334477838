import { Action, createReducer, on } from '@ngrx/store';
import { clearIpsecStatisticsAction, setIpsecStatisticsAction } from './ipsec-statistics.action';
import { IpsecStatisticsState } from './ipsec-statistics.interface';

const initialState: IpsecStatisticsState = {
  statistics: [],
};

const reducer = createReducer(
  initialState,
  on(setIpsecStatisticsAction, (state, props) => ({
    ...state,
    statistics: props.ipsec_statistics,
  })),
  on(clearIpsecStatisticsAction, () => initialState)
);

export function ipsecStatisticsReducer(state: IpsecStatisticsState | undefined, action: Action) {
  return reducer(state, action);
}
