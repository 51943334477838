import { Actions, ActionTypes } from '../actions/device-configuration.action';
import { DeviceConfigurationReducer } from '../models/device-configuration.model';

export const INITIAL_STATE: DeviceConfigurationReducer = {
  id: 0,
  deviceId: 0,
  comment: '',
  isLocallyChanged: false,
  version: 0,
  isVersionChanged: false,
  is_device_online: false,
  device_state: null,
};

export function deviceConfigurationReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_CONIG_BY_DEVICE_ID:
      const config = action.payload;
      return {
        ...state,
        id: config.id,
        deviceId: config.device_id,
        comment: config.comment,
        isLocallyChanged: false,
      };
    case ActionTypes.SET_LOCALLY_CHANGED_FIELD:
      return {
        ...state,
        isLocallyChanged: action.payload,
      };
    case ActionTypes.SET_CONFIGURATION_VERSION:
      return {
        ...state,
        version: action.payload,
      };
    case ActionTypes.SET_VERSION_CHANGED:
      return {
        ...state,
        isVersionChanged: action.payload,
      };
    case ActionTypes.SET_DEVICE_STATE_AND_ONLINE_STATUS:
      return {
        ...state,
        is_device_online: action.payload.is_device_online,
        device_state: action.payload.device_state,
      };
    default:
      return state;
  }
}
