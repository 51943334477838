import { Action } from '@ngrx/store';
import {
  Columns,
  ColumnWidth,
  GetUserResponse,
  SortColumns,
  User,
} from '@secure/users/store/models/users.model';

export enum ActionTypes {
  FETCH_USERS = 'FETCH_USERS',
  USER_UPDATE_CURRENT_PAGE = 'USER_UPDATE_CURRENT_PAGE',
  USER_CLEAR_SELECTED_ROWS = 'USER_CLEAR_SELECTED_ROWS',
  USER_COLUMN_SELECT = 'USER_COLUMN_SELECT',
  USER_COLUMNS_SELECT_ALL = 'USER_COLUMNS_SELECT_ALL',
  USER_COLUMN_DESELECT = 'USER_COLUMN_DESELECT',
  USER_COLUMNS_DESELECT_ALL = 'USER_COLUMNS_DESELECT_ALL',
  USER_SHUFFLED_COLUMNS = 'USER_SHUFFLED_COLUMNS',
  USER_COLUMN_SEARCH = 'USER_COLUMN_SEARCH',
  USER_UPDATE_FLOATING_FILTER = 'USER_UPDATE_FLOATING_FILTER',
  USER_CLEAR_COLUMNS_SEARCH = 'USER_CLEAR_COLUMNS_SEARCH',
  USER_CLEAR_COLUMNS_SORT = 'USER_CLEAR_COLUMNS_SORT',
  USER_UPDATE_SELECTED_ROWS = 'USER_UPDATE_SELECTED_ROWS',
  USER_UPDATE_COLUMNS_SORT = 'USER_UPDATE_COLUMNS_SORT',
  USER_UPDATE_COLUMN_WIDTH = 'USER_UPDATE_COLUMN_WIDTH',
  USER_GLOBAL_SEARCH = 'USER_GLOBAL_SEARCH',
  DELETE_USERS = 'DELETE_USERS',
  SET_EDIT_USER = 'SET_EDIT_USER',
}

export class FetchUsers implements Action {
  readonly type = ActionTypes.FETCH_USERS;
  constructor(public payload: GetUserResponse) {}
}

export class GlobalSearch implements Action {
  readonly type = ActionTypes.USER_GLOBAL_SEARCH;
  constructor(public payload: string) {}
}

export class UpdateCurrentPage implements Action {
  readonly type = ActionTypes.USER_UPDATE_CURRENT_PAGE;
  constructor(public payload: number) {}
}

export class ClearSelectedRows implements Action {
  readonly type = ActionTypes.USER_CLEAR_SELECTED_ROWS;
}

export class ColumnSelect implements Action {
  readonly type = ActionTypes.USER_COLUMN_SELECT;
  constructor(public payload: string) {}
}

export class ColumnsSelectAll implements Action {
  readonly type = ActionTypes.USER_COLUMNS_SELECT_ALL;
}

export class ColumnDeSelect implements Action {
  readonly type = ActionTypes.USER_COLUMN_DESELECT;
  constructor(public payload: string) {}
}
export class ColumnsDeSelectAll implements Action {
  readonly type = ActionTypes.USER_COLUMNS_DESELECT_ALL;
}
export class ShuffledColumns implements Action {
  readonly type = ActionTypes.USER_SHUFFLED_COLUMNS;
  constructor(public payload: Columns[]) {}
}
export class ColumnSearch implements Action {
  readonly type = ActionTypes.USER_COLUMN_SEARCH;
  constructor(public payload: any) {}
}

export class UpdateFloatingFilter implements Action {
  readonly type = ActionTypes.USER_UPDATE_FLOATING_FILTER;
  constructor(public payload: boolean) {}
}

export class ClearColumnSearch implements Action {
  readonly type = ActionTypes.USER_CLEAR_COLUMNS_SEARCH;
}

export class ClearColumnsSort implements Action {
  readonly type = ActionTypes.USER_CLEAR_COLUMNS_SORT;
}

export class UpdateSelectedRows implements Action {
  readonly type = ActionTypes.USER_UPDATE_SELECTED_ROWS;
  constructor(public payload: User[]) {}
}

export class UpdateColumnsSort implements Action {
  readonly type = ActionTypes.USER_UPDATE_COLUMNS_SORT;
  constructor(public payload: SortColumns[]) {}
}

export class UpdateUsersColumnWidth implements Action {
  readonly type = ActionTypes.USER_UPDATE_COLUMN_WIDTH;
  constructor(public payload: ColumnWidth[]) {}
}

export class DeleteUsers implements Action {
  readonly type = ActionTypes.DELETE_USERS;
  constructor(public payload: number[]) {}
}

export class SetEditUser implements Action {
  readonly type = ActionTypes.SET_EDIT_USER;
  constructor(public payload: User) {}
}

export type Actions =
  | FetchUsers
  | UpdateCurrentPage
  | ClearSelectedRows
  | ColumnSelect
  | ColumnsSelectAll
  | ColumnDeSelect
  | ColumnsDeSelectAll
  | ShuffledColumns
  | ColumnSearch
  | UpdateFloatingFilter
  | ClearColumnSearch
  | ClearColumnsSort
  | UpdateSelectedRows
  | UpdateColumnsSort
  | UpdateUsersColumnWidth
  | GlobalSearch
  | DeleteUsers
  | SetEditUser;
