import { Actions, ActionTypes } from '../actions/auth.action';
import { AuthReducer } from '../models/auth.model';

export const initialState: AuthReducer = {
  id: 0,
  email: '',
  name: '',
  organization_id: '',
  organization_name: '',
  role: null,
  token: null,
};

export function authReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.SIGN_IN:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.SIGN_OUT:
      return initialState;
    case ActionTypes.UPDATE_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
}
