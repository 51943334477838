import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input() globalSearch: string;
  @Output() searchChange = new EventEmitter<string>();
  @Output() search = new EventEmitter<void>();

  onSearchInputChange(event) {
    this.searchChange.next(event);
    if (event.length === 0) {
      this.onSubmit();
    }
  }

  onSubmit() {
    this.search.next();
  }
}
