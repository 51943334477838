import { filter, includes, map } from 'lodash';
import { Actions, ActionTypes } from '../actions/qos-configuration.action';
import { QoSClass, QoSConfigurationReducer } from '../models/qos-configuration.model';

const INITIAL_STATE: QoSConfigurationReducer = {
  id: 0,
  deviceId: 0,
  qosClasses: [],
  columns: [
    { key: 'Name', field: 'name', minWidth: 75, width: 105 },
    { key: 'Class Name', field: 'class_name', minWidth: 75, width: 105 },
    { key: 'WAN', field: 'interface', minWidth: 70, width: 75 },
    { key: 'Parent', field: 'parent_id', minWidth: 75, width: 120 },
    { key: 'Class ID', field: 'class_id', minWidth: 60, width: 73 },
    { key: 'LS Steady State', field: 'ls_sss_unit', minWidth: 75, width: 108 },
    { key: 'LS Burst Speed', field: 'ls_burst_speed', minWidth: 75, width: 108 },
    { key: 'LS Burst Duration', field: 'ls_burst_duration', minWidth: 75, width: 120 },
    { key: 'RTSTEADYSTATE', field: 'rt_sss_unit', minWidth: 75, width: 111 },
    { key: 'RTBURSTSPEED', field: 'rt_burst_speed', minWidth: 75, width: 110 },
    { key: 'RTBURSTDURATION', field: 'rt_burst_duration', minWidth: 75, width: 121 },
    { key: 'ULSTEADYSTATE', field: 'ul_sss_unit', minWidth: 75, width: 113 },
    { key: 'ULBURSTSPEED', field: 'ul_burst_speed', minWidth: 75, width: 108 },
    { key: 'ULBURSTDURATION', field: 'ul_burst_duration', minWidth: 75, width: 125 },
    { key: 'DEFAULT', field: 'default', minWidth: 70, width: 73 },
  ],
};

export function qosConfigurationReducer(state = INITIAL_STATE, action: Actions) {
  switch (action.type) {
    case ActionTypes.SET_QOS_CONFIG_BY_ID:
      return {
        ...state,
        id: action.payload.id,
        deviceId: action.payload.device_id,
        qosClasses: action.payload.qos_classes,
      };
    case ActionTypes.ADD_QOS_CLASS:
      let defaultHandledQosClasses = state.qosClasses;
      const wlan = action.payload.interface;
      if (action.payload.default) {
        defaultHandledQosClasses = map(state.qosClasses, (qosClass: QoSClass) => {
          return wlan === qosClass.interface ? { ...qosClass, default: false } : qosClass;
        });
      }

      return {
        ...state,
        qosClasses: [...defaultHandledQosClasses, action.payload],
      };
    case ActionTypes.EDIT_QOS_CLASS:
      const updatedClass = action.payload;
      const wlanEdit = action.payload.interface;
      let defaultHandledQosClassesEdit = state.qosClasses;
      if (action.payload.default) {
        defaultHandledQosClassesEdit = map(state.qosClasses, (qosClass: QoSClass) => {
          return wlanEdit === qosClass.interface ? { ...qosClass, default: false } : qosClass;
        });
      }

      const updatedClassList = map(defaultHandledQosClassesEdit, (qosClass: QoSClass) =>
        qosClass.id === updatedClass.id ? updatedClass : qosClass
      );
      return {
        ...state,
        qosClasses: updatedClassList,
      };
    case ActionTypes.DELETE_QOS_CLASS:
      const ids = action.payload;
      const modifiedClassList = filter(
        state.qosClasses,
        (qosClass: QoSClass) => !includes(ids, qosClass.id)
      );
      return {
        ...state,
        qosClasses: modifiedClassList,
      };
    case ActionTypes.CLEAR_CURRENT_QOS_CONFIG:
      return INITIAL_STATE;
    case ActionTypes.UPDATE_QOS_COLUMN_WIDTH:
      state.columns.forEach((column) => {
        action.payload.forEach((data) => {
          if (column.field === data.field) {
            column.width = data.width;
          }
        });
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}
