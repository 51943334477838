import { Action } from '@ngrx/store';
import { AuthReducer } from '../models/auth.model';

export enum ActionTypes {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE_TOKEN = 'UPDATE_TOKEN',
  UPDATE_EMAIL = 'UPDATE_EMAIL',
}

export class SignIn implements Action {
  readonly type = ActionTypes.SIGN_IN;
  constructor(public payload: AuthReducer) {}
}

export class SignOut implements Action {
  readonly type = ActionTypes.SIGN_OUT;
}

export class UpdateEmail implements Action {
  readonly type = ActionTypes.UPDATE_EMAIL;
  constructor(public payload: string) {}
}

export type Actions = SignIn | SignOut | UpdateEmail;
