import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StaticRoutingConfigurationReducer } from '../models/static-routing.model';

export const getStaticRoutingFeatureState =
  createFeatureSelector<StaticRoutingConfigurationReducer>('staticRoutingConfiguration');

export const getCurrentDeviceStaticRoutings = createSelector(
  getStaticRoutingFeatureState,
  (state) => state.static_routing
);

export const getCurrentDeviceStaticRoutingRedistribute = createSelector(
  getStaticRoutingFeatureState,
  (state) => state.redistribute
);

export const getCurrentDeviceStaticRoutingsSaveToCloud = createSelector(
  getStaticRoutingFeatureState,
  (state) => {
    return {
      redistribute: state.redistribute,
      configs: state.static_routing,
    };
  }
);

export const getCurrentStaticRoutingColumns = createSelector(
  getStaticRoutingFeatureState,
  (state) => state.shuffledColumns
);

export const getStaticRoutingColumns = createSelector(
  getStaticRoutingFeatureState,
  (state) => state.shuffledColumns
);
