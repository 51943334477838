import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef, ColumnState, SortChangedEvent } from 'ag-grid-community';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
})
export class DataGridComponent {
  @Input() columnDefs: ColDef[];
  @Input() rowData: any[];
  @Input() overlayNoRowsTemplate: any;
  @Input() defaultColDef: ColDef;
  @Input() hasMultipleRows: string;
  @Input() hasRowAnimation = true;
  @Input() frameworkComponents: any;
  @Input() multiSortKey = 'ctrl';
  @Input() pagination: boolean;
  @Input() paginationPageSize: number;

  @Output() gridReady = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();
  @Output() rowClicked = new EventEmitter();
  @Output() rowDataChanged = new EventEmitter();
  @Output() sortChanged = new EventEmitter();
  @Output() columnResized = new EventEmitter();
  @Output() paginationChanged = new EventEmitter();

  gridApi;
  gridColumnApi;

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridReady.emit(params);
    this.gridApi.setDomLayout('autoHeight');
  }

  onSelectionChanged(): void {
    this.selectionChanged.emit(this.gridApi);
  }

  get columnDef() {
    const baseDefaultColDef = {
      suppressMenu: true,
      suppressMovable: true,
      sortable: true,
      resizable: true,
    };

    return { ...baseDefaultColDef, ...this.defaultColDef };
  }

  onRowClicked(params): void {
    this.rowClicked.emit(params.data);
  }

  onRowDataChanged(): void {
    this.rowDataChanged.emit();
  }

  onSortChanged(params: SortChangedEvent): void {
    const sortedColumn = params.columnApi
      .getColumnState()
      .filter((col: ColumnState) => Boolean(col.sort))
      .map((col: ColumnState) => ({ colId: col.colId, sort: col.sort }));
    this.sortChanged.emit(sortedColumn);
  }

  onColumnResized() {
    this.columnResized.emit(this.gridApi);
  }

  onPaginationChanged(event) {
    if (event.newPage) {
      this.paginationChanged.emit(event);
    }
  }
}
