import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FilesizePipe implements PipeTransform {
  deciPipe: DecimalPipe;

  private units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  constructor(@Inject(LOCALE_ID) localeId) {
    this.deciPipe = new DecimalPipe(localeId);
  }

  transform(bytes: number = 0, precision: number = 2): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
      return '?';
    }

    let unit = 0;

    while (bytes >= 1000) {
      bytes /= 1000;
      unit++;
    }

    if (unit === 0) {
      precision = 0;
    }
    return this.deciPipe.transform(bytes, '1.0-' + precision) + ' ' + this.units[unit];
  }
}
