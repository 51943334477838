<div class="border rounded p-3 mt-3" *ngIf="updatedAlert">
  <div class="d-flex justify-content-between">
    <div class="d-flex gap-3 align-items-center">
      <div class="icon-container text-center">
        <i class="fa fa-2x" [ngClass]="currentAlert.icon" aria-hidden="true"></i>
      </div>
      <div>
        <h6 class="m-0" *ngIf="currentAlert.title">
          {{ 'NOTIFICATIONS.ALERTS.TYPE.' + currentAlert.title | translate }}
        </h6>
        <ng-container *ngIf="updatedAlert.conditions && currentAlert.hasThreshold">
          <p class="m-0" *ngIf="!isWANOrVPNLinkAlertType()">
            {{ 'NOTIFICATIONS.ALERTS.GREATER_THAN_OR_EQUAL' | translate }}
            {{ getHighStateValue(updatedAlert.conditions.any) }}{{ currentAlert.unit }}
          </p>
          <p class="m-0" *ngIf="isWANOrVPNLinkAlertType()">
            {{ 'NOTIFICATIONS.ALERTS.GREATER_THAN_OR_EQUAL' | translate }}
            {{ getUnstableStateValue() }},
            {{ 'NOTIFICATIONS.ALERTS.GREATER_THAN_OR_EQUAL' | translate }}
            {{ getUnreachableStateValue() }}
          </p>
        </ng-container>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <span
        *ngIf="updatedAlert.is_enabled || updatedAlert.is_event_enabled; else bothDisabled"
        class="font-weight-bold badge mr-4"
        [ngClass]="
          updatedAlert.is_enabled && updatedAlert.is_event_enabled ? 'badge-success' : 'badge-light'
        "
      >
        <span *ngIf="updatedAlert.is_enabled">{{ 'NOTIFICATIONS.ALERTS.EMAIL' | translate }} </span>
        <span *ngIf="updatedAlert.is_enabled && updatedAlert.is_event_enabled">, </span>
        <span *ngIf="updatedAlert.is_event_enabled"
          >{{ 'NOTIFICATIONS.ALERTS.WEB' | translate }}
        </span>
        <span *ngIf="!(updatedAlert.is_enabled && updatedAlert.is_event_enabled)"
          >{{ 'NOTIFICATIONS.ALERTS.ONLY' | translate }}
        </span>
      </span>
      <ng-template #bothDisabled>
        <span class="font-weight-bold badge badge-danger mr-4">{{
          'NOTIFICATIONS.ALERTS.DISABLED' | translate
        }}</span>
      </ng-template>
      <i
        class="fa cursor-pointer fa-chevron-up"
        [class.fa-chevron-down]="!isExpanded"
        aria-hidden="true"
        aria-expanded="false"
        data-toggle="collapse"
        [attr.data-target]="'#collapse-' + updatedAlert.id"
        [attr.aria-controls]="'collapse-' + updatedAlert.id"
        (click)="onToggleView()"
      ></i>
    </div>
  </div>
  <div class="collapse" [id]="'collapse-' + updatedAlert.id">
    <div class="form-group row mt-4" *ngIf="updatedAlert.conditions && currentAlert.hasThreshold">
      <label class="col-sm-2 col-form-label">{{
        'NOTIFICATIONS.ALERTS.' + currentAlert.thresholdLabel | translate
      }}</label>
      <ng-container *ngFor="let control of updatedAlert.conditions.any">
        <div class="col-sm-3 d-flex input-group" *ngIf="isHighState(control)">
          <input
            type="number"
            class="form-control"
            [value]="getStateValue(control)"
            (change)="onThresholdHighStateChange($event)"
            digitOnly
          />
          <div class="input-group-append">
            <span class="input-group-text">{{ currentAlert.unit }}</span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isWANOrVPNLinkAlertType()">
        <div class="col-sm-3">
          <label>{{ 'NOTIFICATIONS.ALERTS.UNSTABLE' | translate }}(>=)</label>
          <input
            type="number"
            class="form-control"
            [value]="getUnstableStateValue()"
            (change)="onUnstableSateChange($event)"
            digitOnly
          />
        </div>
        <div class="col-sm-3">
          <label>{{ 'NOTIFICATIONS.ALERTS.UNREACHABLE' | translate }}(>=)</label>
          <input
            type="number"
            class="form-control"
            [value]="getUnreachableStateValue()"
            (change)="onUnreachableSateChange($event)"
            digitOnly
          />
        </div>
      </ng-container>
    </div>

    <p class="font-weight-bold mt-2" translate>NOTIFICATIONS.ALERTS.WHERE_TO</p>
    <div
      class="d-flex justify-content-between mr-3 mb-3"
      *ngIf="updatedAlert.hasOwnProperty('is_enabled')"
    >
      <div class="d-flex gap-3 align-items-center">
        <i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i>
        <p class="m-0" translate>NOTIFICATIONS.ALERTS.EMAIL</p>
      </div>
      <input type="checkbox" [checked]="updatedAlert.is_enabled" (change)="onEmailChange()" />
    </div>
    <div class="d-flex justify-content-between mr-3">
      <div class="d-flex gap-3 align-items-center">
        <i class="fa fa-sharp fa-solid fa-globe fa-2x" aria-hidden="true"></i>
        <p class="m-0" translate>NOTIFICATIONS.ALERTS.WEB</p>
      </div>
      <input type="checkbox" [checked]="updatedAlert.is_event_enabled" (change)="onWebChange()" />
    </div>
  </div>
</div>
